import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import InboxClosingReasonsAPI from '../../api/inboxClosingReasons';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isUpdating: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getInboxOfClosingReasons: _state => {
    return _state.records;
  },
};

export const actions = {
  fetch: async ({ commit }, inboxId) => {
    commit(types.SET_INBOX_REASONS_UI_FLAG, { isFetching: true });
    try {
      const response = await InboxClosingReasonsAPI.show(inboxId);
      commit(types.SET_INBOX_REASONS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_INBOX_REASONS_UI_FLAG, { isFetching: false });
    }
  },
  update: async ({ commit }, { inboxId, closingReasons }) => {
    commit(types.SET_INBOX_REASONS_UI_FLAG, { isUpdating: true });

    try {
      const response = await InboxClosingReasonsAPI.update({ inboxId, closingReasons });
      commit(types.EDIT_INBOX_REASONS, response.data);
    } catch (error) {
      //
    } finally {
      commit(types.SET_INBOX_REASONS_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [types.SET_INBOX_REASONS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_INBOX_REASONS]: MutationHelpers.set,
  [types.EDIT_INBOX_REASONS]: MutationHelpers.update,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
