<template>
  <div class="360dialog-conect">
    <woot-button
        type="button"
        color-scheme="secondary"
        @click="onToggle360dialogModal"
    >
        Homologar novo número no WhatsApp
    </woot-button>
    <woot-modal
        :show.sync="show"
        :on-close="onToggle360dialogModal"
        :close-on-backdrop-click="false"
        modal-type="right-aligned"
    >
        <div class="column content-box">
            <woot-modal-header :header-title="title">
                <p v-html="subtitle"></p>
            </woot-modal-header>
            <form @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="columns">
                        <label v-if="enableCreateAPIKey" :class="{ error: $v.email360Dialog.$error }">
                            Email
                            <input
                                v-model.trim="email360Dialog"
                                type="email"
                                placeholder="Partner Email"
                                @input="$v.email360Dialog.$touch"
                            />
                        </label>

                        <label v-if="enableCreateAPIKey" :class="{ error: $v.password360Dialog.$error }">
                            Senha
                            <input
                                v-model.trim="password360Dialog"
                                type="password"
                                placeholder="Partner Senha"
                                @input="$v.password360Dialog.$touch"
                            />
                        </label>

                        <label v-if="enableCreateAPIKey">
                            Canal Id
                            <input
                                v-model.trim="channelId360Dialog"
                                type="text"
                                readonly
                                placeholder="Canal Id"
                            />
                        </label>
                    </div>
                </div>
                <div class="medium-12 columns">
                    <woot-submit-button
                        :loading="submiting"
                        :disabled="disableSubmit"
                        :button-text="buttonText"
                    />
                </div>
            </form>
        </div>
    </woot-modal>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import axios from 'axios';

const receiveMessage = (event, baseUrl) => {
    if (event.origin != baseUrl) return;

    const { data } = event;
    if (typeof data != 'string') return;

    const redirectUrl = `${data}`;
    window.location.search = redirectUrl;
};

export default {
    mixins: [alertMixin],
    props: {
        redirectUrl: {
            type: String
        },
        onapikeygenerated: {
            type: Function,
            defaulf: (apikey) => {}
        },
        activeApiKey: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            axios: null,
            show: false,
            windowObjectReference: null,
            previousUrl: null,
            email360Dialog: null,
            password360Dialog: null,
            partnerId360Dialog: '8L0NNEPA',
            channelId360Dialog: null,
            submiting: false,
            buttonText: 'Conectar com Facebook',
            enableCreateAPIKey: false,
            partnerObj: {}
        };
    },
    computed: {
        disableSubmit() {
            return (this.invalid || this.submiting);
        },
        invalid() {
            return this.enableCreateAPIKey ? this.$v.$invalid : false;
        },
        title() {
            if (this.enableCreateAPIKey) {
                return `Gerar chave da API`;
            }
            return `Homologar novo número`;
        },
        subtitle() {
            if (this.enableCreateAPIKey) {
                return `Gere uma chave de acesso para que o Converx possa gerenciar suas mensagens do WhatsApp.`;
            }
            return `Iniciar processo de homologação de um novo número de WhatsApp junto ao Facebook.<br/>
                    O Facebook irá te ligar ou enviar um SMS para o número selecionado te passando um código de verificação.<br/>
                    Portanto você ou alguém de confiança deverá ter acesso ao telefone para poder receber o código de verificação.<br/>
                    Só inicie esse processo se você tiver acesso ao telefone selecionado.`;
        }
    },
    validations: {
        email360Dialog: {
            required,
            email
        },
        password360Dialog: {
            required,
            minLength: minLength(6)
        }
    },
    watch: {
        enableCreateAPIKey(stts) {
            if (stts) {
                this.buttonText = 'Criar API Key';
            } else {
                this.buttonText = 'Conectar com Facebook';
            }
        },
        show(stts) {
            if (!stts && !this.windowObjectReference) {
                this.reset();
            }
        }
    },
    created() {
        this.axios = axios.create({
            baseURL: 'https://hub.360dialog.io/api/v2',
            headers: {'Content-Type': 'application/json'}
        });
    },
    mounted() {
        const params = new URLSearchParams(window.location.search);
        const client = params.get("client");
        const channels = params.get("channels");
        const revokedChannels = params.get('revoked');

        if (client && channels) {
            const channelsArray = channels.substring(1, channels.length - 1).split(",");

            this.partnerObj = { client: client, channels: channelsArray};

            if (revokedChannels) {
                const revokedChannelsArray = revokedChannels.substring(1, revokedChannels.length - 1).split(',');
                this.partnerObj['revokedChannels'] = revokedChannelsArray;
            }

            if (this.activeApiKey) {
                this.onToggle360dialogModal();
                this.enableCreateAPIKey = true;
                this.channelId360Dialog = this.partnerObj.channels;
            }
        }

        // send to parent (opener) window and close small window
        if (window.opener) {
            window.opener.postMessage(window.location.search);
            window.close();
        }
    },
    methods: {
        onToggle360dialogModal() {
            this.show = !this.show;
            let evt = event || window.event;
            if (evt) evt.preventDefault();
        },
        handleSubmit() {
            this.$v.$touch();
            if (this.invalid || this.submiting) return;
            this.submiting = true;

            if (this.enableCreateAPIKey) {
                return this.handleCreateAPIKey();
            }

            const redirectUrl = encodeURIComponent(`${location.origin}${this.redirectUrl}`);
            this.open360dialogPartner(redirectUrl);
        },
        async getUserToken() {
            return this.axios.post('/token', {
                    email: this.email360Dialog,
                    password: this.password360Dialog
                });
        },
        open360dialogPartner(redirectUrl = null) {
            let permissionUrl = `https://hub.360dialog.com/dashboard/app/${this.partnerId360Dialog}/permissions`;
            if (redirectUrl) {
                permissionUrl += `?redirect_url=${redirectUrl}&plan_selection=basic`;
            }

            this.openSignInWindow(permissionUrl, 'connect-360dialog', window.location.origin);
        },
        openSignInWindow(url, name, baseUrl) {
            window.removeEventListener("message", receiveMessage);

            const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=900, top=100, left=100";

            if (this.windowObjectReference === null || this.windowObjectReference.closed) {
                this.windowObjectReference = window.open(url, name, strWindowFeatures);
            } else if (this.previousUrl !== url) {
                this.windowObjectReference = window.open(url, name, strWindowFeatures);
                this.windowObjectReference.focus();
            } else {
                this.windowObjectReference.focus();
            }

            if (this.windowObjectReference != null) {
                var timer = setInterval(() => {
                    if(this.windowObjectReference.closed) {
                        clearInterval(timer);
                        this.reset();
                    }
                }, 1000);
            }

            window.addEventListener(
                "message",
                (event) => receiveMessage(event, baseUrl),
                false
            );

            this.previousUrl = url;
        },
        async createAPIKey(channelId) {
            return this.axios.post(`/partners/${this.partnerId360Dialog}/channels/${channelId}/api_keys`, {});
        },
        async handleCreateAPIKey() {
            await this.getUserToken()
                .then(async (res) => {
                    const { token_type, access_token } = res.data;
                    this.axios.defaults.headers.common['Authorization'] = `${token_type} ${access_token}`;

                    return await this.createAPIKey(this.channelId360Dialog);
                })
                .then((res) => {
                    const { api_key } = res.data;
                    this.onapikeygenerated(api_key);
                    this.onToggle360dialogModal();
                    this.reset();
                })
                .catch((error) => {
                    this.showAlert('Ocorreu um erro');
                    window.open("https://hub.360dialog.com/", "_blank");
                    console.error(error);
                })
                .finally(() => {
                    this.submiting = false;
                });

            return;
        },
        reset() {
            this.windowObjectReference = null;
            this.previousUrl = null;
            this.enableCreateAPIKey = false;
            this.password360Dialog = null;
            this.email360Dialog = null;
            this.channelId360Dialog = null;
            this.submiting = false;
            window.history.pushState({}, null, window.location.pathname);
        }
    }
}
</script>
