<template>
  <div class="column content-box">
    <div class="table-actions-wrap">
      <search :search-query="searchQuery" :on-input-search="onInputSearch" @on-search-submit="getCadences" />

      <div class="right-aligned-wrap">
        <woot-button color-scheme="success" icon="arrow-clockwise" @click="openAddPopup">
          {{ $t('CADENCES.HEADER') }}
        </woot-button>
      </div>
    </div>

    <div class="medium-12">
      <table-content
        :items="cadences"
        :show-search-empty-state="showEmptySearchResult"
        :is-loading="uiFlags.isFetching"
        @on-sort-change="onSortChange"
        @on-delete-click="openDeletePopup"
        @on-switch-change="onSwitchChange"
      />

      <table-footer
        :current-page="Number(meta.current_page)"
        :total-count="meta.count"
        :page-size="15"
        @page-change="onPageChange"
      />
    </div>

    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup" size="low">
      <Create @on-created="getCadences()" @on-close="hideAddPopup()"></Create>
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CADENCES.DELETE.CONFIRM.TITLE')"
      :message="$t('CADENCES.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('CADENCES.DELETE.CONFIRM.YES')"
      :reject-text="$t('CADENCES.DELETE.CONFIRM.NO')"
      :is-loading="uiFlags.isDeleting"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import Search from './Search';
import TableContent from './List';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import Create from './Create.vue';


export default {
  data() {
    return {
      page: 1,
      searchQuery: '',
      oldSearchQuery: '',
      sortConfig: { name: 'desc' },
      showAddPopup: false,
      selected: {},
      showDeleteConfirmationPopup: false,
    };
  },
  mixins: [alertMixin],
  components: {
    Search,
    TableContent,
    TableFooter,
    Create,
  },
  computed: {
    ...mapGetters({
      meta: 'cadences/getMeta',
      cadences: 'cadences/getCadences',
      uiFlags: 'cadences/getUIFlags',
    }),
    showEmptySearchResult() {
      return !!this.searchQuery && this.cadences.length === 0;
    },
  },
  methods: {
    ...mapActions('cadences', ['get', 'search', 'delete', 'update']),
    async getCadences() {
      try {
        let value = '';

        if (this.searchQuery.charAt(0) === '+') {
          value = this.searchQuery.substring(1);
        } else {
          value = this.searchQuery;
        }

        const requestParams = {
          page: this.page,
          sortAttr: this.getSortAttribute(),
        };

        if (value) {
          if (this.oldSearchQuery && this.searchQuery !== this.oldSearchQuery) {
            this.page = 1;
            requestParams.page = this.page;
          }

          this.oldSearchQuery = this.searchQuery;
          requestParams.search = encodeURIComponent(value.toLowerCase());
          await this.search(requestParams);
        } else {
          await this.get(requestParams);
        }
      } catch (error) {
        this.showAlert(error.message);
      }
    },
    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];
        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }
        return acc;
      }, '');

      if (!sortAttr) {
        this.sortConfig = { name: 'desc' };
        sortAttr = '-name';
      }

      return sortAttr;
    },
    async deleteCadence(id) {
      try {
        await this.delete(id);

        this.showAlert(this.$t('CADENCES.DELETE.API.SUCCESS_MESSAGE'));

        this.clearData();
        this.getCadences();
      } catch (error) {
        this.showAlert(this.$t('CADENCES.DELETE.API.ERROR_MESSAGE'));
      } finally {
        this.closeDeletePopup();
      }
    },
    async updateCadence({ id, data }) {
      try {
        await this.update({ id, data });

        if (data.enabled) {
          this.showAlert(this.$t('CADENCES.UPDATE.API.ENABLED_SUCCESS_MESSAGE'));
        } else {
          this.showAlert(this.$t('CADENCES.UPDATE.API.DISABLED_SUCCESS_MESSAGE'));
        }
        this.getCadences();

      } catch (error) {
        this.showAlert(this.$t('CADENCES.UPDATE.API.ERROR_MESSAGE'));
      }
    },
    onCreateCadence() {
      this.clearData();
      this.getCadences();
    },
    onPageChange(page) {
      this.page = Number(page);

      this.getCadences();
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllCadences = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;

      if (refetchAllCadences) {
        this.getCadences();
      }
    },
    onSortChange(params) {
      this.sortConfig = params;

      this.page = 1;
      this.getCadences();
    },
    onSwitchChange(row, value) {
      this.updateCadence({ id: row.id, data: { enabled: row.enabled } });
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selected = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      const { id } = this.selected;

      this.deleteCadence(id);
    },
    clearData() {
      this.page = 1;
      this.searchQuery = '';
      this.oldSearchQuery = '';
      this.sortConfig = 'id';
    },
  },
  mounted() {
    this.getCadences();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-container {
    .columns.wait-message {
      p {
        margin-top: var(--space-large);
        margin-bottom: 0 !important;
      }

      ~ .modal-footer {
        button {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}

.table-actions-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>