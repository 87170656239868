import BoardsView from './components/BoardsView';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
    {
      path: frontendURL('accounts/:accountId/boards'),
      name: 'boards',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: () => {
        return { inboxId: 0 };
      },
    },
    {
      path: frontendURL('accounts/:accountId/boards/mentions/conversations'),
      name: 'board_mentions',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: () => ({ conversationType: 'mention' }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/boards/mentions/conversations/:conversationId'
      ),
      name: 'board_conversation_through_mentions',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: route => ({
        conversationId: route.params.conversationId,
        conversationType: 'mention',
      }),
    },
    {
      path: frontendURL('accounts/:accountId/boards/conversations/:conversation_id'),
      name: 'board_inbox_conversation',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: route => {
        return { inboxId: 0, conversationId: route.params.conversation_id };
      },
    },
    {
      path: frontendURL('accounts/:accountId/boards/team/:teamId'),
      name: 'team_boards',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: route => ({ teamId: route.params.teamId }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/boards/team/:teamId/conversations/:conversationId'
      ),
      name: 'board_conversations_through_team',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: route => ({
        conversationId: route.params.conversationId,
        teamId: route.params.teamId,
      }),
    },
    {
      path: frontendURL('accounts/:accountId/boards/inbox/:inbox_id'),
      name: 'boards_dashboard',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: route => {
        return { inboxId: route.params.inbox_id };
      },
    },
    {
      path: frontendURL(
        'accounts/:accountId/boards/inbox/:inbox_id/conversations/:conversation_id'
      ),
      name: 'board_conversation_through_inbox',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: route => {
        return {
          conversationId: route.params.conversation_id,
          inboxId: route.params.inbox_id,
        };
      },
    },
    {
      path: frontendURL('accounts/:accountId/boards/label/:label'),
      name: 'label_boards',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: route => ({ label: route.params.label }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/boards/label/:label/conversations/:conversation_id'
      ),
      name: 'board_conversations_through_label',
      roles: ['administrator', 'agent', 'supervisor', 'analyzer', 'assistant'],
      component: BoardsView,
      props: route => ({
        conversationId: route.params.conversation_id,
        label: route.params.label,
      }),
    },
];