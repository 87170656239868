export const MACRO_ACTION_TYPES = [
  {
    key: 'assign_team',
    label: 'Assign a team',
    inputType: 'search_select',
    attributeI18nKey: 'ASSIGN_TEAM'
  },
  {
    key: 'assign_agent',
    label: 'Assign an agent',
    inputType: 'search_select',
    attributeI18nKey: 'ASSIGN_AGENT',
  },
  {
    key: 'add_label',
    label: 'Add a label',
    inputType: 'multi_select',
    attributeI18nKey: 'ADD_LABEL',
  },
  {
    key: 'remove_label',
    label: 'Remove a label',
    inputType: 'multi_select',
    attributeI18nKey: 'REMOVE_LABEL',
  },
  {
    key: 'remove_assigned_team',
    label: 'Remove Assigned Team',
    inputType: null,
    attributeI18nKey: 'REMOVE_ASSIGNED_TEAM',
  },
  {
    key: 'send_email_transcript',
    label: 'Send an email transcript',
    inputType: 'email',
    attributeI18nKey: 'SEND_EMAIL_TRANSCRIPT',
  },
  {
    key: 'mute_conversation',
    label: 'Mute conversation',
    inputType: null,
    attributeI18nKey: 'MUTE_CONVERSATION',
  },
  {
    key: 'snooze_conversation',
    label: 'Snooze conversation',
    inputType: null,
    attributeI18nKey: 'SNOOZE_CONVERSATION',
  },
  {
    key: 'resolve_conversation',
    label: 'Resolve conversation',
    inputType: null,
    attributeI18nKey: 'RESOLVE_CONVERSATION',
  },
  {
    key: 'send_attachment',
    label: 'Send Attachment',
    inputType: 'attachment',
    attributeI18nKey: 'SEND_ATTACHMENT',
  },
  {
    key: 'send_message',
    label: 'Send a message',
    inputType: 'textarea',
    attributeI18nKey: 'SEND_MESSAGE',
  },
  {
    key: 'add_private_note',
    label: 'Add a private note',
    inputType: 'textarea',
    attributeI18nKey: 'SEND_PRIVATE_NOTE',
  },
  {
    key: 'change_priority',
    label: 'Change Priority',
    inputType: 'search_select',
    attributeI18nKey: 'CHANGE_PRIORITY'
  },
];
