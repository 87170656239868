<template>
  <div class="colums">
    <div
      v-if="isValidVariablesHeader && variablesHeader"
      class="template-variables-container"
    >
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_HEADER') }}
      </p>

      <div
        v-for="(variable, key) in template.processedParamsHeader"
        :key="key"
        class="template-variable-item"
      >
        <span class="variable-label">{{ key }}</span>

        <woot-input
          v-model="template.processedParamsHeader[key]"
          class="variable-input"
          type="text"
          :styles="{ marginBottom: 0 }"
        />
      </div>
    </div>

    <div
      v-if="isValidVariablesBody && variablesBody"
      class="template-variables-container"
    >
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_BODY') }}
      </p>

      <div
        v-for="(variable, key) in template.processedParamsBody"
        :key="key"
        class="template-variable-item"
      >
        <span class="variable-label">{{ key }}</span>

        <woot-input
          v-model="template.processedParamsBody[key]"
          class="variable-input"
          type="text"
          :styles="{ marginBottom: 0 }"
        />
      </div>
    </div>

    <p
      v-if="
        (isValidVariablesBody || isValidVariablesHeader) &&
        v.$dirty &&
        v.$invalid
      "
      class="error"
    >
      {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isValidVariablesHeader: {
      type: Boolean | undefined,
    },
    isValidVariablesBody: {
      type: Boolean | undefined,
    },
    template: {
      type: Object,
      default: {},
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    variablesHeader() {
      return this.template.header?.match(/{{([^}]+)}}/g) || '';
    },
    variablesBody() {
      return this.template.body.match(/{{([^}]+)}}/g);
    },
  },
};
</script>

<style lang="scss" scoped>
.template-variables-container {
  padding: var(--space-one);

  .variables-label {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--space-one);
  }

  .template-variable-item {
    align-items: center;
    display: flex;
    margin-bottom: var(--space-one);

    .label {
      font-size: var(--font-size-mini);
    }

    .variable-input {
      flex: 1;
      font-size: var(--font-size-small);
      margin-left: var(--space-one);
    }

    .variable-label {
      background-color: var(--s-75);
      border-radius: var(--border-radius-normal);
      display: inline-block;
      font-size: var(--font-size-mini);
      padding: var(--space-one) var(--space-medium);
    }
  }
}

.error {
  background-color: var(--r-100);
  border-radius: var(--border-radius-normal);
  color: var(--r-800);
  padding: var(--space-one);
  text-align: center;
  margin-bottom: var(--space-two);
}
</style>