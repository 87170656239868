<template>
  <div ref="dashboardsuperset">
    <loading-state
      v-if="iframeLoading"
      :message="$t('DASHBOARD_APPS.LOADING_MESSAGE')"
    />
  </div>
</template>

<script>
import LoadingState from 'dashboard/components/widgets/LoadingState';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { mapGetters } from 'vuex';

export default {
  components: {
    LoadingState,
  },
  props: ['supersetId'],
  data() {
    return {
      iframeLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.iframeLoading = true;
      axios
        .post(
          `/api/v1/accounts/${this.accountId}/dashboard_apps/${this.supersetId}/superset/guest-token`,
          {
            dashboard_id: this.supersetId,
            rls: [
              {
                clause: `account_id=${this.accountId}`,
              },
            ],
          }
        )
        .then(({ data }) => {
          this.iframeLoading = false;
          embedDashboard({
            id: this.supersetId,
            supersetDomain: 'https://superset.phonetrack.com.br',
            mountPoint: this.$refs.dashboardsuperset,
            fetchGuestToken: () => data.token,
            dashboardUiConfig: {
              hideTitle: true,
              filters: {
                visible: false,
                expanded: false,
              },
            },
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
</style>