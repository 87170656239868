<template>
  <div v-if="isATwilioChannel" class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.TITLE')"
      :sub-title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE')"
    >
      <woot-code :script="inbox.callback_webhook_url" lang="html" />
    </settings-section>
  </div>
  <div v-else-if="isALineChannel" class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.TITLE')"
      :sub-title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.SUBTITLE')"
    >
      <woot-code :script="inbox.callback_webhook_url" lang="html" />
    </settings-section>
  </div>
  <div v-else-if="isAWebWidgetInbox">
    <div class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_HEADING')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_SUB_HEAD')"
      >
        <woot-code
          :script="inbox.web_widget_script"
          lang="html"
          :codepen-title="`${inbox.name} - Chatwoot Widget Test`"
          :enable-code-pen="true"
        />
      </settings-section>

      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION')"
      >
        <woot-code :script="inbox.hmac_token" />
        <template #subTitle>
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION') }}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.chatwoot.com/docs/product/channels/live-chat/sdk/identity-validation/"
          >
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.HMAC_LINK_TO_DOCS') }}
          </a>
        </template>
      </settings-section>
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION')"
      >
        <div class="enter-to-send--checkbox">
          <input
            id="hmacMandatory"
            v-model="hmacMandatory"
            type="checkbox"
            @change="handleHmacFlag"
          />
          <label for="hmacMandatory">
            {{ $t('INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL') }}
          </label>
        </div>
      </settings-section>
    </div>
  </div>
  <div v-else-if="isAPIInbox" class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER_SUB_TEXT')"
    >
      <woot-code :script="inbox.inbox_identifier" />
    </settings-section>

    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION')"
    >
      <woot-code :script="inbox.hmac_token" />
    </settings-section>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION')"
    >
      <div class="enter-to-send--checkbox">
        <input
          id="hmacMandatory"
          v-model="hmacMandatory"
          type="checkbox"
          @change="handleHmacFlag"
        />
        <label for="hmacMandatory">
          {{ $t('INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL') }}
        </label>
      </div>
    </settings-section>
  </div>
  <div v-else-if="isAnEmailChannel">
    <div class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_SUB_TEXT')"
      >
        <woot-code :script="inbox.forward_to_email" />
      </settings-section>
    </div>
    <imap-settings :inbox="inbox" />
    <smtp-settings v-if="inbox.imap_enabled" :inbox="inbox" />
    <microsoft-reauthorize
      v-if="inbox.microsoft_reauthorization"
      :inbox="inbox"
    />
  </div>
  <div v-else-if="is360DialogWhatsAppChannel">
    <div v-if="inbox.provider_config" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_WEBHOOK_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_WEBHOOK_SUBHEADER')"
      >
        <woot-code :script="inbox.provider_config.webhook_verify_token" />
      </settings-section>
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_SUBHEADER')"
      >
        <woot-code :script="inbox.provider_config.api_key" />
      </settings-section>
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_TITLE')"
        :sub-title="
          $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_SUBHEADER')
        "
      >
        <div class="whatsapp-settings--content">
          <woot-input
            v-model.trim="whatsAppInboxAPIKey"
            type="text"
            class="input"
            :placeholder="
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_PLACEHOLDER'
              )
            "
          />
          <woot-button
            :disabled="$v.whatsAppInboxAPIKey.$invalid"
            @click="updateWhatsAppInboxAPIKey"
          >
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_BUTTON') }}
          </woot-button>
        </div>
      </settings-section>
      <settings-section
        title="Migrar para o Cloud API"
        sub-title="Este botão faz a troca do cadastro interno do número do On-Premisse para o Cloud API."
      >
        <div class="whatsapp-settings--content">
          <woot-button
            :disabled="inbox.provider != 'default'"
            @click="updateWhatsAppInboxProvider"
          >
            Migrar para o Cloud API
          </woot-button>
        </div>
      </settings-section>
    </div>
  </div>
  <div v-else-if="isVollChannel" class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.VOLL_SECTION_DOMAIN_TITLE')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.VOLL_SECTION_DOMAIN_SUBHEADER')"
    >
      <woot-code :script="inbox.domain" />
    </settings-section>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.VOLL_SECTION_APIKEY_TITLE')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.VOLL_SECTION_APIKEY_SUBHEADER')"
    >
      <woot-code :script="inbox.api_key" />
    </settings-section>
    <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.VOLL_SECTION_APIKEY_UPDATE_TITLE')"
        :sub-title="
          $t('INBOX_MGMT.SETTINGS_POPUP.VOLL_SECTION_APIKEY_UPDATE_SUBHEADER')
        "
      >
        <div class="whatsapp-settings--content">
          <woot-input
            v-model.trim="vollInboxAPIKey"
            type="text"
            class="input"
            :placeholder="
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_PLACEHOLDER'
              )
            "
          />
          <woot-button
            :disabled="$v.vollInboxAPIKey.$invalid"
            @click="updateVollInboxAPIKey"
          >
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_BUTTON') }}
          </woot-button>
        </div>
    </settings-section>
    <settings-section
        title="Número"
        sub-title="Filtro aplicado por Número"
      >
        <div class="whatsapp-settings--content">
          <woot-input
            v-model.trim="vollNumber"
            type="text"
            class="input"
            placeholder="+55DDD900000000"
          />
          <woot-button
            :disabled="$v.vollNumber.$invalid"
            @click="updateVollNumber"
          >
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_BUTTON') }}
          </woot-button>
        </div>
    </settings-section>
    <settings-section
        title="Agentes"
        sub-title="Filtro aplicado por agentes"
      >
        <div class="whatsapp-settings--content">
          <multiselect
              ref="multiselect_incoming"
              v-model="vollAgents"
              selectedLabel="Selecionado"
              tag-placeholder="Adicionar esse agente"
              placeholder="Adicionar agentes"
              label="name"
              track-by="id"
              deselect-label=""
              :max-height="160"
              :allow-empty="true"
              :option-height="104"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              :options="vollAgentsOptions"
              tag-position="bottom"
            >
              <span slot="noOptions">Lista vazia</span>
          </multiselect>
          <woot-button
            style="align-self: baseline;margin-left: 0.8rem;"
            @click="updateVollAgents"
          >
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_BUTTON') }}
          </woot-button>
        </div>
    </settings-section>
    <settings-section
        title="Mínimo de mensagens"
        sub-title="Filtro aplicado por quantidade mínima de mensanges na conversa"
      >
        <div class="whatsapp-settings--content">
          <woot-input
            v-model.trim="vollMinMessages"
            type="text"
            class="input"
          />
          <woot-button
            :disabled="$v.vollMinMessages.$invalid"
            @click="updateVollMinMessages"
          >
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_BUTTON') }}
          </woot-button>
        </div>
      </settings-section>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import SettingsSection from '../../../../../components/SettingsSection';
import ImapSettings from '../ImapSettings';
import SmtpSettings from '../SmtpSettings';
import MicrosoftReauthorize from '../channels/microsoft/Reauthorize';
import { required, numeric } from 'vuelidate/lib/validators';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';

export default {
  components: {
    SettingsSection,
    ImapSettings,
    SmtpSettings,
    MicrosoftReauthorize,
  },
  mixins: [inboxMixin, alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hmacMandatory: false,
      whatsAppInboxAPIKey: '',
      vollInboxAPIKey: '',
      vollNumber: this.inbox.custom_config?.number,
      vollAgents: this.inbox.custom_config?.agents,
      vollMinMessages: this.inbox.custom_config?.min_messages,
      vollAgentsOptions: [],
    };
  },
  validations: {
    whatsAppInboxAPIKey: { required },
    vollInboxAPIKey: { required },
    vollNumber: { required, isPhoneE164OrEmpty },
    vollMinMessages: { required, numeric },
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.hmacMandatory = this.inbox.hmac_mandatory || false;
    },
    handleHmacFlag() {
      this.updateInbox();
    },
    async updateInbox() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {
            hmac_mandatory: this.hmacMandatory,
          },
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async updateWhatsAppInboxAPIKey() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {},
        };

        payload.channel.provider_config = {
          ...this.inbox.provider_config,
          api_key: this.whatsAppInboxAPIKey,
        };

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async updateVollInboxAPIKey() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {
            api_key: this.vollInboxAPIKey
          },
        };

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async updateWhatsAppInboxProvider() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {
            provider: '360_cloud',
          },
        };

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async updateVollNumber() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {},
        };

        payload.channel.custom_config = {
          ...this.inbox.custom_config,
          number: this.vollNumber,
        };

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async updateVollAgents() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {},
        };

        payload.channel.custom_config = {
          ...this.inbox.custom_config,
          agents: this.vollAgents,
        };

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async updateVollMinMessages() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {},
        };

        payload.channel.custom_config = {
          ...this.inbox.custom_config,
          min_messages: parseInt(this.vollMinMessages),
        };

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.vollAgentsOptions.push(tag)
      this.vollAgents.push(tag)
    },
  },
};
</script>
<style lang="scss" scoped>
.whatsapp-settings--content {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: var(--space-small);

  .input {
    flex: 1;
    margin-right: var(--space-small);
    ::v-deep input {
      margin-bottom: 0;
    }
  }
}

.hmac-link-to-docs {
  margin-top: var(--space-small);
}
</style>
