<template>
  <header class="header">
    <div class="table-actions-wrap">
      <div class="left-aligned-wrap">
        <woot-sidemenu-icon />

        <h1 class="page-title header-title">
          {{ $t(headerTitle) }}
        </h1>
      </div>

      <div class="right-aligned-wrap">
        <div class="search-wrap">
          <fluent-icon icon="search" class="search-icon" />
          <input
            type="text"
            :placeholder="$t('CAMPAIGN.LIST.SEARCH_INPUT_PLACEHOLDER')"
            class="content-search"
            :value="searchQuery"
            @keyup.enter="onSearchSubmit"
            @input="onInputSearch"
          />
          <woot-button
            :is-loading="false"
            class="clear"
            :class-names="searchButtonClass"
            @click="onSearchSubmit"
          >
            {{ $t('CAMPAIGN.LIST.SEARCH_BUTTON') }}
          </woot-button>
        </div>

        <woot-button
          class="margin-right-1 clear"
          color-scheme="success"
          icon="whatsapp"
          @click="openAddPopup"
        >
          {{ $t('CAMPAIGN.HEADER_BTN_TXT.WHATSAPP') }}
        </woot-button>
      </div>

      <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup" size="low">
        <add-whatsapp-campaign @on-close="hideAddPopup" />
      </woot-modal>
    </div>
  </header>
</template>

<script>
import AddWhatsappCampaign from './AddCampaign';

export default {
  components: { AddWhatsappCampaign },
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      default: '',
    },
    onInputSearch: {
      type: Function,
      default: () => {},
    },
    onSearchSubmit: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return { showAddPopup: false };
  },
  computed: {
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  margin: 0;
}

.table-actions-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--space-small) var(--space-normal) var(--space-small)
    var(--space-normal);
}

.left-aligned-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-width: var(--space-mega);

  .header-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 var(--space-small);
  }
}

.right-aligned-wrap {
  display: flex;
}

.search-wrap {
  max-width: 400px;
  min-width: 150px;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: var(--space-small);
  margin-left: var(--space-small);

  .search-icon {
    position: absolute;
    top: 1px;
    left: var(--space-one);
    height: 3.8rem;
    line-height: 3.6rem;
    font-size: var(--font-size-medium);
    color: var(--b-700);
  }
  .content-search {
    margin: 0;
    height: 3.8rem;
    width: 100%;
    padding-left: var(--space-large);
    padding-right: 6rem;
    border-color: var(--s-100);
  }

  .button {
    margin-left: var(--space-small);
    height: 3.2rem;
    right: var(--space-smaller);
    position: absolute;
    padding: 0 var(--space-small);
    transition: transform 100ms linear;
    opacity: 0;
    transform: translateX(-1px);
    visibility: hidden;
  }

  .button.show {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
</style>
