<template>
  <div>
    <span
      v-for="label in labels"
      :key="label.title"
      :style="getStyle(label.color)"
    >
      {{ label.title }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getStyle(color) {
      return {
        backgroundColor: color,
        color: 'white',
        padding: '4px 7px',
        borderRadius: '3px',
        fontSize: '10px',
        marginRight: '3px',
      };
    },
  },
};
</script>