<template>
  <superset class="column" supersetId="246cb1de-c273-4363-bb2a-16837c1d2b7b" />
</template>

<script>
import Superset from './Superset.vue';
export default {
  components: {
    Superset,
  },
};
</script>