import { frontendURL } from '../../../../helper/URLHelper';

const boards = accountId => ({
  parentNav: 'boards',
  routes: [
    'boards',
    'boards_dashboard',
    'board_mentions',
    'team_boards',
    'label_boards',
    'board_mentions',
    'board_conversations_through_label',
    'board_conversation_through_inbox',
    'board_conversations_through_team',
    'board_inbox_conversation',
    'board_conversation_through_mentions',
  ],
  menuItems: [
    {
      icon: 'chat',
      label: 'ALL_CONVERSATIONS',
      key: 'boards',
      toState: frontendURL(`accounts/${accountId}/boards`),
      toolTip: 'Conversation from all subscribed inboxes',
      toStateName: 'boards_dashboard',
    },
    {
      icon: 'mention',
      label: 'MENTIONED_CONVERSATIONS',
      key: 'board_mentions',
      toState: frontendURL(`accounts/${accountId}/boards/mentions/conversations`),
      toStateName: 'board_mentions',
    },
  ],
});

export default boards;
