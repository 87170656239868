<template>
  <div class="table-actions-wrap">
    <label>
      {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.HEADER.LABEL') }}
      <woot-switch v-model="optionsComponents.header" />
    </label>

    <label>
      {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.FOOTER.LABEL') }}
      <woot-switch v-model="optionsComponents.footer" />
    </label>

    <label>
      {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.LABEL') }}
      <woot-switch v-model="optionsComponents.buttons" />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    optionsComponents: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style scoped lang="scss">
.table-actions-wrap {
  display: flex;
  margin-bottom: var(--space-medium);

  label {
    margin-right: var(--space-jumbo);
  }
}
</style>