<template>
  <section class="table-wrap">
    <ve-table
      :fixed-header="true"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
      scroll-width="auto"
    />

    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('INBOX_MGMT.TEMPLATES.404')"
    />

    <empty-state
      v-else-if="!isLoading && !templates.length"
      :title="$t('INBOX_MGMT.TEMPLATES.NO_TEMPLATES')"
    />

    <div v-if="isLoading" class="template--loader">
      <spinner />
      <span>{{ $t('INBOX_MGMT.TEMPLATES.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';

import Spinner from 'shared/components/Spinner';
import EmptyState from 'dashboard/components/widgets/EmptyState';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import Content from './Content';
import Bagde from './Bagde';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
    };
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }

      return this.templates;
    },
    columns() {
      return [
        {
          field: 'business_templates.name',
          key: 'business_templates.name',
          title: this.$t('INBOX_MGMT.TEMPLATES.HEADER.NAME'),
          fixed: 'left',
          align: 'left',
          width: 290,
          sortBy: this.sortConfig['business_templates.name'] || '',
          renderBodyCell: ({ row }) => row.name,
        },
        {
          field: 'category',
          key: 'category',
          title: this.$t('INBOX_MGMT.TEMPLATES.HEADER.CATEGORY'),
          align: 'left',
          width: 130,
          renderBodyCell: ({ row }) => {
            return <Bagde template={row} type="category" />;
          },
        },
        {
          field: 'content',
          key: 'content',
          title: this.$t('INBOX_MGMT.TEMPLATES.HEADER.CONTENT'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            return <Content template={row} />;
          },
        },
        {
          field: 'status',
          key: 'status',
          title: this.$t('INBOX_MGMT.TEMPLATES.HEADER.LANGUAGE'),
          align: 'center',
          width: 160,
          sortBy: this.sortConfig.status || '',
          renderBodyCell: ({ row }) => {
            return <Bagde template={row} type="language" />;
          },
        },
        {
          field: 'actions',
          key: 'actions',
          title: this.$t('INBOX_MGMT.TEMPLATES.HEADER.ACTIONS'),
          align: 'center',
          width: 100,
          renderBodyCell: ({ row }) => {
            return (
              <WootButton
                variant="link"
                icon="dismiss-circle"
                color-scheme="secondary"
                onClick={() => this.$emit('on-delete-click', row)}
              >
                {this.$t('INBOX_MGMT.TEMPLATES.DELETE.BUTTON_TEXT')}
              </WootButton>
            );
          },
        },
      ];
    },
    emptyMessage() {
      return this.templates.length
        ? this.$t('INBOX_MGMT.TEMPLATES.404')
        : this.$t('INBOX_MGMT.TEMPLATES.INBOXES_NOT_FOUND');
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);

    .ve-table-container {
      overflow-y: hidden !important;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    font-size: var(--font-size-mini) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;

    a {
      font-weight: var(--font-weight-medium);
    }
  }

  .ve-table-sort {
    top: -4px;
  }
}

.template--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  min-width: 20rem;
}
</style>
