<template>
  <modal :show.sync="show" :on-close="onClose">
    <woot-modal-header
      :header-title="title"
      :header-content="message"
      :header-content-value="messageValue"
    />

    <div
      class="columns"
      :class="{ 'wait-message': isLoading }"
      v-if="canShowLabel && isLoading"
    >
      <Label />
    </div>

    <div class="modal-footer delete-item">
      <woot-button variant="clear" class="action-button" @click="onClose">
        {{ rejectText }}
      </woot-button>
      <woot-button
        color-scheme="alert"
        class="action-button"
        variant="smooth"
        @click="onConfirm"
      >
        {{ confirmText }}
      </woot-button>
    </div>
  </modal>
</template>

<script>
import Label from 'shared/components/Label';
import Modal from '../../Modal';

export default {
  components: {
    Label,
    Modal,
  },
  props: {
    show: Boolean,
    onClose: { type: Function, default: () => {} },
    onConfirm: { type: Function, default: () => {} },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    messageValue: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    rejectText: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    canShowLabel: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
.action-button {
  max-width: var(--space-giga);
}
</style>
