<template>
  <div class="columns" v-if="optionsComponents.buttons">
    <label class="custom-label">
      <span>
        {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.LABEL') }}
        <fluent-icon
          icon="info"
          size="14"
          v-tooltip.top-start="
            $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.TOOLTIP')
          "
        />
      </span>

      <woot-button type="button" variant="link" icon="add" @click="addButton">
        {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ADD_BUTTON') }}
      </woot-button>
    </label>

    <div class="card" v-for="(button, index) in template.buttons" :key="index">
      <div class="row">
        <div class="medium-11">
          <label>
            <select v-model="button.type" @change="onChangeType(button)">
              <option
                v-for="item in typeButton"
                :key="item.key"
                :value="item.key"
              >
                {{ item.value }}
              </option>
            </select>
          </label>

          <div v-if="button.type === getTypes.url" class="card">
            <label
              :class="{
                error: v.template.buttons.$each[index].text.$error,
              }"
            >
              {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.SHORT_TEXT') }}

              <input
                v-model.trim="button.text"
                type="text"
                minlength="1"
                maxlength="25"
                :placeholder="$t('WHATSAPP_TEMPLATES.ADD.INSERT')"
                @blur="v.template.buttons.$each[index].text.$touch"
                @keyup="onKeyupRemoveEmoji(button)"
              />

              <span
                v-if="v.template.buttons.$each[index].text.$error"
                class="message"
              >
                {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR') }}
              </span>
            </label>

            <label
              :class="{
                error: v.template.buttons.$each[index].url.$error,
              }"
            >
              {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.URL_LABEL') }}

              <input
                v-model.trim="button.url"
                type="text"
                minlength="1"
                maxlength="2000"
                :placeholder="
                  $t(
                    'WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.URL_PLACEHOLDER'
                  )
                "
                @blur="v.template.buttons.$each[index].url.$touch"
              />

              <span
                v-if="v.template.buttons.$each[index].url.$error && !button.url"
                class="message"
              >
                {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR') }}
              </span>

              <span
                v-else-if="v.template.buttons.$each[index].url.$error"
                class="message"
              >
                {{
                  $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.URL_INVALID')
                }}
              </span>
            </label>
          </div>

          <div v-if="button.type === getTypes.quickReply" class="card">
            <label
              :class="{
                error: v.template.buttons.$each[index].text.$error,
              }"
            >
              {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.SHORT_TEXT') }}

              <input
                v-model.trim="button.text"
                type="text"
                minlength="1"
                maxlength="25"
                :placeholder="$t('WHATSAPP_TEMPLATES.ADD.INSERT')"
                @blur="v.template.buttons.$each[index].text.$touch"
                @keyup="onKeyupRemoveEmoji(button)"
              />

              <span
                v-if="v.template.buttons.$each[index].text.$error"
                class="message"
              >
                {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR') }}
              </span>
            </label>
          </div>

          <div v-if="button.type === getTypes.phoneNumber" class="card">
            <label
              :class="{
                error: v.template.buttons.$each[index].text.$error,
              }"
            >
              {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.SHORT_TEXT') }}

              <input
                v-model.trim="button.text"
                type="text"
                minlength="1"
                maxlength="25"
                :placeholder="$t('WHATSAPP_TEMPLATES.ADD.INSERT')"
                @blur="v.template.buttons.$each[index].text.$touch"
                @keyup="onKeyupRemoveEmoji(button)"
              />

              <span
                v-if="v.template.buttons.$each[index].text.$error"
                class="message"
              >
                {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR') }}
              </span>
            </label>

            <label
              :class="{
                error: v.template.buttons.$each[index].phone_number.$error,
              }"
            >
              {{
                $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.PHONE_LABEL')
              }}

              <input
                v-model="button.phone_number"
                type="text"
                minlength="13"
                maxlength="20"
                :placeholder="
                  $t(
                    'WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.PHONE_PLACEHOLDER'
                  )
                "
                @input="onInputPhone(button)"
                @blur="v.template.buttons.$each[index].phone_number.$touch"
              />

              <span
                v-if="
                  v.template.buttons.$each[index].phone_number.$error &&
                  !button.phone_number
                "
                class="message"
              >
                {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR') }}
              </span>

              <span
                v-else-if="v.template.buttons.$each[index].phone_number.$error"
                class="message"
              >
                {{
                  $t(
                    'WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.PHONE_INVALID'
                  )
                }}
              </span>
            </label>
          </div>
        </div>

        <div class="medium-1">
          <woot-button
            type="button"
            v-tooltip.top="$t('LABEL_MGMT.FORM.DELETE')"
            variant="smooth"
            color-scheme="alert"
            size="tiny"
            icon="dismiss-circle"
            @click="removeButton(index)"
          />
        </div>
      </div>
    </div>

    <p v-if="!template.buttons.length && v.$invalid" class="error-message">
      {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.INVALID') }}
    </p>
  </div>
</template>

<script>
import templateWppMixin from 'shared/mixins/templateWppMixin';

export default {
  mixins: [templateWppMixin],
  props: {
    optionsComponents: {
      type: Object,
      default: {},
    },
    template: {
      type: Object,
      default: {},
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      limitButtons: 10,
    };
  },
  computed: {
    typeButton() {
      return [
        { key: this.getTypes.url, value: this.getTypes.url },
        { key: this.getTypes.quickReply, value: 'Resposta rápida' },
        { key: this.getTypes.phoneNumber, value: 'Número de telefone' },
      ];
    },
  },
  methods: {
    addButton() {
      if (this.template.buttons.length) {
        this.v.template.buttons.$touch();
      } else {
        this.v.$reset();
      }

      if (
        !this.v.template.buttons.$error &&
        this.template.buttons.length < this.limitButtons
      ) {
        this.template.buttons.push({ type: this.getTypes.quickReply });
      }
    },
    removeButton(index) {
      this.template.buttons.splice(index, 1);
    },
    onInputPhone(button) {
      button.phone_number = button.phone_number.replace(/\D/g, '');
      button.phone_number = Number(button.phone_number);
    },
    onKeyupRemoveEmoji(button) {
      button.text = button.text
        .replace(this.getRegexs.emoji, '')
        .replace(this.getRegexs.spaces, ' ')
        .trim();
    },
    onChangeType(button) {
      const { url, phoneNumber, quickReply } = this.getTypes;

      if (button.type === url) delete button.phone_number;
      if (button.type === phoneNumber) delete button.url;
      if (button.type === quickReply) {
        delete button.phone_number;
        delete button.url;
      }
    },
  },
  watch: {
    'optionsComponents.buttons'(new_value) {
      if (new_value) this.addButton();
    },
  },
};
</script>

<style lang="scss" scoped>
.columns {
  .custom-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-one);

    button {
      &:hover {
        background-color: transparent !important;
        text-decoration: none !important;
      }
    }
  }

  .medium-1 {
    text-align: end;
  }

  .error-message {
    background-color: var(--r-100);
    border-radius: var(--border-radius-normal);
    color: var(--r-800);
    padding: var(--space-one);
    text-align: center;
    margin-bottom: var(--space-two);
  }
}
</style>