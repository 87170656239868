<template>
  <div class="settings--content">
    <settings-section
      title="Sessão WhatsApp"
      sub-title="Detalhe da sessão ativa do WhatsApp Web"
    >
      <div v-if="status" class="medium-4 text-center">
        <div v-if="showQRCodeModal" class="setting--qrcode">
          <img :src="qrcode" :alt="inbox.name" />
        </div>
        <p v-if="sessionInterval != null" class="fs-default setting--status">
          <span v-if="status == 'QRCODE'">Ler QR Code</span>
          <span v-else>Aguarde</span>
          <spinner size="small" />
        </p>
        <woot-button
          v-if="status == 'CONNECTED'"
          type="button"
          size="small"
          color-scheme="alert"
          @click="handleWPPConnectLogoutSession"
        >
          Encerrar sessão
        </woot-button>
        <woot-button
          v-if="status == 'CLOSED' && sessionInterval == null"
          :is-loading="submiting"
          :is-disabled="submiting"
          type="button"
          size="small"
          color-scheme="primary"
          @click="handleWPPConnectStartSession"
        >
          Iniciar nova sessão
        </woot-button>
      </div>
    </settings-section>
    <settings-section
      title="Telefone para notificar"
      sub-title="Em caso de desconexão, este número de telefone receberá uma mensagem de whatsapp para ler novamente o QrCode."
    >
      <phone-number
        class="medium-8 columns"
        :value="phoneNumber"
        :onPhoneNumber="onSetPhoneNumber"
      />
      <woot-button
        type="button"
        size="small"
        color-scheme="primary"
        @click="handlePhoneNumber"
      >
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.UPDATE') }}
      </woot-button>
    </settings-section>
    <settings-section
      title="Link para reconectar"
      sub-title="Com esse link o usuário pode ler novamente o QrCode sem precisar fazer login no Converx."
    >
      <woot-code lang="text" :script="linkQrcode" />
    </settings-section>
    <settings-section
      title="Senha de Link"
      sub-title="Senha para acessar link e ler novamente o QrCode."
    >
      <woot-button
        type="button"
        size="small"
        color-scheme="primary"
        @click="handlePassword"
        v-if="!passwordLink"
      >
        Gerar senha do link
      </woot-button>
      <woot-code v-else lang="text" :script="passwordLink" />
    </settings-section>
    <settings-section
      v-if="isAdmin"
      title="Token de acesso"
      sub-title="Token de acesso"
    >
      <woot-code lang="text" :script="inbox.additional_attributes.token" />
    </settings-section>
    <settings-section
      v-if="isAdmin"
      title="Sessão criada"
      sub-title="Sessão criada"
    >
      <woot-code lang="text" :script="inbox.additional_attributes.session" />
    </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import inboxMixin from 'shared/mixins/inboxMixin';
import SettingsSection from '../../../../../components/SettingsSection';
import WppConnect from 'dashboard/api/wppconnect';
import Spinner from 'shared/components/Spinner';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';
import alertMixin from 'shared/mixins/alertMixin';
import PhoneNumber from 'dashboard/components/PhoneNumber';
import getUuid from 'widget/helpers/uuid';

export default {
  components: {
    SettingsSection,
    Spinner,
    PhoneNumber,
  },
  mixins: [inboxMixin, adminMixin, alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      status: '',
      qrcode: null,
      submiting: false,
      sessionInterval: null,
      countClosed: 0,
      phoneNumber: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    showQRCodeModal() {
      return !!this.qrcode && this.status != 'CONNECTED';
    },
    linkQrcode() {
      return `${location.origin}/whatsapp/${this.inbox.inbox_identifier}/qrcode`;
    },
  },
  validations: {
    phoneNumber: { isPhoneE164OrEmpty },
  },
  watch: {
    status() {
      if (this.status == 'CLOSED') this.countClosed += 1;
      if (this.countClosed >= 2 || this.status == 'CONNECTED') {
        clearInterval(this.sessionInterval);
        this.sessionInterval = null;
        this.countClosed = 1;
      }
    },
  },
  created() {
    WppConnect.loadConfig(this.inbox.additional_attributes);
    this.phoneNumber = this.inbox.additional_attributes.phoneNumber || '';
    this.passwordLink = this.inbox.additional_attributes.passwordLink || '';
  },
  mounted() {
    this.handleWPPConnectStatus();
  },
  methods: {
    onSetPhoneNumber(phone_number) {
      this.phoneNumber = phone_number;
    },
    async handleWPPConnectStatus() {
      const { status, qrcode } = await WppConnect.statusSession();
      this.status = status;
      this.qrcode = qrcode;
    },
    async handleWPPConnectStartSession() {
      if (this.submiting) return;
      this.submiting = true;
      await WppConnect.startSession({
        webhook: null,
        waitQrCode: true,
        chatWoot: {
          enable: true,
          baseURL: window.origin,
          token: this.currentUser.access_token,
          inbox_id: this.inbox.id,
          account_id: this.currentUser.account_id,
        },
      });

      this.qrcode = WppConnect.getQRCode();
      this.submiting = false;
      this.sessionInterval = setInterval(
        () => this.handleWPPConnectStatus(),
        5000
      );
    },
    async handleWPPConnectLogoutSession() {
      const { status } = await WppConnect.logoutSession();
      if (status) {
        this.qrcode = null;
        this.status = 'CLOSED';
      }
    },
    async handlePhoneNumber() {
      const payload = {
        id: this.inbox.id,
        formData: false,
        channel: {
          additional_attributes: {
            ...this.inbox.additional_attributes,
            phoneNumber: this.phoneNumber,
          },
        },
      };

      await this.$store.dispatch('inboxes/updateInbox', payload);
      this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
    },
    async handlePassword() {
      this.passwordLink = getUuid();

      const payload = {
        id: this.inbox.id,
        formData: false,
        channel: {
          additional_attributes: {
            ...this.inbox.additional_attributes,
            passwordLink: this.passwordLink,
          },
        },
      };

      await this.$store.dispatch('inboxes/updateInbox', payload);
      this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
    },
  },
  beforeDestroy() {
    clearInterval(this.sessionInterval);
  },
};
</script>
<style lang="scss" scoped>
.setting--qrcode {
  border: 1px solid #000;
  border-radius: 3px;
  padding: 1px;
  margin-bottom: 10px;
}
.setting--status {
  background: #000000;
  border-radius: 0 0 3px 3px;
  margin-top: -11px;
  font-size: 1.3rem;
  padding-top: 3px;
  color: #fff;
}
</style>