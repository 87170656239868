<template>
  <section class="table-wrap">
    <ve-table :fixed-header="true" scroll-width="90rem" :columns="columns" :table-data="tableData"
      :border-around="false" :sort-option="sortOption" />

    <empty-state v-if="showSearchEmptyState" :title="$t('CADENCES.LIST.NOT_FOUND')" />

    <empty-state v-else-if="!isLoading && !items.length" :title="$t('CADENCES.LIST.404')" />

    <div v-if="isLoading" class="item--loader">
      <spinner />
      <span>{{ $t('CADENCES.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import { VeTable } from 'vue-easytable';

import Spinner from 'shared/components/Spinner.vue';
import WootButton from 'dashboard/components/ui/WootButton';
import WootSwitch from 'components/ui/Switch';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import rtlMixin from 'shared/mixins/rtlMixin';

export default {
  components: {
    Spinner,
    WootButton,
    WootSwitch,
    EmptyState,
    VeTable,
  },
  mixins: [clickaway, rtlMixin, alertMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
    };
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }

      return this.items;
    },
    columns() {
      return [
        {
          field: 'name',
          key: 'name',
          title: this.$t('CADENCES.LIST.HEADER.NAME'),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.name || '',
          width: 100,
        },
        {
          field: 'url',
          key: 'url',
          title: this.$t('CADENCES.LIST.HEADER.URL'),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          width: 200,
          renderBodyCell: ({ row }) => {
            return (
              <div class="url-container">
                <p class="url-text">
                  {row.url}
                </p>
                <WootButton
                  variant="link"
                  icon="copy"
                  color-scheme="secondary"
                  onClick={() => this.copyToClipboard(row.url)}
                >
                </WootButton>
              </div>
            );
          },
        },
        {
          field: 'messages',
          key: 'messages',
          title: this.$t('CADENCES.LIST.HEADER.MESSAGES'),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
          renderBodyCell: ({ row }) => {
            return (
              <div>
                <span>{row.actions.length}</span>
              </div>
            );
          },
        },
        {
          field: 'enabled',
          key: 'enlabed',
          title: this.$t('CADENCES.LIST.HEADER.ENABLED'),
          align: 'left',
          width: 100,
          renderBodyCell: ({ row }) => {
            return (
              <div>
                <WootSwitch
                  value={row.enabled}
                  onInput={value => this.$emit('on-switch-change', { ...row, enabled: value })}
                />
              </div>
            );
          },
        },
        {
          field: 'actions',
          key: 'actions',
          title: this.$t('CADENCES.LIST.HEADER.ACTIONS'),
          align: 'center',
          width: 100,
          renderBodyCell: ({ row }) => {
            return (
              <WootButton
                variant="link"
                icon="dismiss-circle"
                color-scheme="secondary"
                onClick={() => this.$emit('on-delete-click', row)}
              >
                {this.$t('CADENCES.DELETE.BUTTON_TEXT')}
              </WootButton>
            );
          },
        },
      ];
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.showAlert(this.$t('CADENCES.LIST.URL_COPY.SUCCESS'));
      }).catch(err => {
        this.showAlert(this.$t('CADENCES.LIST.URL_COPY.ERROR'));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;

  .url-container {
    display: flex;
    align-items: center;

    .url-text {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);

    .ve-table-container {
      overflow-y: hidden !important;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    font-size: var(--font-size-mini) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;

    a {
      font-weight: var(--font-weight-medium);
    }
  }

  .ve-table-sort {
    top: -4px;
  }
}

.item--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}
</style>