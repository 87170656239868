<template>
  <div class="column content-box">
    <woot-modal-header
      class="text-center"
      :header-title="$t('CLOSING_REASONS.WARNING')"
    />

    <div
      v-if="getInboxOfClosingReasons.length === 0"
      class="alert-filter flex-center"
    >
      <div class="text-center">
        <fluent-icon icon="warning" size="40" />
      </div>

      <div>
        <p v-html="$t('CLOSING_REASONS.CREATE.NOT_FOUND')"></p>
      </div>
    </div>

    <form v-else @submit.prevent="submit" class="row">
      <div class="medium-12 columns">
        <label :class="{ error: $v.selectedReason.$error }">
          {{ $t('CLOSING_REASONS.CREATE.REASON.LABEL') }}
          <select v-model="selectedReason">
            <option
              v-for="reason in getInboxOfClosingReasons"
              :key="reason.name"
              :value="reason.id"
            >
              {{ reason.name }}
            </option>
          </select>

          <span v-if="$v.selectedReason.$error" class="message">
            {{ $t('CLOSING_REASONS.CREATE.REASON.ERROR') }}
          </span>
        </label>

        <label>
          {{ $t('CLOSING_REASONS.CREATE.COMMENT.LABEL') }}
          <textarea
            v-model="comment"
            rows="2"
            :placeholder="$t('CLOSING_REASONS.CREATE.COMMENT.PLACEHOLDER')"
          />
        </label>
      </div>

      <div class="modal-footer">
        <woot-button :is-loading="uiFlags.isCreating">
          {{ $t('CLOSING_REASONS.ADD.FORM.SUBMIT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import wootConstants from 'dashboard/constants/globals';
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
    currentChat: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedReason: null,
      comment: null,
    };
  },
  validations() {
    return {
      selectedReason: { required },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'closingReasons/getUIFlags',
      getInboxOfClosingReasons: 'inboxClosingReasons/getInboxOfClosingReasons',
    }),
  },
  methods: {
    ...mapActions('inboxClosingReasons', ['fetch']),
    ...mapActions('historyClosingReasons', ['create']),
    submit() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }

        const data = {
          history_closing_reasons: {
            conversation_id: this.currentChat.id,
            closing_reason_id: this.selectedReason,
            comments: this.comment,
          },
        };

        this.create(data);
        this.toggleStatus();
        this.$emit('on-close');
      } catch (error) {
        this.showAlert(this.$t('CLOSING_REASONS.ADD.API.ERROR_MESSAGE'));
      }
    },
    toggleStatus() {
      const { SNOOZED, RESOLVED } = wootConstants.STATUS_TYPE;
      const { options } = this;

      const snooze = Object.keys(options).length ? options.snooze : undefined;
      const status = Object.keys(options).length ? SNOOZED : RESOLVED;

      this.$store
        .dispatch('toggleStatus', {
          conversationId: this.currentChat.id,
          status: status,
          snoozedUntil: snooze,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'));
        });
    },
    async getData() {
      try {
        if (this.getInboxOfClosingReasons.length) {
          return;
        }

        await this.fetch(this.inbox.id);
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.alert-filter {
  padding: var(--space-small);
  margin: var(--space-large) var(--space-large);
  background: var(--r-50);
  border-radius: var(--border-radius-small);

  div:first-of-type {
    flex: 15 1;

    svg {
      color: var(--r-800);
    }
  }

  div:last-child {
    flex: 75 1;

    p {
      font-size: var(--font-size-mini);
      font-weight: var(--font-weight-medium);
      color: var(--b-900);
      line-height: 1.5;
    }
  }
}

.modal-footer {
  button {
    margin-left: 0 !important;
  }

  width: 100%;
  justify-content: end !important;
}
</style>