/* global axios */
class WppConnect {
  constructor() {
    this.baseUrl = `${globalConfig.WPPCONNECT_URL}/api`;
    this.secretkey = `${globalConfig.WPPCONNECT_SECRET}`;
  }

  loadConfig({ token, session }) {
    this.token = token;
    this.session = session;
  }

  getOptions() {
    return {
        headers: {
            Authorization: `Bearer ${this.token}`,
        }
    };
  }

  async generateToken(session) {
    const { data } = await axios.post(`${this.baseUrl}/${session}/${this.secretkey}/generate-token`);
    this.session = data.session;
    this.token = data.token;
    this.full = data.full;

    return new Promise((resolve) => resolve(data));
  }

  async startSession(payload) {
    const { data } = await axios.post(`${this.baseUrl}/${this.session}/start-session`, payload, this.getOptions());
    this.qrcode = data.qrcode;

    return new Promise((resolve) => resolve(data));
  }

  async statusSession() {
    const { data } = await axios.get(`${this.baseUrl}/${this.session}/status-session`, this.getOptions());
    this.qrcode = data.qrcode;

    return new Promise((resolve) => resolve(data));
  }

  async checkConnectionSession() {
    const { data } = await axios.get(`${this.baseUrl}/${this.session}/check-connection-session`, this.getOptions());
    return new Promise((resolve) => resolve(data));
  }

  async logoutSession() {
    const { data } = await axios.post(`${this.baseUrl}/${this.session}/logout-session`, {}, this.getOptions());
    return new Promise((resolve) => resolve(data));
  }
  async closeSession() {
    const { data } = await axios.post(`${this.baseUrl}/${this.session}/close-session`, {}, this.getOptions());
    // this.qrcode = data.qrcode;

    return new Promise((resolve) => resolve(data));
  }

  getQRCode() {
    if (!this.qrcode) return '';
    if (this.qrcode.includes('data:image/png;base64,')) {
        return this.qrcode;
    }
    return `data:image/png;base64,${this.qrcode}`;
  }

  getToken() {
    return this.token;
  }

  getSession() {
    return this.session;
  }

  getWebhookUrl() {
    return `${this.baseUrl}/${this.session}/chatwoot`;
  }
}

export default new WppConnect();
