<template>
  <div class="message_link_preview" @click="openUrl">
    <div v-if="isVideoYoutube">
      <iframe title="preview view" :src="video.src" class="preview_video" />
    </div>
    <div
      v-else-if="hasImages"
      class="preview_image"
      :style="{ backgroundImage: backgroundImage }"
    ></div>
    <div v-if="hasTitle" class="preview_title">{{ preview.title }}</div>
    <div v-if="hasDescription" class="preview_desc">
      {{ preview.description }}
    </div>
    <div v-if="hasUrl" class="preview_url">{{ preview.url }}</div>
  </div>
</template>

<script>
export default {
  props: ['preview'],
  computed: {
    hasUrl() {
      return !!this.preview?.url;
    },
    hasFavicon() {
      return !!this.preview?.favicon;
    },
    hasTitle() {
      return !!this.preview?.title;
    },
    hasDescription() {
      return !!this.preview?.description;
    },
    hasImages() {
      return !!this.preview?.images.length;
    },
    image() {
      return this.hasImages ? this.preview?.images[0] : '';
    },
    backgroundImage() {
      return this.hasImages ? `url(${this.image.src})` : '';
    },
    hasVideos() {
      return !!this.preview?.videos.length;
    },
    video() {
      return this.hasVideos ? this.preview?.videos[0] : {};
    },
    isVideoYoutube() {
      return this.video?.provider == 'YouTube';
    },
  },
  methods: {
    openUrl() {
      window.open(this.preview.url, '_blank');
    },
  },
};
</script>

<style lang="scss">
.message_link_preview {
  cursor: pointer;
  background: #f5f6f6;
  border-radius: 6px;
  padding: 6px;
  margin-bottom: 6px;
  .preview_image {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
  }
  .preview_video {
    width: 100%;
  }
  .preview_title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .preview_desc {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .preview_url {
    font-size: 0.8rem;
  }
}
</style>