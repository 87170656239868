<template>
  <div class="columns">
    <label
      :class="{
        error: v.template.body.$error || isValidVariablesBody === false,
      }"
    >
      {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BODY.LABEL') }}
      <fluent-icon
        icon="info"
        size="14"
        v-tooltip.top-start="
          $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BODY.TOOLTIP')
        "
      />

      <textarea
        v-model.lazy.trim="template.body"
        type="text"
        :placeholder="
          $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BODY.PLACEHOLDER')
        "
        rows="4"
        minlength="1"
        maxlength="1024"
        @blur="v.template.body.$touch"
      />

      <span v-if="v.template.body.$error" class="message">
        {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BODY.ERROR') }}
      </span>

      <span v-if="isValidVariablesBody === false" class="message">
        {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BODY.INVALID') }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    isValidVariablesBody: {
      type: Boolean | undefined,
    },
    template: {
      type: Object,
      default: {},
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
};
</script>