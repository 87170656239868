import { frontendURL } from '../../../../helper/URLHelper';

const reports = accountId => ({
  parentNav: 'reports',
  routes: [
    'account_overview_reports',
    'csat_reports',
    'attendance_reports',
    'attendance_detail_reports',
    'attendance_agent_reports',
    'insights_reports',
    'campaigns_reports',
    'dashboard_apps'
  ],
  menuItems: [
    {
      icon: 'arrow-trending-lines',
      label: 'REPORTS_OVERVIEW',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/reports/overview`),
      toStateName: 'account_overview_reports',
    },
    {
      icon: 'emoji',
      label: 'CSAT',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/reports/csat`),
      toStateName: 'csat_reports',
    },
    {
      icon: 'chat',
      label: 'ATTENDANCE',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/reports/attendances`),
      toStateName: 'attendance_reports',
    },
    {
      icon: 'mail-inbox-all',
      label: 'ATTENDANCE_DETAIL',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/reports/attendance-details`),
      toStateName: 'attendance_detail_reports',
    },
    {
      icon: 'people',
      label: 'ATTENDANCE_AGENT',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/reports/attendance-agents`),
      toStateName: 'attendance_agent_reports',
    },
    {
      icon: 'tag',
      label: 'INSIGHTS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/reports/insights`),
      toStateName: 'insights_reports',
    },
    {
      icon: 'megaphone',
      label: 'REPORTS_CAMPAIGN',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/reports/campaigns`),
      toStateName: 'campaigns_reports',
    },
    {
      icon: 'automation',
      label: 'REPORTS_DASHBOARD_APPS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/reports/dashboard-apps`),
      toStateName: 'reports_dashboard_apps',
    }
  ],
});

export default reports;
