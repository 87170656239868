<template>
  <div class="columns" v-if="optionsComponents.footer">
    <label
      :class="{
        error: v.template.footer.$error || hasVariablesInFooter,
      }"
    >
      {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.FOOTER.LABEL') }}

      <input
        v-model.lazy.trim="template.footer"
        type="text"
        minlength="1"
        maxlength="60"
        :placeholder="
          $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.FOOTER.PLACEHOLDER')
        "
        @blur="v.template.footer.$touch"
      />

      <span v-if="v.template.footer.$error" class="message">
        {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.FOOTER.ERROR') }}
      </span>

      <span v-if="hasVariablesInFooter" class="message">
        {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.FOOTER.INVALID') }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    hasVariablesInFooter: {
      type: Boolean | String,
    },
    optionsComponents: {
      type: Object,
      default: {},
    },
    template: {
      type: Object,
      default: {},
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
};
</script>