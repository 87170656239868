<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('CLOSING_REASONS.ADD.TITLE')"
      :header-content="$t('CLOSING_REASONS.ADD.DESC')"
    />

    <form class="row" @submit.prevent="submit">
      <div class="medium-12 columns">
        <label :class="{ error: $v.closing_reasons.name.$error }">
          {{ $t('CLOSING_REASONS.ADD.FORM.NAME.LABEL') }}

          <input
            v-model.trim="closing_reasons.name"
            type="text"
            minlength="3"
            maxlength="255"
            :placeholder="$t('CLOSING_REASONS.ADD.FORM.NAME.PLACEHOLDER')"
            @blur="$v.closing_reasons.name.$touch"
          />

          <span v-if="$v.closing_reasons.name.$error" class="message">
            {{ $t('CLOSING_REASONS.ADD.FORM.NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div class="modal-footer">
        <woot-button :is-loading="uiFlags.isCreating">
          {{ $t('CLOSING_REASONS.ADD.FORM.SUBMIT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],

  data() {
    return {
      closing_reasons: {
        name: '',
      },
    };
  },
  validations() {
    return {
      closing_reasons: {
        name: { required, minLength: minLength(3), maxLength: maxLength(255) },
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'closingReasons/getUIFlags',
    }),
  },
  methods: {
    ...mapActions('closingReasons', ['create']),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        await this.create({ closing_reasons: this.closing_reasons });

        this.showAlert(this.$t('CLOSING_REASONS.ADD.API.SUCCESS_MESSAGE'));
        this.$emit('on-created');
      } catch (error) {
        this.showAlert(this.$t('CLOSING_REASONS.ADD.API.ERROR_MESSAGE'));
      } finally {
        this.onClose();
      }
    },
    onClose() {
      this.$emit('on-close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-footer {
  width: 100%;
  justify-content: end;
}
</style>