<template>
  <superset class="column" supersetId="47e8301e-a6e9-4c3e-9195-074c78484301" />
</template>

<script>
import Superset from './Superset.vue';
export default {
  components: {
    Superset,
  },
};
</script>