<template>
  <superset class="column" supersetId="017681d5-8636-4b85-832d-aa17cea5effd" />
</template>

<script>
import Superset from './Superset.vue';
export default {
  components: {
    Superset,
  },
};
</script>