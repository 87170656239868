<template>
  <div class="timeline">
    <div class="timeline-item">
      <h6
        v-for="page in total"
        :key="page"
        :class="{
          'timeline-five': total === 5,
          'timeline-four': total === 4,
          current: page === current_page,
          concluded: page < current_page,
        }"
      >
        {{ page }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current_page: {
      default: 1,
      type: Number,
    },
    total: {
      default: 1,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  display: flex;
  max-width: 58%;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--space-large);

  .timeline-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-weight: bold;
      text-align: center;
      border: 2px solid #9b9b9b;
      border-radius: var(--border-radius-rounded);
      width: 37px;
      height: 37px;
      line-height: 2;

      background-color: var(--v-50);
      z-index: var(--z-index-one);

      &.current {
        border: 2px solid #053ca8;
        background-color: #053ca8;
        color: var(--v-50);
      }

      &.concluded {
        border: 2px solid #27ae60;
        background-color: #27ae60;
        color: var(--v-50);
      }

      &.timeline-five {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          &::after {
            content: '';
            background-color: #9b9b9b;
            position: absolute;
            height: 2px;
            margin-top: 13px;
          }

          &:nth-child(1)::after {
            width: 63px;
            margin-left: 15px;
          }

          &:nth-child(2)::after {
            width: 62px;
            margin-left: 14px;
          }

          &:nth-child(3)::after {
            width: 63px;
            margin-left: 13px;
          }

          &:nth-child(4)::after {
            width: var(--space-jumbo);
            margin-left: 13px;
          }

          &.concluded {
            &::after {
              background-color: #27ae60;
            }
          }
        }
      }

      &.timeline-four {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          &::after {
            content: '';
            background-color: #9b9b9b;
            position: absolute;
            height: 2px;
            margin-top: 13px;
          }

          &:nth-child(1)::after {
            width: 96px;
            margin-left: 15px;
          }

          &:nth-child(2)::after {
            width: 95px;
            margin-left: 14px;
          }

          &:nth-child(3)::after {
            width: 96px;
            margin-left: 13px;
          }

          &.concluded {
            &::after {
              background-color: #27ae60;
            }
          }
        }
      }
    }
  }
}
</style>