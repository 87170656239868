<template>
  <div class="column content-box" v-if="dashboardAppFiltered">
    <woot-tabs
      v-if="dashboardAppFiltered.length"
      :index="activeIndex"
      class="dashboard-app--tabs"
      @change="onDashboardAppTabChange"
    >
      <woot-tabs-item
        v-for="tab in dashboardAppTabs"
        :key="tab.key"
        :name="tab.name"
        :show-badge="false"
      />
    </woot-tabs>

    <empty-state v-else :title="$t('DASHBOARD_APPS.NO_DATA')" />

    <dashboard-app-frame
      v-for="(dashboardApp, index) in dashboardAppFiltered"
      v-show="activeIndex === index"
      :key="dashboardApp.id"
      :is-visible="activeIndex === index"
      :config="dashboardAppFiltered[index].content"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmptyState from 'dashboard/components/widgets/EmptyState';
import DashboardAppFrame from 'dashboard/components/widgets/DashboardApp/Frame';

export default {
  components: {
    EmptyState,
    DashboardAppFrame,
  },
  data() {
    return { activeIndex: 0 };
  },
  computed: {
    ...mapGetters({
      dashboardApps: 'dashboardApps/getRecords',
    }),
    dashboardAppTabs() {
      return [
        ...this.dashboardAppFiltered.map(dashboardApp => ({
          key: `dashboard-${dashboardApp.id}`,
          name: dashboardApp.title,
        })),
      ];
    },
    dashboardAppFiltered() {
      if (this.$route.name === 'menu_dashboard_apps') {
        return this.dashboardApps.filter(item => item.content[0]?.show_menu);
      }

      if (this.$route.name === 'reports_dashboard_apps') {
        return this.dashboardApps.filter(item => item.content[0]?.show_report);
      }
    },
  },
  mounted() {
    this.$store.dispatch('dashboardApps/get');
  },
  methods: {
    onDashboardAppTabChange(index) {
      this.activeIndex = index;
    },
  },
};
</script>
