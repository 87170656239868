<template>
  <div v-if="template">
    <span
      v-if="type === 'language'"
      v-tooltip="template.status"
      :class="getClassesBadgeFull"
    >
      {{ template.language }}
    </span>

    <span
      v-else-if="type === 'category'"
      class="badge-template outline-primary"
    >
      {{ getCategory }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'language',
    },
    template: {
      type: Object,
      default: {},
    },
  },
  computed: {
    getCategory() {
      switch (this.template.category.toUpperCase()) {
        case 'UTILITY':
          return this.$t('WHATSAPP_TEMPLATES.CATEGORY.UTILITY');
        case 'MARKETING':
          return this.$t('WHATSAPP_TEMPLATES.CATEGORY.MARKETING');
      }
    },
    getClassesBadgeFull() {
      const { status } = this.template;

      let classes = 'badge-template ';

      if (status.toLowerCase() === 'approved') {
        classes += 'full-success ';
      }

      if (['submitted', 'pending'].includes(status.toLowerCase())) {
        classes += 'full-warning ';
      }

      if (status.toLowerCase() === 'rejected') {
        classes += 'full-danger ';
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-template {
  padding: 8px 15px;
  border-radius: var(--border-radius-full);
  color: var(--white);
  font-weight: var(--font-weight-black);
  font-size: var(--font-size-micro);

  &.full-success {
    background-color: var(--g-900);
  }

  &.full-warning {
    background-color: var(--y-500);
  }

  &.full-danger {
    background-color: var(--r-800);
  }

  &.outline-primary {
    border: 1px solid black;
    color: black;
  }
}
</style>