<template>
  <div class="settings--content">
    <alert
      :show="showSyncMessage"
      :content="$t('WHATSAPP_TEMPLATES.ADD.SYNC.ALERT')"
      type="success"
    />

    <div class="table-actions-wrap">
      <table-search
        :search-query="searchQuery"
        :on-input-search="onInputSearch"
        @on-search-submit="getWhatsappTemplates"
      />

      <div class="right-aligned-wrap">
        <woot-button
          color-scheme="secondary"
          icon="arrow-sort"
          @click="syncronize"
          :disabled="uiFlags.isSyncing"
        >
          {{ $t('WHATSAPP_TEMPLATES.SYNC') }}
        </woot-button>

        <woot-button
          color-scheme="success"
          icon="whatsapp"
          @click="openAddPopup"
        >
          {{ $t('WHATSAPP_TEMPLATES.HEADER') }}
        </woot-button>
      </div>
    </div>

    <div class="medium-12">
      <table-template
        :templates="templates"
        :show-search-empty-state="showEmptySearchResult"
        :is-loading="uiFlags.isFetching"
        @on-sort-change="onSortChange"
        @on-delete-click="openDeletePopup"
      />

      <table-footer
        :current-page="Number(meta.current_page)"
        :total-count="meta.count"
        :page-size="5"
        @page-change="onPageChange"
      />
    </div>

    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup" size="low">
      <add-whatsapp-template
        :channel="getChannelId"
        @on-created="onCreateTemplate"
        @on-close="hideAddPopup"
      />
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.TITLE')"
      :message="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.YES')"
      :reject-text="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.NO')"
      :is-loading="uiFlags.isDeleting"
      :can-show-label="true"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';

import Alert from 'shared/components/Alert';
import TableSearch from './Search';
import TableTemplate from './list/Table';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import AddWhatsappTemplate from './create/AddWhatsappTemplate';

export default {
  components: {
    Alert,
    TableSearch,
    TableTemplate,
    TableFooter,
    AddWhatsappTemplate,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      page: 1,
      searchQuery: '',
      oldSearchQuery: '',
      sortConfig: 'id',
      showAddPopup: false,
      showSyncMessage: false,
      selectedTemplate: {},
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      meta: 'whatsappTemplates/getMeta',
      templates: 'whatsappTemplates/getTemplates',
      uiFlags: 'whatsappTemplates/getUIFlags',
    }),
    getChannelId() {
      return this.inbox.channel_id;
    },
    showEmptySearchResult() {
      return !!this.searchQuery && this.templates.length === 0;
    },
  },
  methods: {
    ...mapActions('whatsappTemplates', ['get', 'search', 'sync', 'delete']),
    async getWhatsappTemplates() {
      try {
        if (!this.getChannelId) return;

        const params = {
          page: this.page,
          channel: this.getChannelId,
          sortAttr: this.sortConfig,
        };

        if (this.searchQuery) {
          if (this.oldSearchQuery && this.searchQuery !== this.oldSearchQuery) {
            this.page = 1;
            params.page = this.page;
          }

          this.oldSearchQuery = this.searchQuery;

          params.search = encodeURIComponent(this.searchQuery.toLowerCase());

          await this.search(params);
        } else {
          await this.get(params);
        }
      } catch (error) {
        this.showAlert(error.message);
      }
    },
    async deleteTemplate(name) {
      try {
        const params = { channel: this.getChannelId, template_name: name };

        await this.delete(params);

        this.showAlert(
          this.$t('INBOX_MGMT.TEMPLATES.DELETE.API.SUCCESS_MESSAGE')
        );

        this.clearData();
        this.getWhatsappTemplates();
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.TEMPLATES.DELETE.API.ERROR_MESSAGE')
        );
      } finally {
        this.closeDeletePopup();
      }
    },
    async syncronize() {
      try {
        const params = { channel: this.getChannelId };
        this.showSyncMessage = false;

        await this.sync(params);

        this.showAlert(this.$t('WHATSAPP_TEMPLATES.ADD.SYNC.SUCCESS_MESSAGE'));
        this.showSyncMessage = true;
      } catch (error) {
        this.showAlert(this.$t('WHATSAPP_TEMPLATES.ADD.SYNC.ERROR_MESSAGE'));
      }
    },
    onCreateTemplate() {
      this.clearData();
      this.getWhatsappTemplates();
    },
    onPageChange(page) {
      this.page = Number(page);

      this.getWhatsappTemplates();
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllTemplates = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;

      if (refetchAllTemplates) {
        this.getWhatsappTemplates();
      }
    },
    onSortChange(params) {
      const param = Object.entries(params).find(pair => Boolean(pair[1])) || [];

      if (!param.length) return;

      this.sortConfig = param[0];
      this.page = 1;
      this.getWhatsappTemplates();
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedTemplate = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      const { name } = this.selectedTemplate;

      this.deleteTemplate(name);
    },
    clearData() {
      this.page = 1;
      this.searchQuery = '';
      this.oldSearchQuery = '';
      this.sortConfig = 'id';
    },
  },
  mounted() {
    this.getWhatsappTemplates();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-container {
    .columns.wait-message {
      p {
        margin-top: var(--space-large);
        margin-bottom: 0 !important;
      }

      ~ .modal-footer {
        button {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}

.table-actions-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>