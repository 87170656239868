<template>
  <superset class="column" supersetId="3cf77cea-4f47-4214-88ad-4231866da955" />
</template>

<script>
import Superset from './Superset.vue';
export default {
  components: {
    Superset,
  },
};
</script>