import ApiClient from './ApiClient';

export const buildParams = (channel, page, sortAttr = 'id', search = '') => {
  let params = `channel_whatsapp=${channel}&page=${page}&sort=${sortAttr}`;

  if (search) {
    params = `${params}&q=${search}`;
  }

  return params;
};

class WhatsappTemplateAPI extends ApiClient {
  constructor() {
    super('templates', { accountScoped: true });
  }

  get(channel, page, sortAttr = '') {
    let requestURL = `${this.url}?${buildParams(
      channel,
      page,
      sortAttr
    )}`;

    return axios.get(requestURL);
  }

  search(channel, page, sortAttr = '', search = '') {
    let requestURL = `${this.url}/search?${buildParams(
      channel,
      page,
      sortAttr,
      search
    )}`;

    return axios.get(requestURL);
  }

  create(data, channel) {
    let requestURL = `${this.url}?channel_whatsapp=${channel}`;

    return axios.post(requestURL, data);
  }

  delete(template_name, channel) {
    let requestURL = `${this.url}/${template_name}?channel_whatsapp=${channel}`;

    return axios.delete(requestURL);
  }

  sync(channel) {
    let requestURL = `${this.url}/sync?channel_whatsapp=${channel}`;

    return axios.post(requestURL);
  }
}

export default new WhatsappTemplateAPI();
