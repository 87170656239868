<template>
  <div class="content-page row">
    <div class="left-wrap" :class="wrapClass">
      <campaign-header
        :search-query="searchQuery"
        :on-search-submit="onSearchSubmit"
        :on-input-search="onInputSearch"
        header-title="CAMPAIGN.WHATSAPP.HEADER"
      />

      <campaign-table
        :campaigns="campaigns"
        :labels="labelList"
        :show-search-empty-state="showEmptySearchResult"
        :is-loading="uiFlags.isFetching"
        :on-click-campaign="openCampaignInfoPanel"
        @on-sort-change="onSortChange"
      />

      <table-footer
        :current-page="Number(meta.current_page)"
        :total-count="meta.count"
        :page-size="15"
        @page-change="onPageChange"
      />
    </div>

    <campaign-info-panel
      v-if="showCampaignViewPanel"
      :campaign="selectedCampaign"
      @toggle-panel="closeCampaignInfoPanel"
      @load-campaign="onPageChange(1)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { CAMPAIGNS_EVENTS } from '../../../../../helper/AnalyticsHelper/events';

import CampaignHeader from './CampaignHeader';
import CampaignInfoPanel from './CampaignInfoPanel';
import CampaignTable from './CampaignTable';
import TableFooter from 'dashboard/components/widgets/TableFooter';

import campaignMixin from 'shared/mixins/campaignMixin';
import alertMixin from 'shared/mixins/alertMixin';

const DEFAULT_PAGE = 1;

export default {
  components: {
    CampaignHeader,
    CampaignTable,
    CampaignInfoPanel,
    TableFooter,
  },
  mixins: [alertMixin, campaignMixin],
  data() {
    return {
      selectedCampaignId: '',
      searchQuery: '',
      sortConfig: { created_at: 'desc' },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      meta: 'campaigns/getMeta',
      labelList: 'labels/getLabels',
    }),
    campaigns() {
      return this.$store.getters['campaigns/getCampaigns'](this.campaignType);
    },
    showEmptySearchResult() {
      const hasEmptyResults = !!this.searchQuery && this.campaigns.length === 0;
      return hasEmptyResults;
    },
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);

      return !Number.isNaN(selectedPageNumber) &&
        selectedPageNumber >= DEFAULT_PAGE
        ? selectedPageNumber
        : DEFAULT_PAGE;
    },
    selectedCampaign() {
      if (this.selectedCampaignId) {
        const campaign = this.campaigns.find(item => {
          return this.selectedCampaignId === item.id;
        });

        return campaign;
      }

      return undefined;
    },
    showCampaignViewPanel() {
      return this.selectedCampaignId !== '' && this.selectedCampaign?.id;
    },
    wrapClass() {
      return this.showCampaignViewPanel ? 'medium-8' : 'medium-12';
    },
  },
  mounted() {
    this.fetchCampaigns(this.pageParameter);
  },
  methods: {
    updatePageParam(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
    },
    async fetchCampaigns(page) {
      this.updatePageParam(page);
      let value = '';

      if (this.searchQuery.charAt(0) === '+') {
        value = this.searchQuery.substring(1);
      } else {
        value = this.searchQuery;
      }

      const requestParams = {
        page,
        sortAttr: this.getSortAttribute(),
      };

      if (!value) {
        this.$store.dispatch('campaigns/getCampaignWhatsapp', requestParams);
      } else {
        this.$store.dispatch('campaigns/search', {
          search: encodeURIComponent(value),
          ...requestParams,
        });
      }
    },
    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];
        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }
        return acc;
      }, '');

      if (!sortAttr) {
        this.sortConfig = { created_at: 'desc' };
        sortAttr = '-created_at';
      }
      return sortAttr;
    },
    onPageChange(page) {
      this.selectedCampaignId = '';
      this.fetchCampaigns(page);
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllCampaigns = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllCampaigns) {
        this.fetchCampaigns(DEFAULT_PAGE);
      }
    },
    onSearchSubmit() {
      this.selectedCampaignId = '';

      if (this.searchQuery) {
        this.fetchCampaigns(DEFAULT_PAGE);
      }
    },
    onSortChange(params) {
      this.sortConfig = params;
      this.fetchCampaigns(this.meta.current_page);

      const sortBy =
        Object.entries(params).find(pair => Boolean(pair[1])) || [];

      this.$track(CAMPAIGNS_EVENTS.APPLY_SORT, {
        appliedOn: sortBy[0],
        order: sortBy[1],
      });
    },
    openCampaignInfoPanel(id) {
      this.selectedCampaignId = id;
    },
    closeCampaignInfoPanel() {
      this.selectedCampaignId = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.content-page {
  width: 100%;
}

.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
