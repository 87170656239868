<template>
  <div class="medium-12 columns">
    <div v-if="getTemplateHeader">
      <label>{{ $t('WHATSAPP_TEMPLATES.PARSER.HEADER') }}</label>

      <textarea
        v-if="formatTypeTemplateHeader === 'TEXT' && processedStringHeader"
        v-model="processedStringHeader"
        rows="4"
        readonly
        class="template-input"
      />

      <file-upload
        v-else-if="formatTypeTemplateHeader !== 'TEXT' && !hasAttachments"
        ref="images"
        name="_file"
        :accept="acceptedFormats"
        :size="acceptedSize"
        :data="{
          direct_upload_url: '/rails/active_storage/direct_uploads',
          direct_upload: true,
        }"
        @input-file="onFileUpload"
      >
        <woot-button
          class-names="button--upload"
          icon="file-upload"
          color-scheme="secondary"
          variant="smooth"
          size="small"
        >
          <span v-if="formatTypeTemplateHeader === 'IMAGE'">
            {{ $t('WHATSAPP_TEMPLATES.PARSER.UPLOAD_IMAGE') }}
          </span>

          <span v-if="formatTypeTemplateHeader === 'DOCUMENT'">
            {{ $t('WHATSAPP_TEMPLATES.PARSER.UPLOAD_DOCUMENT') }}
          </span>

          <span v-if="formatTypeTemplateHeader === 'VIDEO'">
            {{ $t('WHATSAPP_TEMPLATES.PARSER.UPLOAD_VIDEO') }}
          </span>
        </woot-button>

        <small>
          Formatos permitidos: <span>{{ acceptedFormats }}</span>
        </small>
      </file-upload>
    </div>

    <div v-if="hasAttachments" class="attachment-preview-box">
      <attachment-preview
        :attachments="attachedFiles"
        :remove-attachment="removeAttachment"
      />
    </div>

    <div>
      <label>{{ $t('WHATSAPP_TEMPLATES.PARSER.BODY') }}</label>
      <textarea
        v-model="processedStringBody"
        rows="4"
        readonly
        class="template-input"
      />
    </div>

    <div v-if="processedStringFooter">
      <label>{{ $t('WHATSAPP_TEMPLATES.PARSER.FOOTER') }}</label>
      <textarea
        v-model="processedStringFooter"
        rows="4"
        readonly
        class="template-input"
      />
    </div>

    <div v-if="variablesHeader" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_HEADER') }}
      </p>
      <div
        v-for="(variable, key) in processedParamsHeader"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParamsHeader[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
        />
      </div>
    </div>

    <div v-if="variablesBody" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_BODY') }}
      </p>
      <div
        v-for="(variable, key) in processedParamsBody"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParamsBody[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
          @blur="onBlur(processedParamsBody)"
        />
      </div>
      <p v-if="$v.$dirty && $v.$invalid" class="error">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
      </p>
    </div>

    <footer :class="{ 'custom-footer': type === 'campaign' }">
      <woot-button
        v-if="type === 'default'"
        variant="smooth"
        @click="$emit('resetTemplate')"
      >
        {{ $t('WHATSAPP_TEMPLATES.PARSER.GO_BACK_LABEL') }}
      </woot-button>

      <woot-button @click="sendMessage" v-if="type !== 'cadence'">
        {{
          $t(
            type === 'campaign'
              ? 'CAMPAIGN.NEXT.BUTTON_TEXT'
              : 'WHATSAPP_TEMPLATES.PARSER.SEND_MESSAGE_LABEL'
          )
        }}
      </woot-button>
    </footer>
  </div>
</template>

<script>
const allKeysRequired = value => {
  const keys = Object.keys(value);
  return keys.every(key => value[key]);
};

import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import FileUpload from 'vue-upload-component';

import { mapGetters } from 'vuex';
import { requiredIf } from 'vuelidate/lib/validators';
import templateWppMixin from 'shared/mixins/templateWppMixin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: { AttachmentPreview, FileUpload },
  mixins: [alertMixin, templateWppMixin],
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "default"
    }
  },
  validations: {
    processedParamsHeader: {
      requiredIfKeysPresent: requiredIf('variables'),
      allKeysRequired,
    },
    processedParamsBody: {
      requiredIfKeysPresent: requiredIf('variables'),
      allKeysRequired,
    },
  },
  data() {
    return {
      processedParamsHeader: {},
      processedParamsBody: {}
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    variablesHeader() {
      if (this.templateStringHeader) {
        return this.templateStringHeader.match(/{{([^}]+)}}/g);
      }

      return "";
    },
    variablesBody() {
      return this.templateStringBody.match(/{{([^}]+)}}/g);
    },
    getTemplateHeader() {
      return this.template.components.find(({ type }) => type === 'HEADER');
    },
    templateStringHeader() {
      return this.getTemplateHeader?.text ?? "";
    },
    templateStringBody() {
      return this.template.components.find(({ type }) => type === 'BODY').text;
    },
    templateStringFooter() {
      return this.template.components.find(({ type }) => type === 'FOOTER')
        ?.text;
    },
    formatTypeTemplateHeader() {
      if (this.getTemplateHeader) {
        // TEXT || DOCUMENT || IMAGE || VIDEO
        return this.getTemplateHeader.format;
      }
    },
    processedStringHeader() {
      if (this.templateStringHeader) {
        return this.templateStringHeader.replace(
          /{{([^}]+)}}/g,
          (match, variable) => {
            const variableKey = this.processVariable(variable);
            return this.processedParamsHeader[variableKey] || `{{${variable}}}`;
          }
        );
      }

      return "";
    },
    processedStringBody() {
      return this.templateStringBody.replace(
        /{{([^}]+)}}/g,
        (match, variable) => {
          const variableKey = this.processVariable(variable);
          return this.processedParamsBody[variableKey] || `{{${variable}}}`;
        }
      );
    },
    processedStringFooter() {
      return this.templateStringFooter ?? "";
    },
  },
  mounted() {
    this.getVariablesTemplate();
  },
  methods: {
    sendMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const payload = {
        message: this.getMessage(),
        templateParams: {
          name: this.template.name,
          category: this.template.category,
          language: this.template.language,
          namespace: this.template.namespace,
          processed_params: this.getProcessedParams(),
        },
      };

      if (this.hasAnAttach()) {
        payload.templateParams.file_processed_parameters = this.getFileProcessedParameters();
      }

      this.$emit('sendMessage', payload);
      this.sendAttachmentPrivateMessage();
    },
    sendAttachmentPrivateMessage() {
      if (!this.hasAnAttach()) return;

      let file = this.attachedFiles[0];
      file.isPrivate = true;
      const payload = {
        message: "",
        private: true,
        files: [ file.blobSignedId ],
        attachedFile: this.resource
      };

      this.$emit('sendMessage', payload);
    },
    getMessage() {
      let message = "";

      if (this.processedStringHeader) {
        message = `*${this.processedStringHeader}* \n\n`;
      }

      message = `${message} ${this.processedStringBody}`;

      if (this.processedStringFooter) {
        message = `${message} \n\n ${this.processedStringFooter}`;
      }

      return message;
    },
    getProcessedParams() {
      const paramsHeader = Object.values(this.processedParamsHeader);
      const paramsBody = Object.values(this.processedParamsBody);
      return {
        header: paramsHeader.reduce((obj, elemento, indice) => {
            obj[indice + 1] = elemento;
            return obj;
        }, {}),
        body: paramsBody.reduce((obj, elemento, indice) => {
            obj[indice + 1] = elemento;
            return obj;
        }, {})
      };
    },
    processVariable(str) {
      return str.replace(/{{|}}/g, '');
    },
    getVariablesTemplate() {
      if (this.templateStringHeader) {
        //Header
        this.generateVariables(this.templateStringHeader, "processedParamsHeader");
      }

      //Body
      this.generateVariables(this.templateStringBody, "processedParamsBody");
    },
    generateVariables(templateString, saveTo) {
      const matchedVariables = templateString.match(/{{([^}]+)}}/g);
      if (!matchedVariables) return;

      const variables = matchedVariables.map(i => this.processVariable(i));
      this[saveTo] = variables.reduce((acc, variable) => {
        acc[variable] = '';
        return acc;
      }, {});
    },
    onBlur(params) {
      this.$emit('onBlur', params);
    },
  },
};
</script>

<style scoped lang="scss">
.template__variables-container {
  padding: var(--space-one);
}

.variables-label {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-one);
}

.template__variable-item {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-one);

  .label {
    font-size: var(--font-size-mini);
  }

  .variable-input {
    flex: 1;
    font-size: var(--font-size-small);
    margin-left: var(--space-one);
  }

  .variable-label {
    background-color: var(--s-75);
    border-radius: var(--border-radius-normal);
    display: inline-block;
    font-size: var(--font-size-mini);
    padding: var(--space-one) var(--space-medium);
  }
}

footer {
  display: flex;
  justify-content: flex-end;

  &.custom-footer {
    position: absolute;
    right: var(--space-large);
    margin-top: var(--space-small);
  }

  button {
    margin-left: var(--space-one);
  }
}
.error {
  background-color: var(--r-100);
  border-radius: var(--border-radius-normal);
  color: var(--r-800);
  padding: var(--space-one);
  text-align: center;
}
.template-input {
  background-color: var(--s-25);
}

.preview-item__wrap,
.file-uploads-html5 {
  margin-top: var(--space-micro);
  margin-bottom: var(--space-one);

  small {
    margin-left: var(--space-normal);
    font-size: var(--font-size-micro);

    span {
      text-transform: uppercase;
    }
  }
}
</style>
