import ApiClient from './ApiClient';

class UserAccountsAccessAPI extends ApiClient {
    constructor() {
        super('user_account_access', { accountScoped: true });
    }
    validityAccess() {
        return axios.get(`${this.url}/check`)
    }
    dennyCauses() {
        return axios.get(`${this.url}/denny_causes`)
    }
    policiesDateExpire() {
        return axios.get(`${this.url}/policies_date_expire`)
    }
}

export default new UserAccountsAccessAPI();
