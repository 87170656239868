import Cookies from 'js-cookie';
import { setCookieWithDomain } from '../../sdk/cookieHelpers';
import { differenceInMinutes, addMinutes } from 'date-fns';

export const alertWhenExpireAccessAccount = (storeGetter) => {
    const { 'userAccountsAccess/allowAccessAccount': allowAccessAccount, 'userAccountsAccess/getPoliciesAccessAccountDateExpires': policiesAccessAccountDateExpires } = storeGetter;
    if (allowAccessAccount && Object.keys(policiesAccessAccountDateExpires).length > 0) {
        let diffMinutes;
        let keyCookie;
        for (const property in policiesAccessAccountDateExpires) {
            if (policiesAccessAccountDateExpires[property]) {
                diffMinutes = differenceInMinutes(new Date(policiesAccessAccountDateExpires[property]), new Date());
                keyCookie = `cw_alert_policiesAccessAccount_${property.toLocaleLowerCase()}`;
                if (diffMinutes < 30 && (!Cookies.get(keyCookie) || new Date() > new Date(Cookies.get(keyCookie)))) {
                    setCookieWithDomain(keyCookie, addMinutes(new Date(), 10));
                    if (diffMinutes < 1) {
                        diffMinutes = 1;
                    }
                    switch (property) {
                        case 'PASSWORD_POLICY_EXPIRE':
                            window.bus.$emit('newToastMessage', window.WOOT.$i18n.tc('SECURITY_POLICIES.ALERT.PASSWORD_POLICY_EXPIRE', { minutes: diffMinutes }));
                            break;
                        case 'POLICY_ACCESS_TIME_CONTROL':
                            window.bus.$emit('newToastMessage', window.WOOT.$i18n.tc('SECURITY_POLICIES.ALERT.ACCESS_TIME_POLICY', { minutes: diffMinutes }));
                            break;
                    }
                }
            }
        }

    }
}