<template>
  <div class="file-permission-page settings--content">
    <form @submit.prevent="update">
      <div class="row flex-dir-column margin-bottom-2">
        <label class="medium-4 columns">
          Ative a verificação das extensões de arquivos recebidos neste canal
          <select v-model="enableBlockFileIncoming" @change="clean('incoming')">
            <option
              v-for="option in getEnableOptions"
              :key="option.key"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </label>
        <div class="columns">
          <p>
            Se um cliente enviar um arquivo com uma extensão proibida (por
            exemplo, um arquivo .exe), o Converx impedirá que o agente receba e
            faça o download da mensagem.
          </p>
          <p>
            O agente será notificado sobre a tentativa de recebimento de um
            arquivo com uma extensão proibida, mas o cliente não será informado
          </p>
        </div>
      </div>
      <div
        v-if="enableBlockFileIncoming"
        class="row flex-dir-column margin-bottom-2"
      >
        <label class="medium-4 columns">
          Extensões de arquivos bloqueados
          <multiselect
            ref="multiselect_incoming"
            v-model="fileTypeIncoming"
            selectedLabel="Selecionado"
            tag-placeholder="Adicionar esse tipo"
            placeholder="Pesquisar ou Adicionar um tipo de arquivo"
            label="ext"
            track-by="key"
            deselect-label=""
            :max-height="160"
            :allow-empty="false"
            :options="getFileTypeIncomingOptions"
            :option-height="104"
            :multiple="true"
            :taggable="false"
            tag-position="bottom"
          >
            <span slot="noOptions">Lista vazia</span>
          </multiselect>
        </label>
        <div class="columns">
          <p>
            Comece digitando o tipo de arquivo, por exemplo <span>.pdf</span> e
            pressione <span>ENTER</span> no teclado para confirmar.
          </p>
        </div>
      </div>

      <div class="divider">&nbsp;</div>

      <div class="row flex-dir-column margin-bottom-2">
        <label class="medium-4 columns">
          Ative a verificação das extensões de arquivos enviados neste canal
          <select v-model="enableBlockFileOutgoing" @change="clean('outgoing')">
            <option
              v-for="option in getEnableOptions"
              :key="option.key"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </label>
        <div class="columns">
          <p>
            Se um agente tentar enviar um arquivo com uma extensão proibida (por
            exemplo, um arquivo .exe), o Converx impedirá o envio da mensagem
            para o cliente.
          </p>
          <p>
            O agente será notificado sobre a tentativa de envio de um arquivo
            com uma extensão proibida, mas o cliente não será informado
          </p>
        </div>
      </div>
      <div
        v-if="enableBlockFileOutgoing"
        class="row flex-dir-column margin-bottom-2"
      >
        <label class="medium-4 columns">
          Extensões de arquivos bloqueados
          <multiselect
            ref="multiselect_outgoing"
            v-model="fileTypeOutgoing"
            selectedLabel="Selecionado"
            tag-placeholder="Adicionar esse tipo"
            placeholder="Pesquisar ou Adicionar um tipo de arquivo"
            label="ext"
            track-by="key"
            deselect-label=""
            :max-height="160"
            :allow-empty="false"
            :options="getFileTypeOutgoingOptions"
            :option-height="104"
            :multiple="true"
            :taggable="false"
            tag-position="bottom"
          >
            <span slot="noOptions">Lista vazia</span>
          </multiselect>
        </label>
        <div class="columns">
          <p>
            Comece digitando o tipo de arquivo, por exemplo <span>.pdf</span> e
            pressione <span>ENTER</span> no teclado para confirmar.
          </p>
        </div>
      </div>

      <div v-if="showEnableAntivirus">
        <div class="divider">&nbsp;</div>

        <div class="row flex-dir-column margin-bottom-2">
          <label class="medium-4 columns">
            Ativar a verificação para arquivo malicioso (Antivírus)
            <select v-model="enableAntivirus">
              <option
                v-for="option in getEnableOptions"
                :key="option.key"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </label>
          <div class="columns">
            <p>
              Se um cliente tentar enviar um arquivo com virus ou malware
              prejudicial a sua maquina o Converx irá bloqueia-lo
            </p>
          </div>
        </div>
      </div>

      <woot-submit-button
        class="submit-button"
        button-text="Atualizar configurações"
        :loading="uiFlags.isUpdating"
        :disabled="uiFlags.isUpdating"
      />
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import mimeTypes from '../helpers/mimeTypes.json';

export default {
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      enableAntivirus: false,
      enableBlockFileIncoming: false,
      enableBlockFileOutgoing: false,
      fileTypeIncoming: [],
      fileTypeOutgoing: [],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      currentUserRole: 'getCurrentRole',
    }),
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    getEnableOptions() {
      return [
        { key: 1, text: 'Ativado', value: true },
        { key: 0, text: 'Desativado', value: false },
      ];
    },
    getFileTypeIncomingOptions() {
      return Object.assign([], mimeTypes);
    },
    getFileTypeOutgoingOptions() {
      return Object.assign([], mimeTypes);
    },
    showEnableAntivirus() {
      return this.currentUserRole == 'administrator';
    },
  },
  mounted() {
    if (this.inbox.file_manager) {
      this.enableAntivirus = this.inbox.file_manager.antivirus_enable ?? false;
      this.enableBlockFileIncoming =
        this.inbox.file_manager.enable_block_file_incoming;
      this.enableBlockFileOutgoing =
        this.inbox.file_manager.enable_block_file_outgoing;
      this.fileTypeIncoming = this.inbox.file_manager.file_type_incoming;
      this.fileTypeOutgoing = this.inbox.file_manager.file_type_outgoing;
    }
  },
  methods: {
    clean($type) {
      if ($type == 'incoming') {
        this.fileTypeIncoming = [];
      }

      if ($type == 'outgoing') {
        this.fileTypeOutgoing = [];
      }
    },
    async update($event) {
      try {
        const payload = {
          id: this.currentInboxId,
          formData: false,
          file_manager: {
            antivirus_enable: this.enableAntivirus,
            enable_block_file_incoming: this.enableBlockFileIncoming,
            enable_block_file_outgoing: this.enableBlockFileOutgoing,
            file_type_incoming: this.fileTypeIncoming,
            file_type_outgoing: this.fileTypeOutgoing,
          },
        };

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-permission-page {
  .divider {
    background: #b8b9bb;
    height: 1px;
    margin: 1.5rem 0;
  }
}
</style>