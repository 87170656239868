import Vue from 'vue';
import types from '../mutation-types';
import UserAccountsAccessApi from '../../api/userAccountsAccess';

export const state = {
  accessAccountDateExpires: {},
};

export const getters = {
  allowAccessAccount(_state) {
    if (Object.keys(_state.accessAccountDateExpires).length == 0) {
      return true;
    }
    const policiesKey = Object.keys(_state.accessAccountDateExpires);
    let index = 0;
    let allowAccessAccount = true;
    while (allowAccessAccount && index < policiesKey.length) {
      allowAccessAccount = !_state.accessAccountDateExpires[policiesKey[index]] || new Date() < new Date(_state.accessAccountDateExpires[policiesKey[index]])
      index++;
    }
    return allowAccessAccount;
  },
  getPoliciesAccessAccountDateExpires(_state) {
    return _state.accessAccountDateExpires;
  },
};

export const actions = {
  async setPoliciesAccessAccountDateExpires({ commit }) {
    const response = await UserAccountsAccessApi.policiesDateExpire();
    commit(types.SET_POLICIES_ACCESS_ACCOUNT_DATE_EXPIRES, response.data);
  }
};

export const mutations = {
  [types.SET_POLICIES_ACCESS_ACCOUNT_DATE_EXPIRES](_state, accessAccountDateExpires) {
    Vue.set(_state, 'accessAccountDateExpires', accessAccountDateExpires);
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};