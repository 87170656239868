import SettingsContent from '../Wrapper';
const Bot = () => import('./Index.vue');
const CsmlEditBot = () => import('./csml/Edit.vue');
const CsmlNewBot = () => import('./csml/New.vue');
const IframeBotmaker = () => import('./botmaker/IframeBotmaker.vue');
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/agent-bots'),
      roles: ['administrator', 'supervisor'],
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'agent_bots';
        return {
          headerTitle: 'AGENT_BOTS.HEADER',
          icon: 'bot',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'agent_bots',
          component: Bot,
          roles: ['administrator', 'supervisor'],
        },
        {
          path: 'csml/new',
          name: 'agent_bots_csml_new',
          component: CsmlNewBot,
          roles: ['administrator', 'supervisor'],
        },
        {
          path: 'csml/:botId',
          name: 'agent_bots_csml_edit',
          component: CsmlEditBot,
          roles: ['administrator', 'supervisor'],
        },
        {
          path: 'botmaker/new',
          name: 'agent_bots_botmaker_new',
          component: IframeBotmaker,
          roles: ['administrator', 'supervisor'],
        },
        {
          path: 'botmaker/:botId',
          name: 'agent_bots_botmaker_edit',
          component: IframeBotmaker,
          roles: ['administrator', 'supervisor'],
        },
      ],
    },
  ],
};
