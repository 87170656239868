<template>
  <div
    class="conversations-list-wrap"
    :class="{
      hide: !showConversationList,
      'list--full-width': isOnExpandedLayout,
    }"
  >
    <slot />
    <div class="chat-list__top">
      <h1 class="page-title text-truncate" :title="pageTitle">
        {{ pageTitle }}
      </h1>
    </div>

    <div class="boards-conversations">
      <chat-list-status
        v-for="(board, index) in boards"
        :key="index"
        :conversation-inbox="conversationInbox"
        :team-id="teamId"
        :label="label"
        :conversation-type="conversationType"
        :folders-id="foldersId"
        :showConversation-list="showConversationList"
        :is-on-expanded-layout="isOnExpandedLayout"
        :status="board.status"
        :title="board.title"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatListStatus from './ChatListStatus';

export default {
  components: {
    ChatListStatus,
  },
  props: {
    conversationInbox: {
      type: [String, Number],
      default: 0,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    conversationType: {
      type: String,
      default: '',
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
    showConversationList: {
      default: true,
      type: Boolean,
    },
    isOnExpandedLayout: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
    }),
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.activeInbox);
    },
    pageTitle() {
      if (this.inbox.name) {
        return this.inbox.name;
      }
      if (this.activeTeam.name) {
        return this.activeTeam.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      if (this.conversationType === 'mention') {
        return this.$t('CHAT_LIST.MENTION_HEADING');
      }
      return this.$t('CHAT_LIST.TAB_HEADING');
    },
    activeTeam() {
      if (this.teamId) {
        return this.$store.getters['teams/getTeam'](this.teamId);
      }
      return {};
    },
    boards() {
      return [
        { title: 'Pendente', status: 'pending' },
        { title: 'Adiadas', status: 'snoozed' },
        { title: 'Abertas', status: 'open' },
        { title: 'Resolvidas', status: 'resolved' },
      ];
    },
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/woot';

.spinner {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
}

.boards-conversations {
  display: flex;
  padding-left: 10px;
  height: 100%;
  position: relative;
  padding-right: 10px;
  padding-bottom: 0;
  overflow-x: auto;
  overflow-y: hidden;
}

.conversations-list {
  // Prevent the list from scrolling if the submenu is opened
  &.is-context-menu-open {
    overflow: hidden !important;
  }
}

.conversations-list-wrap {
  flex-shrink: 0;
  width: 34rem;
  overflow: hidden;
  @include breakpoint(large up) {
    width: 36rem;
  }
  @include breakpoint(xlarge up) {
    width: 35rem;
  }
  @include breakpoint(xxlarge up) {
    width: 38rem;
  }
  @include breakpoint(xxxlarge up) {
    flex-basis: 46rem;
  }

  &.hide {
    display: none;
  }

  &.list--full-width {
    width: 100%;
    @include breakpoint(xxxlarge up) {
      flex-basis: 100%;
    }
  }
}
.filter--actions {
  display: flex;
  align-items: center;
}

.btn-filter {
  margin: 0 var(--space-smaller);
}

.filter__applied {
  padding: 0 0 var(--space-slab) 0 !important;
  border-bottom: 1px solid var(--color-border);
}

.delete-custom-view__button {
  margin-right: var(--space-normal);
}
</style>
