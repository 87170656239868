var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.optionsComponents.buttons)?_c('div',{staticClass:"columns"},[_c('label',{staticClass:"custom-label"},[_c('span',[_vm._v("\n      "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.LABEL'))+"\n      "),_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(
          _vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.TOOLTIP')
        ),expression:"\n          $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.TOOLTIP')\n        ",modifiers:{"top-start":true}}],attrs:{"icon":"info","size":"14"}})],1),_vm._v(" "),_c('woot-button',{attrs:{"type":"button","variant":"link","icon":"add"},on:{"click":_vm.addButton}},[_vm._v("\n      "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ADD_BUTTON'))+"\n    ")])],1),_vm._v(" "),_vm._l((_vm.template.buttons),function(button,index){return _c('div',{key:index,staticClass:"card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"medium-11"},[_c('label',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(button.type),expression:"button.type"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(button, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.onChangeType(button)}]}},_vm._l((_vm.typeButton),function(item){return _c('option',{key:item.key,domProps:{"value":item.key}},[_vm._v("\n              "+_vm._s(item.value)+"\n            ")])}),0)]),_vm._v(" "),(button.type === _vm.getTypes.url)?_c('div',{staticClass:"card"},[_c('label',{class:{
              error: _vm.v.template.buttons.$each[index].text.$error,
            }},[_vm._v("\n            "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.SHORT_TEXT'))+"\n\n            "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(button.text),expression:"button.text",modifiers:{"trim":true}}],attrs:{"type":"text","minlength":"1","maxlength":"25","placeholder":_vm.$t('WHATSAPP_TEMPLATES.ADD.INSERT')},domProps:{"value":(button.text)},on:{"blur":[_vm.v.template.buttons.$each[index].text.$touch,function($event){return _vm.$forceUpdate()}],"keyup":function($event){return _vm.onKeyupRemoveEmoji(button)},"input":function($event){if($event.target.composing){ return; }_vm.$set(button, "text", $event.target.value.trim())}}}),_vm._v(" "),(_vm.v.template.buttons.$each[index].text.$error)?_c('span',{staticClass:"message"},[_vm._v("\n              "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR'))+"\n            ")]):_vm._e()]),_vm._v(" "),_c('label',{class:{
              error: _vm.v.template.buttons.$each[index].url.$error,
            }},[_vm._v("\n            "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.URL_LABEL'))+"\n\n            "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(button.url),expression:"button.url",modifiers:{"trim":true}}],attrs:{"type":"text","minlength":"1","maxlength":"2000","placeholder":_vm.$t(
                  'WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.URL_PLACEHOLDER'
                )},domProps:{"value":(button.url)},on:{"blur":[_vm.v.template.buttons.$each[index].url.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(button, "url", $event.target.value.trim())}}}),_vm._v(" "),(_vm.v.template.buttons.$each[index].url.$error && !button.url)?_c('span',{staticClass:"message"},[_vm._v("\n              "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR'))+"\n            ")]):(_vm.v.template.buttons.$each[index].url.$error)?_c('span',{staticClass:"message"},[_vm._v("\n              "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.URL_INVALID'))+"\n            ")]):_vm._e()])]):_vm._e(),_vm._v(" "),(button.type === _vm.getTypes.quickReply)?_c('div',{staticClass:"card"},[_c('label',{class:{
              error: _vm.v.template.buttons.$each[index].text.$error,
            }},[_vm._v("\n            "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.SHORT_TEXT'))+"\n\n            "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(button.text),expression:"button.text",modifiers:{"trim":true}}],attrs:{"type":"text","minlength":"1","maxlength":"25","placeholder":_vm.$t('WHATSAPP_TEMPLATES.ADD.INSERT')},domProps:{"value":(button.text)},on:{"blur":[_vm.v.template.buttons.$each[index].text.$touch,function($event){return _vm.$forceUpdate()}],"keyup":function($event){return _vm.onKeyupRemoveEmoji(button)},"input":function($event){if($event.target.composing){ return; }_vm.$set(button, "text", $event.target.value.trim())}}}),_vm._v(" "),(_vm.v.template.buttons.$each[index].text.$error)?_c('span',{staticClass:"message"},[_vm._v("\n              "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR'))+"\n            ")]):_vm._e()])]):_vm._e(),_vm._v(" "),(button.type === _vm.getTypes.phoneNumber)?_c('div',{staticClass:"card"},[_c('label',{class:{
              error: _vm.v.template.buttons.$each[index].text.$error,
            }},[_vm._v("\n            "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.SHORT_TEXT'))+"\n\n            "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(button.text),expression:"button.text",modifiers:{"trim":true}}],attrs:{"type":"text","minlength":"1","maxlength":"25","placeholder":_vm.$t('WHATSAPP_TEMPLATES.ADD.INSERT')},domProps:{"value":(button.text)},on:{"blur":[_vm.v.template.buttons.$each[index].text.$touch,function($event){return _vm.$forceUpdate()}],"keyup":function($event){return _vm.onKeyupRemoveEmoji(button)},"input":function($event){if($event.target.composing){ return; }_vm.$set(button, "text", $event.target.value.trim())}}}),_vm._v(" "),(_vm.v.template.buttons.$each[index].text.$error)?_c('span',{staticClass:"message"},[_vm._v("\n              "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR'))+"\n            ")]):_vm._e()]),_vm._v(" "),_c('label',{class:{
              error: _vm.v.template.buttons.$each[index].phone_number.$error,
            }},[_vm._v("\n            "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.PHONE_LABEL'))+"\n\n            "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(button.phone_number),expression:"button.phone_number"}],attrs:{"type":"text","minlength":"13","maxlength":"20","placeholder":_vm.$t(
                  'WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.PHONE_PLACEHOLDER'
                )},domProps:{"value":(button.phone_number)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(button, "phone_number", $event.target.value)},function($event){return _vm.onInputPhone(button)}],"blur":_vm.v.template.buttons.$each[index].phone_number.$touch}}),_vm._v(" "),(
                _vm.v.template.buttons.$each[index].phone_number.$error &&
                !button.phone_number
              )?_c('span',{staticClass:"message"},[_vm._v("\n              "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.ERROR'))+"\n            ")]):(_vm.v.template.buttons.$each[index].phone_number.$error)?_c('span',{staticClass:"message"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.PHONE_INVALID'
                ))+"\n            ")]):_vm._e()])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"medium-1"},[_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('LABEL_MGMT.FORM.DELETE')),expression:"$t('LABEL_MGMT.FORM.DELETE')",modifiers:{"top":true}}],attrs:{"type":"button","variant":"smooth","color-scheme":"alert","size":"tiny","icon":"dismiss-circle"},on:{"click":function($event){return _vm.removeButton(index)}}})],1)])])}),_vm._v(" "),(!_vm.template.buttons.length && _vm.v.$invalid)?_c('p',{staticClass:"error-message"},[_vm._v("\n    "+_vm._s(_vm.$t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.BUTTONS.INVALID'))+"\n  ")]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }