<template>
  <div class="columns profile--settings">
    <form v-if="!uiFlags.isFetchingItem" @submit.prevent="updateAccount">
      <div class="small-12 row profile--settings--row">
        <div class="columns small-3">
          <h4 class="block-title">
            {{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE') }}
          </h4>
          <p>{{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.NOTE') }}</p>
        </div>
        <div class="columns small-9 medium-5">
          <label :class="{ error: $v.name.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.NAME.LABEL') }}
            <input
              v-model="name"
              type="text"
              :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')"
              @blur="$v.name.$touch"
            />
            <span v-if="$v.name.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.NAME.ERROR') }}
            </span>
          </label>
          <label :class="{ error: $v.locale.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL') }}
            <select v-model="locale">
              <option
                v-for="lang in languagesSortedByCode"
                :key="lang.iso_639_1_code"
                :value="lang.iso_639_1_code"
              >
                {{ lang.name }}
              </option>
            </select>
            <span v-if="$v.locale.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
            </span>
          </label>
          <label v-if="featureInboundEmailEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.FEATURES.INBOUND_EMAIL_ENABLED') }}
          </label>
          <label v-if="featureCustomReplyDomainEnabled">
            {{
              $t('GENERAL_SETTINGS.FORM.FEATURES.CUSTOM_EMAIL_DOMAIN_ENABLED')
            }}
          </label>
          <label v-if="featureCustomReplyDomainEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.DOMAIN.LABEL') }}
            <input
              v-model="domain"
              type="text"
              :placeholder="$t('GENERAL_SETTINGS.FORM.DOMAIN.PLACEHOLDER')"
            />
          </label>
          <label v-if="featureCustomReplyEmailEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL') }}
            <input
              v-model="supportEmail"
              type="text"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER')
              "
            />
          </label>
          <label
            v-if="showAutoResolutionConfig"
            :class="{ error: $v.autoResolveDuration.$error }"
          >
            {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL') }}
            <input
              v-model="autoResolveDuration"
              type="number"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.PLACEHOLDER')
              "
              @blur="$v.autoResolveDuration.$touch"
            />
            <span v-if="$v.autoResolveDuration.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.ERROR') }}
            </span>
          </label>
          <label :class="{ error: $v.twoFactorAuth.$error }">
            Habilitar segundo fator de autenticação
            <select v-model="twoFactorAuth">
              <option :value="true">Sim</option>
              <option :value="false">Não</option>
            </select>
            <span v-if="$v.twoFactorAuth.$error" class="message"></span>
          </label>
          <label v-if="twoFactorAuth">
            Depois de quantos dias usuário será forçado a fazer logout para
            inserir novamente o token de autenticação?
            <input v-model="twoFactorAuthDays" type="text" />
          </label>

          <label>
            {{ $t('GENERAL_SETTINGS.FORM.TEMPLATE_LIMIT.ENABLE') }}

            <select v-model="enable_template_limit">
              <option :value="true">
                {{ $t('GENERAL_SETTINGS.FORM.TYPES.YES') }}
              </option>

              <option :value="false">
                {{ $t('GENERAL_SETTINGS.FORM.TYPES.NO') }}
              </option>
            </select>
          </label>

          <label
            v-if="enable_template_limit"
            :class="{
              error: $v.monthly_template_limit.$error,
            }"
          >
            {{ $t('GENERAL_SETTINGS.FORM.TEMPLATE_LIMIT.LIMIT') }}

            <input
              min="1"
              type="number"
              v-model="monthly_template_limit"
              @input="$v.monthly_template_limit.$touch"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.TEMPLATE_LIMIT.PLACEHOLDER')
              "
            />

            <span v-if="$v.monthly_template_limit.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.TEMPLATE_LIMIT.ERROR') }}
            </span>
          </label>
        </div>
      </div>

      <div class="profile--settings--row row">
        <div class="columns small-3">
          <h4 class="block-title">
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE') }}
          </p>
        </div>
        <div class="columns small-9 medium-5">
          <woot-code :script="getAccountId" />
        </div>
      </div>
      <div class="profile--settings--row row">
        <div class="columns small-3">
          <h4 class="block-title">
            {{ $t('SECURITY_POLICIES.FORM.SECURITY_SECTION.TITLE') }}
          </h4>
          <p>
            {{ $t('SECURITY_POLICIES.FORM.SECURITY_SECTION.NOTE') }}
          </p>
        </div>
        <div class="columns small-9 large-5">
          <label>
            {{
              $t('SECURITY_POLICIES.FORM.PASSWORD_POLICY_EXPIRE.ENABLE.LABEL')
            }}
            <woot-switch v-model="passwordPolicyExpireEnable" />
          </label>
          <label
            v-if="passwordPolicyExpireEnable"
            :class="{ error: $v.passwordPolicyExpireDays.$error }"
          >
            {{ $t('SECURITY_POLICIES.FORM.PASSWORD_POLICY_EXPIRE.DAYS.LABEL') }}
            <input
              v-model="passwordPolicyExpireDays"
              type="number"
              :placeholder="
                $t(
                  'SECURITY_POLICIES.FORM.PASSWORD_POLICY_EXPIRE.DAYS.PLACEHOLDER'
                )
              "
              @blur="$v.passwordPolicyExpireDays.$touch"
            />
            <span v-if="$v.passwordPolicyExpireDays.$error" class="message">
              {{
                $t('SECURITY_POLICIES.FORM.PASSWORD_POLICY_EXPIRE.DAYS.ERROR')
              }}
            </span>
          </label>
          <hr />
          <div class="row">
            <div class="columns">
              <label>
                {{
                  $t('SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.ENABLE.LABEL')
                }}
                <woot-switch v-model="accessTimeControl.enable" />
              </label>
              <access-time-control
                :settings="accessTimeControl.setupInit"
                :locale="locale"
                @updateSettings="updateAccessTimeControlSettings"
                v-show="accessTimeControl.enable"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="current-version">
        <div>{{ `v${globalConfig.appVersion}` }}</div>
        <div v-if="hasAnUpdateAvailable && globalConfig.displayManifest">
          {{
            $t('GENERAL_SETTINGS.UPDATE_CHATWOOT', {
              latestChatwootVersion: latestChatwootVersion,
            })
          }}
        </div>
        <div class="build-id">
          <div>{{ `Build ${globalConfig.gitSha}` }}</div>
        </div>
      </div>

      <woot-submit-button
        class="button nice success button--fixed-top"
        :button-text="$t('GENERAL_SETTINGS.SUBMIT')"
        :loading="isUpdating"
      />
    </form>

    <woot-loading-state v-if="uiFlags.isFetchingItem" />
  </div>
</template>

<script>
import {
  required,
  minValue,
  requiredIf,
  maxValue,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import { FEATURE_FLAGS } from '../../../../featureFlags';
const semver = require('semver');
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { getLanguageDirection } from 'dashboard/components/widgets/conversation/advancedFilterItems/languages';
import AccessTimeControl from './AccessTimeControl';

export default {
  components: { AccessTimeControl },
  mixins: [accountMixin, alertMixin, configMixin, uiSettingsMixin],
  data() {
    return {
      id: '',
      name: '',
      locale: 'en',
      domain: '',
      supportEmail: '',
      features: {},
      autoResolveDuration: null,
      latestChatwootVersion: null,
      passwordPolicyExpireEnable: false,
      passwordPolicyExpireDays: null,
      twoFactorAuth: false,
      twoFactorAuthDays: 7,
      accessTimeControl: {
        enable: false,
        setupInit: {
          week_days_hours_allow: [],
          days_deny: [],
        },
        setup: {},
      },
      enable_template_limit: false,
      monthly_template_limit: null,
    };
  },
  validations() {
    let validations = {
      name: {
        required,
      },
      locale: {
        required,
      },
      autoResolveDuration: {
        minValue: minValue(1),
        maxValue: maxValue(999),
      },
      twoFactorAuth: {
        required,
      },
      monthly_template_limit: {
        minValue: minValue(1),
        required: requiredIf(function () {
          return this.enable_template_limit;
        }),
      },
    };
    if (this.passwordPolicyExpireEnable) {
      validations.passwordPolicyExpireDays = {
        required,
        minValue: minValue(5),
        maxValue: maxValue(364),
      };
    }
    if (this.twoFactorAuth) {
      validations.twoFactorAuthDays = {
        required,
        minValue: minValue(1),
        maxValue: maxValue(365),
      };
    }
    return validations;
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    showAutoResolutionConfig() {
      return this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.AUTO_RESOLVE_CONVERSATIONS
      );
    },
    hasAnUpdateAvailable() {
      if (!semver.valid(this.latestChatwootVersion)) {
        return false;
      }

      return semver.lt(
        this.globalConfig.appVersion,
        this.latestChatwootVersion
      );
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },

    featureInboundEmailEnabled() {
      return !!this.features.inbound_emails;
    },

    featureCustomReplyDomainEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_domain
      );
    },

    featureCustomReplyEmailEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_email
      );
    },

    getAccountId() {
      return this.id.toString();
    },
  },
  mounted() {
    if (!this.id) {
      this.initializeAccount();
    }
  },
  methods: {
    async initializeAccount() {
      try {
        await this.$store.dispatch('accounts/get');
        const {
          name,
          locale,
          id,
          domain,
          support_email,
          features,
          auto_resolve_duration,
          latest_chatwoot_version: latestChatwootVersion,
          custom_attributes: custom_attributes,
          enable_template_limit: enableTemplateLimit,
          monthly_template_limit: monthlyTemplateLimit,
        } = this.getAccount(this.accountId);

        this.$root.$i18n.locale = locale;
        this.name = name;
        this.locale = locale;
        this.id = id;
        this.domain = domain;
        this.supportEmail = support_email;
        this.features = features;
        this.autoResolveDuration = auto_resolve_duration;
        this.latestChatwootVersion = latestChatwootVersion;
        this.enable_template_limit = enableTemplateLimit;
        this.monthly_template_limit = monthlyTemplateLimit;

        this.accessTimeControl.setup = this.accessTimeControl.setupInit;
        if (custom_attributes) {
          if (custom_attributes.password_policy_expire) {
            this.passwordPolicyExpireEnable =
              custom_attributes.password_policy_expire.enable;
            this.passwordPolicyExpireDays =
              custom_attributes.password_policy_expire.days;
          }
          if (custom_attributes.access_time_control) {
            this.accessTimeControl.setupInit = this.accessTimeControl.setup =
              custom_attributes.access_time_control;
            this.accessTimeControl.enable =
              custom_attributes.access_time_control.enable;
          }
          if (custom_attributes.two_factor_auth) {
            this.twoFactorAuth = custom_attributes.two_factor_auth.enable;
            this.twoFactorAuthDays = custom_attributes.two_factor_auth.days;
          }
        }
      } catch (error) {
        // Ignore error
      }
    },

    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
        return;
      }

      try {
        const data = {
          locale: this.locale,
          name: this.name,
          domain: this.domain,
          support_email: this.supportEmail,
          auto_resolve_duration: this.autoResolveDuration,
          enable_template_limit: this.enable_template_limit,
          custom_attributes: {
            password_policy_expire: {
              enable: this.passwordPolicyExpireEnable,
              days: Number(this.passwordPolicyExpireDays),
            },
            access_time_control: {
              ...this.accessTimeControl.setup,
              ...{
                enable: this.accessTimeControl.enable,
              },
            },
            two_factor_auth: {
              enable: this.twoFactorAuth,
              days: Number(this.twoFactorAuthDays),
            },
          },
        };

        if (data.enable_template_limit) {
          data.monthly_template_limit = Number(this.monthly_template_limit);
        }

        await this.$store.dispatch('accounts/update', data);

        this.$root.$i18n.locale = this.locale;

        this.getAccount(this.id).locale = this.locale;
        this.updateDirectionView(this.locale);
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
      }
    },

    updateDirectionView(locale) {
      const isRTLSupported = getLanguageDirection(locale);
      this.updateUISettings({
        rtl_view: isRTLSupported,
      });
    },

    updateAccessTimeControlSettings(settings) {
      this.accessTimeControl.setup = settings;
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings {
  padding: 24px;
  overflow: auto;
}

.profile--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;

  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }

  .small-9 {
    padding: $space-normal;
  }
}

.current-version {
  font-size: var(--font-size-small);
  text-align: center;
  padding: var(--space-normal);
}
</style>
