/* eslint arrow-body-style: 0 */
import DashboardApps from './Index';
import Wrapper from "../settings/Wrapper";
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/dashboard-apps'),
    component: Wrapper,
    props: {
      headerTitle: 'DASHBOARD_APPS.HEADER',
      icon: 'automation',
    },
    children: [
      {
        path: '',
        name: 'menu_dashboard_apps',
        component: DashboardApps,
        roles: ['administrator', 'supervisor'],
      },
    ]
  },
]