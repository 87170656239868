import ApiClient from './ApiClient';

class HistoryClosingReasons extends ApiClient {
  constructor() {
    super('history_closing_reasons', { accountScoped: true });
  }

  get(data) {
    return axios.get(this.url, {
      params: data
    });
  }
}

export default new HistoryClosingReasons();
