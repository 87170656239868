<template>
  <div class="columns" v-if="optionsComponents.header">
    <label>
      {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.HEADER.SUBTITLE') }}

      <select v-model="optionsHeader.value" @change="onChangeHeaderType">
        <option v-for="item in typeHeader" :key="item.key" :value="item.key">
          {{ item.value }}
        </option>
      </select>
    </label>

    <label
      v-if="optionsHeader.value === 'text'"
      :class="{
        error: v.template.header.$error || isValidVariablesHeader === false,
      }"
    >
      {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.HEADER.SUBSUBTITLE') }}
      <fluent-icon
        icon="info"
        size="14"
        v-tooltip.top-start="
          $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.HEADER.TOOLTIP')
        "
      />

      <input
        v-model.lazy.trim="template.header"
        type="text"
        minlength="1"
        maxlength="60"
        :placeholder="
          $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.HEADER.PLACEHOLDER')
        "
        @blur="v.template.header.$touch"
      />

      <span v-if="v.template.header.$error" class="message">
        {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.HEADER.ERROR') }}
      </span>

      <span v-if="isValidVariablesHeader === false" class="message">
        {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.HEADER.INVALID') }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    isValidVariablesHeader: {
      type: Boolean | undefined,
    },
    optionsComponents: {
      type: Object,
      default: {},
    },
    optionsHeader: {
      type: Object,
      default: {},
    },
    template: {
      type: Object,
      default: {},
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    typeHeader() {
      return [
        { key: 'text', value: this.$t('WHATSAPP_TEMPLATES.MIDIAS.TEXT') },
        { key: 'image', value: this.$t('WHATSAPP_TEMPLATES.MIDIAS.IMAGE') },
        {
          key: 'document',
          value: this.$t('WHATSAPP_TEMPLATES.MIDIAS.DOCUMENT'),
        },
        { key: 'video', value: this.$t('WHATSAPP_TEMPLATES.MIDIAS.VIDEO') },
      ];
    },
  },
  methods: {
    onChangeHeaderType() {
      if (this.optionsHeader.value !== 'text') {
        this.template.header = null;
        this.template.processedParamsHeader = {};
      }
    },
  },
};
</script>