<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('WHATSAPP_TEMPLATES.ADD.TITLE')"
      :header-content="$t('WHATSAPP_TEMPLATES.ADD.DESC')"
    />

    <timeline :current_page="page" :total="4" />

    <form class="row" @submit.prevent="addTemplate">
      <div class="medium-12 columns">
        <div v-if="page === 1">
          <label :class="{ error: $v.template.name.$error }">
            {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.NAME.LABEL') }}
            <fluent-icon
              icon="info"
              size="14"
              v-tooltip.top-start="
                $t('WHATSAPP_TEMPLATES.ADD.FORM.NAME.TOOLTIP')
              "
            />

            <input
              v-model.trim="template.name"
              type="text"
              minlength="1"
              maxlength="60"
              :placeholder="$t('WHATSAPP_TEMPLATES.ADD.FORM.NAME.PLACEHOLDER')"
              @keyup="formatName"
              @blur="$v.template.name.$touch"
            />

            <span v-if="$v.template.name.$error" class="message">
              {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.NAME.ERROR') }}
            </span>
          </label>
        </div>

        <div v-if="page === 2">
          <label>
            {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CATEGORY.LABEL') }}

            <select v-model="template.category">
              <option
                v-for="item in categories"
                :key="item.key"
                :value="item.key"
              >
                {{ item.value }}
              </option>
            </select>
          </label>

          <label>
            {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CHANGE_CATEGORY.LABEL') }}

            <woot-switch v-model="template.allow_category_change" />
          </label>

          <label>
            {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.LANG.LABEL') }}

            <select v-model="template.language">
              <option
                v-for="item in languages"
                :key="item.key"
                :value="item.key"
              >
                {{ item.value }}
              </option>
            </select>
          </label>
        </div>

        <div v-if="page === 3">
          <content-options :options-components="optionsComponents" />

          <content-header
            v-bind:is-valid-variables-header="isValidVariablesHeader"
            :options-components="optionsComponents"
            :options-header="optionsHeader"
            :template="template"
            :v="$v"
          />

          <content-body
            v-bind:is-valid-variables-body="isValidVariablesBody"
            :template="template"
            :v="$v"
          />

          <content-footer
            v-bind:has-variables-in-footer="hasVariablesInFooter"
            :options-components="optionsComponents"
            :template="template"
            :v="$v"
          />

          <content-button
            :options-components="optionsComponents"
            :template="template"
            :v="$v"
          />

          <content-vars
            v-bind:is-valid-variables-header="isValidVariablesHeader"
            v-bind:is-valid-variables-body="isValidVariablesBody"
            :template="template"
            :v="$v"
          />
        </div>

        <div v-if="page === 4">
          <content-preview
            :template="getTemplateDetails().template"
            :types="getTypes"
          />
        </div>
      </div>

      <wait-label v-if="uiFlags.isCreating" />

      <div class="modal-footer">
        <woot-button
          @click.prevent="changePage('minus')"
          :isDisabled="canDisableButton"
        >
          {{ $t('GENERAL_SETTINGS.BACK') }}
        </woot-button>

        <woot-button v-if="canChangePage" @click.prevent="changePage('add')">
          {{ $t('WHATSAPP_TEMPLATES.NEXT.BUTTON_TEXT') }}
        </woot-button>

        <woot-button v-else :is-loading="uiFlags.isCreating">
          {{ $t('WHATSAPP_TEMPLATES.ADD.CREATE_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
const allKeysRequired = value => Object.keys(value).every(key => value[key]);
const urlValid = helpers.regex('u', templateWppMixin.computed.getRegexs().url);

import { mapGetters, mapActions } from 'vuex';
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  helpers,
} from 'vuelidate/lib/validators';

import ContentBody from './ContentBody';
import ContentButton from './ContentButton';
import ContentFooter from './ContentFooter';
import ContentHeader from './ContentHeader';
import ContentOptions from './ContentOptions';
import ContentPreview from './ContentPreview';
import ContentVars from './ContentVars';
import WaitLabel from 'shared/components/Label';
import Timeline from 'shared/components/Timeline';

import alertMixin from 'shared/mixins/alertMixin';
import templateWppMixin from 'shared/mixins/templateWppMixin';

export default {
  components: {
    ContentBody,
    ContentButton,
    ContentFooter,
    ContentHeader,
    ContentOptions,
    ContentPreview,
    ContentVars,
    WaitLabel,
    Timeline,
  },
  mixins: [alertMixin, templateWppMixin],
  props: {
    channel: {
      type: Number,
    },
  },
  data() {
    return {
      template: {
        name: '',
        category: 'UTILITY',
        language: 'pt_BR',
        allow_category_change: false,
        components: [],
        header: null,
        body: null,
        footer: null,
        buttons: [],
        processedParamsHeader: {},
        processedParamsBody: {},
      },
      optionsComponents: {
        header: false,
        footer: false,
        buttons: false,
      },
      optionsHeader: {
        value: 'text',
      },
      page: 1,
    };
  },
  validations() {
    return {
      template: {
        name: { required },
        category: { required },
        language: { required },
        header: {
          required: requiredIf(function () {
            return (
              this.optionsComponents.header &&
              this.optionsHeader.value === 'text'
            );
          }),
        },
        body: { required },
        footer: {
          required: requiredIf(() => this.optionsComponents.footer),
        },
        buttons: {
          required: requiredIf(() => this.optionsComponents.buttons),
          $each: {
            type: { required },
            text: { required },
            url: {
              required: requiredIf(prop => prop.type === 'URL'),
              urlValid: urlValid,
            },
            phone_number: {
              required: requiredIf(prop => prop.type === 'PHONE_NUMBER'),
              minLength: minLength(13),
              maxLength: maxLength(20),
            },
          },
        },
        processedParamsHeader: {
          requiredIfKeysPresent: requiredIf('variables'),
          allKeysRequired,
        },
        processedParamsBody: {
          requiredIfKeysPresent: requiredIf('variables'),
          allKeysRequired,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'whatsappTemplates/getUIFlags',
    }),
    categories() {
      return [
        {
          key: 'UTILITY',
          value: this.$t('WHATSAPP_TEMPLATES.CATEGORY.UTILITY'),
        },
        {
          key: 'MARKETING',
          value: this.$t('WHATSAPP_TEMPLATES.CATEGORY.MARKETING'),
        },
      ];
    },
    languages() {
      return [
        { key: 'pt_BR', value: this.$t('WHATSAPP_TEMPLATES.LANGS.PT_BR') },
        { key: 'en_US', value: this.$t('WHATSAPP_TEMPLATES.LANGS.EN') },
        { key: 'es', value: this.$t('WHATSAPP_TEMPLATES.LANGS.ES') },
      ];
    },
    canChangePage() {
      return this.page !== 4;
    },
    canDisableButton() {
      return this.page === 1 || (this.page === 4 && this.uiFlags.isCreating);
    },
    getMaxVariables() {
      return { header: 1, body: 10 };
    },
    isValidVariablesHeader() {
      if (this.template.header) {
        return this.validateListVariables(this.template.header, 'header');
      }
    },
    isValidVariablesBody() {
      if (this.template.body) {
        return this.validateListVariables(this.template.body, 'body');
      }
    },
    hasVariablesInFooter() {
      if (this.template.footer) {
        return this.hasVariables(this.template.footer);
      }

      return false;
    },
    getUrls() {
      const baseUrl = 'https://converx.app/assets/images/templates/converx';

      const URL_IMAGE = process.env.CONVERX_IMAGE ?? `${baseUrl}.png`;
      const URL_DOCUMENT = process.env.CONVERX_DOCUMENT ?? `${baseUrl}.pdf`;
      const URL_VIDEO = process.env.CONVERX_VIDEO ?? `${baseUrl}.mp4`;

      return { image: URL_IMAGE, document: URL_DOCUMENT, video: URL_VIDEO };
    },
    getTypes() {
      return {
        text: { key: 'TEXT', value: 'text' },
        document: { key: 'DOCUMENT', value: 'document' },
        image: { key: 'IMAGE', value: 'image' },
        video: { key: 'VIDEO', value: 'video' },
        header: { key: 'HEADER', value: 'header' },
        body: { key: 'BODY', value: 'body' },
        footer: { key: 'FOOTER', value: 'footer' },
        button: { key: 'BUTTONS', value: 'button' },
      };
    },
  },
  methods: {
    ...mapActions('whatsappTemplates', ['create']),
    async addTemplate() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      try {
        const data = {
          template: this.getTemplateDetails(),
          channel: this.channel,
        };

        await this.create(data);

        this.showAlert(this.$t('WHATSAPP_TEMPLATES.ADD.API.SUCCESS_MESSAGE'));
        this.$emit('on-created');
      } catch (error) {
        this.showAlert(this.$t('WHATSAPP_TEMPLATES.ADD.API.ERROR_MESSAGE'));
      } finally {
        this.onClose();
      }
    },
    getTemplateDetails() {
      const data = {
        template: {
          name: this.template.name,
          language: this.template.language,
          category: this.template.category,
          allow_category_change: this.template.allow_category_change,
          body_parameters: {
            type: this.getTypes.body.key,
            text: this.template.body,
          },
        },
      };

      if (this.optionsComponents.header) {
        data.template.header_parameters = {
          type: this.getTypes.header.key,
          format: this.optionsHeader.value.toUpperCase(),
        };

        if (this.optionsHeader.value === this.getTypes.text.value) {
          data.template.header_parameters.text = this.template.header;

          if (Object.values(this.template.processedParamsHeader).length) {
            data.template.header_parameters.example = {
              header_text: Object.values(this.template.processedParamsHeader),
            };
          }
        } else {
          let url = this.getUrls.image;

          if (this.optionsHeader.value === this.getTypes.document.value) {
            url = this.getUrls.document;
          }

          if (this.optionsHeader.value === this.getTypes.video.value) {
            url = this.getUrls.video;
          }

          data.template.header_parameters.example = { header_handle: [url] };
        }
      }

      if (Object.values(this.template.processedParamsBody).length) {
        data.template.body_parameters.example = {
          body_text: [Object.values(this.template.processedParamsBody)],
        };
      }

      if (this.optionsComponents.footer) {
        data.template.footer_parameters = {
          type: this.getTypes.footer.key,
          text: this.template.footer,
        };
      }

      if (this.optionsComponents.buttons) {
        data.template.button_parameters = {
          type: this.getTypes.button.key,
          buttons: this.template.buttons,
        };
      }

      return data;
    },
    onClose() {
      this.$emit('on-close');
    },
    changePage(type) {
      const { page } = this;

      if (type === 'minus' && page > 1) {
        --this.page;
      }

      if (type === 'add' && page < 4) {
        this.$v.$touch();

        if (
          (page === 1 && this.$v.template.name.$error) ||
          (page === 3 &&
            (this.$v.template.header.$error ||
              this.$v.template.body.$error ||
              this.$v.template.footer.$error ||
              this.$v.template.buttons.$error ||
              this.isValidVariablesHeader === false ||
              this.isValidVariablesBody === false ||
              this.hasVariablesInFooter ||
              this.$v.$invalid))
        ) {
          return;
        }

        this.$v.$reset();

        ++this.page;
      }
    },
    formatName() {
      this.template.name = this.template.name
        .replace(this.getRegexs.words, ' ')
        .split(this.getRegexs.unicode)
        .map(word => word.toLowerCase())
        .join('_');
    },
    hasVariables(text) {
      const match = text.match(this.getRegexs.vars);

      return match ?? false;
    },
    validateListVariables(text, type) {
      const match = this.hasVariables(text);

      if (match) {
        return match.length <= this.getMaxVariables[type];
      }
    },
    getVariablesTemplate() {
      if (this.template.header) {
        const { header } = this.template;

        if (this.validateListVariables(header, this.getTypes.header.value)) {
          this.generateVariables(this.template.header, 'processedParamsHeader');
        }
      }

      if (this.template.body) {
        const { body } = this.template;

        if (this.validateListVariables(body, this.getTypes.body.value)) {
          this.generateVariables(this.template.body, 'processedParamsBody');
        }
      }
    },
    generateVariables(templateString, saveTo) {
      const matchedVariables = this.hasVariables(templateString);

      if (!matchedVariables) return;

      const variables = matchedVariables.map(i => this.processVariable(i));

      this.template[saveTo] = variables.reduce((acc, variable) => {
        acc[variable] = '';
        return acc;
      }, {});
    },
    onKeyupRemoveEmoji(type) {
      if (this.template[type]) {
        this.template[type] = this.template[type]
          .replace(this.getRegexs.emoji, '')
          .replace(this.getRegexs.spaces, ' ')
          .trim();
      }
    },
  },
  watch: {
    'template.header'(new_value) {
      if (new_value) {
        this.onKeyupRemoveEmoji(this.getTypes.header.value);
        this.getVariablesTemplate();
      }
    },
    'template.body'(new_value) {
      if (new_value) this.getVariablesTemplate();
    },
    'template.footer'(new_value) {
      if (new_value) this.onKeyupRemoveEmoji(this.getTypes.footer.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-button {
  margin-bottom: var(--space-normal);
}

.message {
  margin-top: var(--space-micro);

  &.custom-error {
    position: absolute;
    margin-top: var(--space-jumbo);
    left: var(--space-large);
    width: 50%;
  }
}

.modal-footer {
  button {
    margin-left: 0 !important;
  }

  width: 100%;
  justify-content: space-between !important;
}
</style>