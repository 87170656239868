<template>
  <div>
    <label
      :class="{
        error: isPhoneNumberNotValid,
      }"
    >
      <div v-if="label">{{ label }}</div>
      <woot-phone-input
        v-model="phoneNumber"
        :value="phoneNumber"
        :error="isPhoneNumberNotValid"
        :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
        @input="onPhoneNumberInputChange"
        @blur="$v.phoneNumber.$touch"
        @setCode="setPhoneCode"
      />
      <span v-if="isPhoneNumberNotValid" class="message">
        {{ phoneNumberError }}
      </span>
    </label>
    <div
      v-if="isPhoneNumberNotValid || !phoneNumber"
      class="callout small warning"
    >
      {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.HELP') }}
    </div>
  </div>
</template>

<script>
import {
  isPhoneNumberValid,
  isPtBRPhoneNoZeroStart,
} from 'shared/helpers/Validators';
import parsePhoneNumber from 'libphonenumber-js';

export default {
  props: {
    label: String,
    value: {
      type: String,
      default: '',
    },
    onPhoneNumber: {
      type: Function,
      default: n => n,
    },
  },
  data() {
    return {
      phoneNumber: '',
      activeDialCode: '',
    };
  },
  computed: {
    parsePhoneNumber() {
      return parsePhoneNumber(this.phoneNumber);
    },
    isPhoneNumberNotValid() {
      if (this.phoneNumber !== '') {
        return (
          !isPhoneNumberValid(this.phoneNumber, this.activeDialCode) ||
          (this.phoneNumber !== '' ? this.activeDialCode === '' : false)
        );
      }
      return false;
    },
    phoneNumberError() {
      if (this.activeDialCode === '') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DIAL_CODE_ERROR');
      }
      if (!isPhoneNumberValid(this.phoneNumber, this.activeDialCode)) {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR');
      }
      return '';
    },
    setPhoneNumber() {
      if (this.parsePhoneNumber && this.parsePhoneNumber.countryCallingCode) {
        return this.phoneNumber;
      }
      if (this.phoneNumber === '' && this.activeDialCode !== '') {
        return '';
      }
      return this.activeDialCode
        ? `${this.activeDialCode}${this.phoneNumber}`
        : '';
    },
  },
  watch: {
    setPhoneNumber() {
      this.onPhoneNumber(this.setPhoneNumber);
    },
  },
  validations: {
    phoneNumber: { isPtBRPhoneNoZeroStart },
  },
  mounted() {
    this.phoneNumber = this.value || '';
    this.setDialCode();
  },
  methods: {
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
    },
    setDialCode() {
      if (
        this.phoneNumber !== '' &&
        this.parsePhoneNumber &&
        this.parsePhoneNumber.countryCallingCode
      ) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        this.activeDialCode = `+${dialCode}`;
      }
    },
    setPhoneCode(code) {
      if (this.phoneNumber !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        const newPhoneNumber = this.phoneNumber.replace(
          `+${dialCode}`,
          `${code}`
        );
        this.phoneNumber = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },
  },
};
</script>

<style>
</style>