/* global axios */
class WhatsAppWeb {
  constructor() {
    this.baseUrl = `${globalConfig.WHATSAPP_WEB_BASE_URL}`;
    this.secretkey = `${globalConfig.WHATSAPP_WEB_SECRET}`;
  }

  loadConfig({ instance, api_base_url = null }) {
    this.instance = instance;
    if (api_base_url) this.baseUrl = api_base_url;
  }

  getSecret() {
    return this.secretkey;
  }

  getInstance() {
    return this.instance;
  }

  getWebhookUrl() {
    return `${location.origin}/webhooks/whatsapp/${this.instance}`;
  }

  getOptions() {
    return {
        params: {
          key: this.instance
        }
    };
  }

  async initInstance() {
    const options = this.getOptions();
    const params = {
      ...options.params,
      token: this.getSecret(),
      webhook: true,
      webhookUrl: this.getWebhookUrl()
    }

    const { data } = await axios.get(`${this.baseUrl}/instance/init`, { params });

    return new Promise((resolve) => resolve(data));
  }

  async findQrCode() {
    const options = this.getOptions();
    const { data } = await axios.get(`${this.baseUrl}/instance/qrbase64`, options);
    this.qrcode = data.qrcode.trim();

    return new Promise((resolve) => resolve(data));
  }

  async info() {
    const options = this.getOptions();
    const { data } = await axios.get(`${this.baseUrl}/instance/info`, options);

    const { phone_connected } = data.instance_data;
    this.status = !!phone_connected ? 'CONNECTED' : 'CLOSED';

    return new Promise((resolve) => resolve(data));
  }

  //TODO: Implementar api de status da sessão (instancia)
  async statusSession(starting = false) {
    await this.info();
    if (!this.qrcode || starting) await this.findQrCode();

    if (starting && !!this.qrcode) {
      this.status = 'QRCODE';
    }

    return {
      status: this.status || 'CLOSED',
      qrcode: this.qrcode
    }
  }

  async logoutSession() {
    const options = this.getOptions();
    await axios.delete(`${this.baseUrl}/instance/logout`, options);
    await axios.delete(`${this.baseUrl}/instance/delete`, options);

    return { status: true };
  }

  getQRCode() {
    if (!this.qrcode) return '';
    if (this.qrcode.includes('data:image/png;base64,')) {
        return this.qrcode;
    }
    return `data:image/png;base64,${this.qrcode}`;
  }
}

export default new WhatsAppWeb();
