import axiosRequest from "axios";
import Auth from '../api/auth';

class ApiBotMaker {
  constructor(resource, options = {}) {
    this.options = options;
    this.resource = resource;

    this.api = axiosRequest.create({
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    });

    if (typeof botCheckins != 'undefined') {
      clearInterval(botCheckins);
    }

    let botCheckins = setInterval(() => {
      if (Auth.hasCookieBotmaker()) {
        clearInterval(botCheckins);

        const { "access-token": accessToken, client } = Auth.getAuthData();
        const { token } = Auth.getDataCookieBotmaker();

        Object.assign(this.api.defaults.headers.common, {
          "Authorization": `Bearer ${token}`,
          "Access-Token": accessToken,
          "Client": client
        });
      }
    }, 1000);
  }

  get url() {
    return `${this.baseUrl()}/accounts/1/converx/${this.accountIdFromRoute}/${this.resource}`;
  }

  // eslint-disable-next-line class-methods-use-this
  get accountIdFromRoute() {
    const isInsideAccountScopedURLs = window.location.pathname.includes(
      '/app/accounts'
    );

    if (isInsideAccountScopedURLs) {
      return window.location.pathname.split('/')[3];
    }

    return '';
  }

  baseUrl() {
    const host = "https://botmaker.phonetrack.app";
    const version = "v1";

    return `${host}/api/${version}`;
  }

  get() {
    return this.api.get(this.url);
  }

  delete(id) {
    return this.api.delete(`${this.baseUrl()}/${this.resource}/${id}`);
  }
}

export default ApiBotMaker;
