/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../../helper/URLHelper';
import iFrameFeedback from './iFrameFeedback';
import Wrapper from "../Wrapper.vue";
export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/feedback'),
      component: Wrapper,
      props: {
        headerTitle: 'Feedback',
        icon: 'book-open-globe',
      },
      children: [
        {
          path: '',
          name: 'submenu_feedback',
          component: iFrameFeedback,
          roles: ['administrator', 'agent', 'seller', 'supervisor'],
        },
      ]
    },
  ]
};
