<template>
  <div
    v-show="hasOpenedAtleastOnce"
    class="dashboard-app--container"
    :ref="encodeURIComponent(url)"
  >
    <div
      v-for="(configItem, index) in config"
      :key="index"
      class="dashboard-app--list"
    >
      <loading-state
        v-if="iframeLoading"
        :message="$t('DASHBOARD_APPS.LOADING_MESSAGE')"
        class="dashboard-app_loading-container"
      />
      <iframe
        title="dashboard-app"
        v-if="configItem.type === 'frame' && configItem.url && isIframe"
        :id="`dashboard-app--frame-${index}`"
        :src="configItem.url"
        @load="() => onIframeLoad(index)"
      />
    </div>
  </div>
</template>

<script>
import LoadingState from 'dashboard/components/widgets/LoadingState';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { mapGetters } from 'vuex';
export default {
  components: {
    LoadingState,
  },
  props: {
    config: {
      type: Array,
      default: () => [],
    },
    currentChat: {
      type: Object,
      default: () => ({}),
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasOpenedAtleastOnce: false,
      iframeLoading: true,
      isIframe: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    dashboardAppContext() {
      return {
        conversation: this.currentChat,
        contact: this.$store.getters['contacts/getContact'](this.contactId),
        currentAgent: this.currentAgent,
      };
    },
    contactId() {
      return this.currentChat?.meta?.sender?.id;
    },
    currentAgent() {
      const { id, name, email } = this.$store.getters.getCurrentUser;
      return { id, name, email };
    },
    url() {
      return this.config[0]?.url;
    },
  },
  watch: {
    isVisible() {
      if (this.isVisible) {
        this.hasOpenedAtleastOnce = true;
      }
    },
  },

  mounted() {
    if (this.isVisible) {
      this.hasOpenedAtleastOnce = true;
    }

    window.onmessage = e => {
      if (
        typeof e.data !== 'string' ||
        e.data !== 'chatwoot-dashboard-app:fetch-info'
      ) {
        return;
      }
      this.onIframeLoad(0);
    };

    this.initialize();
  },
  methods: {
    onIframeLoad(index) {
      const frameElement = document.getElementById(
        `dashboard-app--frame-${index}`
      );
      const eventData = { event: 'appContext', data: this.dashboardAppContext };
      frameElement.contentWindow.postMessage(JSON.stringify(eventData), '*');
      this.iframeLoading = false;
    },
    initialize() {
      try {
        new URL(this.url);
        this.isIframe = true;
        this.iframeLoading = false;
      } catch (e) {
        this.isIframe = false;
        this.iframeLoading = true;
        axios
          .post(
            `/api/v1/accounts/${this.accountId}/dashboard_apps/${this.url}/superset/guest-token`,
            {
              dashboard_id: this.url,
              rls: [
                {
                  clause: `account_id=${this.accountId}`,
                },
              ],
            }
          )
          .then(({ data }) => {
            this.iframeLoading = false;
            const ref = encodeURIComponent(this.url);
            embedDashboard({
              id: this.url,
              supersetDomain: 'https://superset.phonetrack.com.br',
              mountPoint: this.$refs[ref],
              fetchGuestToken: () => data.token,
              dashboardUiConfig: {
                hideTitle: true,
                filters: {
                  visible: false,
                  expanded: false,
                },
              },
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
.dashboard-app--container,
.dashboard-app--list,
.dashboard-app--list iframe {
  height: 100%;
  width: 100%;
}

.dashboard-app--list iframe {
  border: 0;
}
.dashboard-app_loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.dashboard-app--container iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
</style>
