import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUserRole: 'getCurrentRole',
    }),
    isAdmin() {
      const roles = ['administrator', 'supervisor']
      return roles.includes(this.currentUserRole);
    },
  },
};
