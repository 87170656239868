<template>
  <div class="small-12 medium-4 bg-white campaign--panel">
    <div class="campaign--info">
      <div class="campaign-info--header">
        <woot-button
          :icon="closeIconName"
          class="clear secondary close-button--rtl"
          @click="onPanelToggle"
        />
      </div>

      <div class="campaign--details">
        <div class="campaign--name-wrap">
          <h3 class="sub-block-title campaign--name">
            {{ campaign.title }}
          </h3>
        </div>

        <div class="campaign--content-wrap">
          <div class="campaign--content-wrap-item">
            <h3 class="sub-block-title">
              {{ $t('CAMPAIGN.LIST.TABLE_HEADER.SENDER') }}:
            </h3>

            <UserAvatarWithName :user="campaign.sender" />
          </div>

          <div class="campaign--content-wrap-item">
            <h3 class="sub-block-title">
              {{ $t('CAMPAIGN.LIST.TABLE_HEADER.INBOX') }}:
            </h3>

            <InboxName :inbox="campaign.inbox" />
          </div>

          <div class="campaign--content-wrap-item">
            <h3 class="sub-block-title">
              {{ $t('CAMPAIGN.LIST.TABLE_HEADER.CREATED_AT') }}:
            </h3>

            <h3 class="sub-block-subtitle">
              {{
                messageStamp(generateTimestamp(campaign), 'dd/MM/yy - HH:mm')
              }}
            </h3>
          </div>

          <div class="campaign--content-wrap-item">
            <h3 class="sub-block-title">
              {{ $t('CAMPAIGN.LIST.TABLE_HEADER.SCHEDULED_AT') }}:
            </h3>

            <h3 class="sub-block-subtitle">
              {{
                messageStamp(
                  new Date(campaign.scheduled_at),
                  'dd/MM/yy - HH:mm'
                )
              }}
            </h3>
          </div>

          <div class="campaign--content-wrap-item">
            <h3 class="sub-block-title">
              {{ $t('CAMPAIGN.LIST.TABLE_HEADER.STATUS') }}:
            </h3>

            <Label
              :title="getInfoLabel(campaign).title"
              :colorScheme="getInfoLabel(campaign).color"
            />
          </div>

          <div class="campaign--content-wrap-item">
            <h3 class="sub-block-title">
              {{ $t('CAMPAIGN.LIST.TABLE_HEADER.PUBLIC') }}:
            </h3>

            <CampaignPublic :labels="getPublic(campaign)" />
          </div>

          <div class="campaign--content-wrap-item" v-if="campaign.file">
            <h3 class="sub-block-title">
              {{ $t('CAMPAIGN.LIST.TABLE_HEADER.ATTACHMENT') }}:
            </h3>

            <a :href="campaign.file.file_url" target="_blank">
              Visualizar {{ getFileType(campaign) }}
            </a>
          </div>

          <div class="campaign--content-wrap-item-custom">
            <h3 class="sub-block-title">
              {{ $t('CAMPAIGN.LIST.TABLE_HEADER.TEMPLATE') }}:
            </h3>

            <p v-html="formatMessage(campaign.message)"></p>
          </div>

          <div class="campaign--content-wrap-item">
            <woot-button
              v-if="isAdmin"
              type="button"
              :disabled="campaign.campaign_status === 'completed'"
              @click="toggleDeleteModal"
            >
              {{ $t('CAMPAIGN.LIST.BUTTONS.DELETE') }}
            </woot-button>
          </div>
        </div>
      </div>

      <woot-delete-modal
        v-if="showDeleteModal"
        :show.sync="showDeleteModal"
        :on-close="closeDelete"
        :on-confirm="confirmDeletion"
        :title="$t('CAMPAIGN.DELETE.CONFIRM.TITLE')"
        :message="$t('CAMPAIGN.DELETE.CONFIRM.MESSAGE')"
        :confirm-text="$t('CAMPAIGN.DELETE.CONFIRM.YES')"
        :reject-text="$t('CAMPAIGN.DELETE.CONFIRM.NO')"
      />
    </div>
  </div>
</template>

<script>
import Label from 'dashboard/components/ui/Label';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName';
import InboxName from 'dashboard/components/widgets/InboxName';
import CampaignPublic from './CampaignPublic';

import adminMixin from 'dashboard/mixins/isAdmin';
import timeMixin from 'dashboard/mixins/time';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import alertMixin from 'shared/mixins/alertMixin';

import { mapGetters } from 'vuex';

export default {
  components: {
    InboxName,
    Label,
    UserAvatarWithName,
    CampaignPublic,
  },
  mixins: [adminMixin, alertMixin, messageFormatterMixin, timeMixin],
  props: {
    campaign: {
      type: Object,
      default: () => ({}),
    },
    closeIconName: {
      type: String,
      default: 'dismiss',
    },
  },
  data() {
    return {
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      labelList: 'labels/getLabels',
    }),
    typeFile() {
      return {
        document: ['application/pdf', 'application/msword'],
        image: ['image/png', 'image/jpeg'],
        video: ['video/mp4', 'video/3gpp'],
      };
    },
  },
  methods: {
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    getPublic({ audience }) {
      const ids = audience.map(({ id }) => id);

      return this.labelList
        .filter(item => ids.includes(item.id))
        .map(({ title, color }) => {
          return { title, color };
        });
    },
    getInfoLabel({ campaign_status }) {
      const title =
        campaign_status === 'completed'
          ? this.$t('CAMPAIGN.LIST.STATUS.COMPLETED')
          : this.$t('CAMPAIGN.LIST.STATUS.ACTIVE');

      const color = campaign_status === 'completed' ? 'secondary' : 'success';

      return { title, color };
    },
    generateTimestamp({ created_at }) {
      const time = new Date(created_at).getTime() / 1000;
      return Math.floor(time);
    },
    onPanelToggle() {
      this.$emit('toggle-panel');
    },
    confirmDeletion() {
      this.deleteCampaign(this.campaign);
      this.closeDelete();
    },
    deleteCampaign({ id }) {
      try {
        this.$store.dispatch('campaigns/delete', id);
        this.$emit('toggle-panel');
        this.$emit('load-campaign');
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('CAMPAIGN.DELETE.API.ERROR_MESSAGE')
        );
      }
    },
    closeDelete() {
      this.showDeleteModal = false;
    },
    getFileType(campaign) {
      const { file_type } = campaign.file;

      if (this.typeFile.document.includes(file_type)) return 'documento';
      if (this.typeFile.image.includes(file_type)) return 'imagem';
      if (this.typeFile.video.includes(file_type)) return 'video';
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign--panel {
  height: 100%;
  background: white;
  font-size: var(--font-size-small);
  overflow-y: auto;
  overflow: auto;
  position: relative;
  border-right: 1px solid var(--color-border);

  .campaign--info {
    text-align: left;

    .campaign-info--header {
      background-color: #053ca8;
      height: var(--space-larger);

      .close-button--rtl {
        top: var(--space-micro);
        right: var(--space-micro);
        position: absolute;
        color: var(--white);

        &:hover {
          background-color: transparent;
          color: var(--white-transparent);
        }
      }
    }
  }

  .campaign--details {
    width: 100%;
    padding: var(--space-normal);

    .campaign--name-wrap {
      margin-bottom: var(--space-small);
    }

    .campaign--name {
      text-transform: capitalize;
      white-space: normal;
      font-size: var(--font-size-large);
    }

    .campaign--content-wrap {
      margin-top: var(--space-small);

      .campaign--content-wrap-item {
        display: flex;
        align-items: center;
        margin-top: var(--space-medium);

        .sub-block-title {
          margin-right: var(--space-one);
          font-size: var(--font-size-small);
        }

        .sub-block-subtitle {
          font-size: var(--font-size-mini);
        }

        .row--user-block {
          align-items: center;
          display: flex;
          text-align: left;

          .user-name {
            margin: 0 var(--space-small);
          }
        }

        .inbox--name {
          margin-top: var(--space-minus-normal);
        }

        button {
          width: 100%;
        }
      }

      .campaign--content-wrap-item-custom {
        margin-top: var(--space-medium);

        .sub-block-title {
          margin-right: var(--space-one);
          font-size: var(--font-size-small);
        }
      }
    }
  }
}
</style>
