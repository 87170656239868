<template>
  <section class="table-wrap">
    <ve-table
      :fixed-header="true"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
      scroll-width="190rem"
    />

    <empty-state v-if="showSearchEmptyState" :title="$t('CAMPAIGN.LIST.404')" />

    <empty-state
      v-else-if="!isLoading && !campaigns.length"
      :title="$t('CAMPAIGN.LIST.NO_CAMPAIGNS')"
    />

    <div v-if="isLoading" class="campaign--loader">
      <spinner />
      <span>{{ $t('CAMPAIGN.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';

import Spinner from 'shared/components/Spinner';
import EmptyState from 'dashboard/components/widgets/EmptyState';
import InboxName from 'dashboard/components/widgets/InboxName';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName';
import Label from 'dashboard/components/ui/Label';
import CampaignPublic from './CampaignPublic';

import { mixin as clickaway } from 'vue-clickaway';
import campaignMixin from 'shared/mixins/campaignMixin';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },
  mixins: [
    clickaway,
    timeMixin,
    rtlMixin,
    campaignMixin,
    messageFormatterMixin,
  ],
  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    onClickCampaign: {
      type: Function,
      default: () => {},
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
    };
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }

      return this.campaigns;
    },
    columns() {
      return [
        {
          field: 'title',
          key: 'title',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.TITLE'),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.title || '',
          renderBodyCell: ({ row }) => (
            <woot-button
              variant="clear"
              onClick={() => this.onClickCampaign(row.id)}
            >
              <div class="row--campaign-block">
                <div class="campaign-block">
                  <h6 class="sub-block-title text-truncate">{row.title}</h6>
                  <button class="button clear small link view-details--button">
                    {this.$t('CONTACTS_PAGE.LIST.VIEW_DETAILS')}
                  </button>
                </div>
              </div>
            </woot-button>
          ),
        },
        {
          field: 'inbox',
          key: 'inbox',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.INBOX'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.inbox || '',
          renderBodyCell: ({ row }) => {
            return <InboxName inbox={row.inbox} />;
          },
        },
        {
          field: 'sender',
          key: 'sender',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SENDER'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.sender || '',
          renderBodyCell: ({ row }) => {
            return <UserAvatarWithName user={row.sender} />;
          },
        },
        {
          field: 'created_at',
          key: 'created_at',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.CREATED_AT'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.created_at || '',
          renderBodyCell: ({ row }) => (
            <span>
              {this.messageStamp(
                this.generateTimestamp(row.created_at),
                'dd/MM/yy - HH:mm'
              )}
            </span>
          ),
        },
        {
          field: 'scheduled_at',
          key: 'scheduled_at',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SCHEDULED_AT'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.scheduled_at || '',
          renderBodyCell: ({ row }) => (
            <span>
              {this.messageStamp(
                new Date(row.scheduled_at),
                'dd/MM/yy - HH:mm'
              )}
            </span>
          ),
        },
        {
          field: 'audience',
          key: 'audience',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.PUBLIC'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            const audience = this.getLabels(row.audience.map(({ id }) => id));

            return <CampaignPublic labels={audience} />;
          },
        },
        {
          field: 'campaign_status',
          key: 'campaign_status',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.campaign_status || '',
          renderBodyCell: ({ row }) => {
            const labelText =
              row.campaign_status === 'completed'
                ? this.$t('CAMPAIGN.LIST.STATUS.COMPLETED')
                : this.$t('CAMPAIGN.LIST.STATUS.ACTIVE');
            const colorScheme =
              row.campaign_status === 'completed' ? 'secondary' : 'success';
            return <Label title={labelText} colorScheme={colorScheme} />;
          },
        },
      ];
    },
    emptyMessage() {
      return this.campaigns.length
        ? this.$t('CAMPAIGN.WHATSAPP.404')
        : this.$t('CAMPAIGN.WHATSAPP.INBOXES_NOT_FOUND');
    },
  },
  methods: {
    getLabels(ids) {
      return this.labels
        .filter(item => ids.includes(item.id))
        .map(({ title, color }) => {
          return { title, color };
        });
    },
    generateTimestamp(date) {
      const time = new Date(date).getTime() / 1000;
      return Math.floor(time);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }

  .row--campaign-block {
    align-items: center;
    display: flex;
    text-align: left;

    .campaign-block {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin: 0 var(--space-small);
      max-width: var(--space-giga);

      h6 {
        color: var(--c-500);
        text-transform: capitalize;
        font-size: var(--font-size-small);
      }
    }

    .view-details--button {
      color: var(--b-800);
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    font-size: var(--font-size-mini) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;

    a {
      font-weight: var(--font-weight-medium);
    }
  }

  .ve-table-sort {
    top: -4px;
  }
}

.campaign--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  min-width: 20rem;
}
</style>
