<template>
  <div class="column content-box">
    <div v-if="dennyCauses.includes('POLICY_ACCESS_TIME_CONTROL')">
      <p
        v-html="
          $t('BLOCK_ACCESS_USER_ACCOUNT.POLICY_ACCESS_TIME_CONTROL.TEXT_INFO')
        "
        class="block-access-user-account-access-text-info"
      />
    </div>
    <template v-else-if="dennyCauses.includes('PASSWORD_POLICY_EXPIRE')">
      <p
        v-html="
          $t('BLOCK_ACCESS_USER_ACCOUNT.PASSWORD_POLICY_EXPIRE.TEXT_INFO')
        "
        class="block-access-user-account-access-text-info"
      />
      <change-password />
    </template>
  </div>
</template>

<script>
import ChangePassword from '../settings/profile/ChangePassword';
import userAccountsAccess from '../../../api//userAccountsAccess';

export default {
  components: {
    ChangePassword,
  },
  data() {
    return { dennyCauses: [] };
  },
  async created() {
    try {
      const response = await userAccountsAccess.dennyCauses();
      this.dennyCauses = response.data;
      if (this.dennyCauses.length == 0) {
        window.location = '/';
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/woot';

.block-access-user-account-access-text-info {
  text-align: center;
  font-size: var(--font-size-two);
}
</style>