<template>
  <form
    autocomplete="off"
    class="login-box medium-4 column align-self-middle"
    @submit.prevent="submit()"
  >
    <div class="flex-end">
      <woot-button
        type="button"
        variant="clear"
        color-scheme="secondary"
        size="small"
        icon="power"
        @click="logout"
      >
        Sair
      </woot-button>
    </div>
    <h4>Nos ajude a proteger sua conta</h4>
    <p>
      Para maior segurança, você precisará verificar sua identidade. Enviamos um
      código de verificação para {{ email }}
    </p>
    <div class="column log-in-form">
      <label :class="{ error: $v.otp_code.$error }">
        Código de verificação
        <input
          v-model.trim="otp_code"
          accept="numbers"
          type="text"
          placeholder="Código de verificação"
          @input="$v.otp_code.$touch"
        />
        <span v-if="$v.otp_code.$error" class="message">
          Por favor insira um código válido
        </span>
      </label>
      <woot-submit-button
        :disabled="$v.otp_code.$invalid || otpConfirmation.showLoading"
        button-text="Verificar código"
        :loading="otpConfirmation.showLoading"
        button-class="expanded"
      />
    </div>
    <div class="column margin-top-1 text-center">
      <woot-button
        type="button"
        size="small"
        variant="clear"
        color-scheme="secondary"
        @click="onResendCodeClick"
        >Reenviar código</woot-button
      >
    </div>
  </form>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric,
} from 'vuelidate/lib/validators';
import Auth from '../../api/auth';
import WootButton from '../../components/ui/WootButton.vue';
import { mapGetters } from 'vuex';

export default {
  components: { WootButton },
  data() {
    return {
      otp_code: null,
      otpConfirmation: {
        message: '',
        showLoading: false,
      },
      error: '',
    };
  },
  validations: {
    otp_code: {
      required,
      numeric,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    email() {
      return this.currentUser.email
        .replace(/(\w{2})\w*(@)/, '$1******$2')
        .replace(/(@\w{1})\w*/, '$1****');
    },
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.otpConfirmation.showLoading = false;
      bus.$emit('newToastMessage', message);
    },
    submit() {
      this.otpConfirmation.showLoading = true;
      Auth.otpConfirmation(Number(this.otp_code))
        .then(res => {
          let successMessage = 'Código de verificação confirmado com sucesso!';
          if (res.data && res.data.message) {
            successMessage = res.data.message;
          }
          this.showAlert(successMessage);
          this.$router.push({ name: 'home' });
        })
        .catch(error => {
          let errorMessage =
            'Código de verificação inválido. Tente reenviar o código novamente.';
          if (error?.response?.data?.message) {
            errorMessage = error.response.data.message;
          }
          this.showAlert(errorMessage);
        });
    },
    onResendCodeClick() {
      Auth.resendOtpCode();
      this.showAlert('Código de verificação reenviado');
    },
    logout() {
      Auth.logout();
    },
  },
};
</script>
