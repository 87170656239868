import BlockAccessUserAccount from './Index';
import Wrapper from "../settings/Wrapper";
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/block-access-account'),
    component: Wrapper,
    props: {
      headerTitle: 'BLOCK_ACCESS_USER_ACCOUNT.HEADER',
      icon: null,
      showSidemenuIcon: false
    },
    children: [
      {
        path: '',
        name: 'menu_block_access_account',
        component: BlockAccessUserAccount,
        roles: ['administrator', 'agent', 'seller', 'supervisor', 'analyzer', 'assistant', 'embed'],
      },
    ]
  },
]