<template>
  <div class="column">
    <woot-modal-header :header-title="$t('CADENCES.ADD.TITLE')" :header-content="$t('CADENCES.ADD.DESC')" />
    <form @submit.prevent="submit">
      <div class="row">
        <div class="medium-12 columns">
          <label :class="{ error: $v.cadences.name.$error }">
            {{ $t('CADENCES.ADD.FORM.NAME.LABEL') }}

            <input v-model.trim="cadences.name" type="text" minlength="3" maxlength="255"
              :placeholder="$t('CADENCES.ADD.FORM.NAME.PLACEHOLDER')" @blur="$v.cadences.name.$touch" />

            <span v-if="$v.cadences.name.$error" class="message">
              {{ $t('CADENCES.ADD.FORM.NAME.ERROR') }}
            </span>
          </label>
        </div>
      </div>

      <div class="row">
        <div v-for="(action, key) in cadences.actions" :key="action.key" class="medium-12 columns filters-wrap">
          <p><strong>Mensagem - {{ key + 1 }}</strong></p>
          <label>
            {{ $t('CADENCES.ADD.FORM.INBOX.LABEL') }}
            <select v-model="cadences.actions[key].inbox_id">
              <option v-for="item in inboxes" :key="item.name" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </label>

          <templates-picker
             v-if="!cadences.actions[key].template"
            :inbox-id="cadences.actions[key].inbox_id"
            :with-attachments="false"
            @onSelect="onSelectTemplate($event, key)"
          />

          <template-parser 
            ref="templateParsers"
            v-if="cadences.actions[key].template"
            type="cadence"
            :template="cadences.actions[key].template"
            @onBlur="onBlurParameter($event, key)"
          />

          <div class="cadence-actions">
            <woot-button 
              v-if="key === cadences.actions.length - 1"
              @click="addAction"
              icon="add"
              color-scheme="success"
              variant="smooth"
              size="small">
                {{ $t('CADENCES.ADD.FORM.ADD_MESSAGE_BUTTON_TEXT') }}
            </woot-button>

            <div v-else></div>
            
            <div class="flex-end">
              <woot-button
                v-if="cadences.actions[key].template"
                @click.prevent="cadences.actions[key].template = null"
                icon="edit"
                color-scheme="danger"
                variant="smooth"
                size="small">
                  {{ $t('CADENCES.ADD.FORM.CHANGE_TEMPLATE_BUTTON_TEXT') }}
              </woot-button>

              <woot-button
                v-if="cadences.actions.length > 1" 
                @click.prevent="cadences.actions.splice(key, 1)"
                icon="delete"
                color-scheme="secondary"
                variant="smooth"
                size="small">
                  {{ $t('CADENCES.ADD.FORM.REMOVE_MESSAGE_BUTTON_TEXT') }}
              </woot-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-end">
        <woot-button type="submit" class="button primary" :disabled="$v.$invalid">
          {{ $t('CADENCES.ADD.FORM.SUBMIT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  components: {
    TemplatesPicker,
    TemplateParser,
  },
  data() {
    return {
      cadences: {
        name: '',
        actions: []
      },
      selectedInbox: null,
    };
  },
  validations() {
    return {
      cadences: {
        name: { required, minLength: minLength(3), maxLength: maxLength(255) },
        actions: {
          required, 
          $each: {
            inbox_id: { required },
            template: {
              required,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'cadences/getUIFlags',
    }),
    inboxes() {
      return this.$store.getters['inboxes/getWhatsAppInboxes'];
    },
  },
  methods: {
    ...mapActions('cadences', ['create']),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      let hasError = false;
      this.$refs.templateParsers.forEach(templateParser => {
        if (templateParser.$v) {
          templateParser.$v.$touch();
          if (templateParser.$v.$invalid) {
            hasError = true;
          }
        }
      });

      if (hasError) return;
   
      try {

        let cadences = {
          name: this.cadences.name,
          url: this.cadences.url,
          actions: this.cadences.actions.map((action) => {
            return {
              inbox_id: action.inbox_id,
              message: this.buildMessage(action),
              parameters: action.parameters,
            };
          })
        };

        await this.create({ cadences: cadences });

        this.showAlert(this.$t('CADENCES.ADD.API.SUCCESS_MESSAGE'));
        this.$emit('on-created');
      } catch (error) {
        this.showAlert(this.$t('CADENCES.ADD.API.ERROR_MESSAGE'));
      } finally {
        this.onClose();
      }
    },
    onClose() {
      this.$emit('on-close');
    },
    addAction(event) {
      event && event.preventDefault();
      this.cadences.actions.push({
        key: Date.now() + Math.random().toString(36).substring(2, 11),
        inbox_id: null,
        template: null,
        parameters: [],
      });
    },
    onSelectTemplate(template, index) {
      this.cadences.actions[index].template = template;
    },
    onBlurParameter(params, index) {
      this.cadences.actions[index].parameters = Object.values(params);
    },
    resetTemplate(index) {
      this.cadences.actions[index].template = null;
    },
    generateName() {
      return `${this.$t('CADENCES.ADD.FORM.NAME.CADENCE')} - ${new Date().getTime()}`;
    },
    buildMessage(action) {
      let message = action.template.components[0].text;
      action.parameters.forEach((param, index) => {
        message = message.replace(`{{${index + 1}}}`, param);
      });
      return message;
    },
  },
  mounted() {
    this.cadences.name = this.generateName();
    this.addAction();
  },
};
</script>

<style lang="scss" scoped>
.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-border);
  margin-bottom: var(--space-normal);

  .cadence-actions {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
}


.filter-actions {
  margin-top: var(--space-normal);
}

.event_wrapper {
  select {
    margin: var(--space-zero);
  }

  .info-message {
    font-size: var(--font-size-mini);
    color: var(--s-500);
    text-align: right;
  }

  margin-bottom: var(--space-medium);
}
</style>