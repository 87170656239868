import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import WhatsappTemplateAPI from '../../api/whatsappTemplate';

export const state = {
  records: [],
  meta: {},
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isSyncing: false
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getTemplates(_state) {
    return _state.records;
  },
  getMeta(_state) {
    return _state.meta;
  }
};

export const actions = {
  get: async ({ commit }, params) => {
    commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: true });
    commit(types.SET_META, {});

    try {
      const { channel, page, sortAttr } = params;
      const response = await WhatsappTemplateAPI.get(channel, page, sortAttr);

      commit(types.SET_TEMPLATES, response.data.payload?.templates);
      commit(types.SET_META, response.data.meta);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, params) => {
    commit(types.SET_TEMPLATE_UI_FLAG, { isCreating: true });

    try {
      const { channel, template } = params;
      const response = await WhatsappTemplateAPI.create(template, channel);

      commit(types.ADD_TEMPLATE, response.data.message);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isCreating: false });
    }
  },
  delete: async ({ commit }, params) => {
    commit(types.SET_TEMPLATE_UI_FLAG, { isDeleting: true });

    try {
      const { channel, template_name } = params;

      await WhatsappTemplateAPI.delete(template_name, channel);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isDeleting: false });
    }
  },
  search: async ({ commit }, params) => {
    commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: true });
    commit(types.SET_META, {});

    try {
      const { channel, page, sortAttr, search } = params;
      const response = await WhatsappTemplateAPI.search(channel, page, sortAttr, search);

      commit(types.SET_TEMPLATES, response.data.payload?.templates);
      commit(types.SET_META, response.data.meta);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: false });
    }
  },
  sync: async ({ commit }, params) => {
    commit(types.SET_TEMPLATE_UI_FLAG, { isSyncing: true });

    try {
      const { channel } = params;
      await WhatsappTemplateAPI.sync(channel);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isSyncing: false });
    }
  },
};

export const mutations = {
  [types.SET_TEMPLATE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_META](_state, data) {
    _state.meta = data;
  },

  [types.ADD_TEMPLATE]: MutationHelpers.create,
  [types.SET_TEMPLATES]: MutationHelpers.set,
  [types.DELETE_TEMPLATE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
