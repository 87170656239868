import ApiClient from './ApiClient';

export const buildCampaignParams = (page, sortAttr, search) => {
  let params = `type=whatsapp&page=${page}&sort=${sortAttr}`;
  if (search) {
    params = `${params}&q=${search}`;
  }

  return params;
};

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  getWhatsapp(page, sortAttr = 'title') {
    let requestURL = `${this.url}?${buildCampaignParams(
      page,
      sortAttr,
      ''
    )}`;
    return axios.get(requestURL);
  }

  search(page = 1, sortAttr = 'title', search = '') {
    let requestURL = `${this.url}/search?${buildCampaignParams(
      page,
      sortAttr,
      search
    )}`;
    return axios.get(requestURL);
  }
}

export default new CampaignsAPI();
