<template>
  <superset class="column" supersetId="a45de103-058c-4173-95a7-59eb7097c979" />
</template>

<script>
import Superset from './Superset.vue';
export default {
  components: {
    Superset,
  },
};
</script>