import ApiClient from './ApiClient';

class InboxClosingReasons extends ApiClient {
  constructor() {
    super('inbox_closing_reasons', { accountScoped: true });
  }

  update({ inboxId, closingReasons }) {
    return axios.patch(this.url, {
      inbox_id: inboxId,
      closing_reasons_ids: closingReasons,
    });
  }
}

export default new InboxClosingReasons();
