<template>
  <form class="row" @submit.prevent="createChannel()">
    <div class="medium-8 columns">
      <label :class="{ error: $v.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
        <input
          v-model.trim="inboxName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="$v.inboxName.$touch"
        />
        <span v-if="$v.inboxName.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
        </span>
      </label>
    </div>

    <phone-number
      class="medium-8 columns"
      label="Número de telefone para notificar em caso de desconexão"
      :onPhoneNumber="onSetPhoneNumber"
    />

    <div class="medium-12 columns">
      <woot-submit-button
        :disabled="submiting"
        :loading="submiting || uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
      />
    </div>
    <woot-modal
      :show.sync="showQRCodeModal"
      :on-close="addAgents"
      size="medium"
    >
      <div class="qrcode">
        <div class="qrcode_desc">
          <div class="qrcode_desc_guia">
            <h1>Use seu WhatApp no Converx</h1>
            <ul>
              <li>1. Abra o WhatsApp no seu celular.</li>
              <li>
                2. Toque em "Mais opções" ou "Configurações" e selecione
                Aparelhos conectados.
              </li>
              <li>3. Toque em Conectar um aparelho.</li>
              <li>
                4. Aponte seu celular para esta tela para capturar o código QR.
              </li>
            </ul>
          </div>
          <div class="qrcode_conected" v-if="status == 'CONNECTED'">
            <div class="qrcode_conected_icon">
              <fluent-icon icon="checkmark" />
            </div>
            <div class="qrcode_conected_legend">Conectado</div>
          </div>
          <img v-else :src="qrcode" :alt="sessionName" />
        </div>
        <woot-button
          type="button"
          size="small"
          color-scheme="primary"
          @click="addAgents"
        >
          {{ $t('INBOX_MGMT.AGENTS.BUTTON_TEXT') }}
        </woot-button>
      </div>
    </woot-modal>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import WppConnect from 'dashboard/api/wppconnect';
import getUuid from 'widget/helpers/uuid';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';
import PhoneNumber from 'dashboard/components/PhoneNumber';

export default {
  components: {
    PhoneNumber,
  },
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      sessionName: getUuid(),
      passwordLink: getUuid(),
      qrcode: null,
      status: null,
      channel: null,
      submiting: false,
      sessionInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      currentUser: 'getCurrentUser',
      currentAccountId: 'getCurrentAccountId',
    }),
    showQRCodeModal() {
      return !!this.qrcode;
    },
  },
  validations: {
    inboxName: { required },
    phoneNumber: { isPhoneE164OrEmpty },
  },
  mounted() {
    this.sessionName = `${this.currentAccountId}-${this.sessionName}`;
  },
  methods: {
    onSetPhoneNumber(phone_number) {
      this.phoneNumber = phone_number;
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid || this.submiting) {
        return;
      }
      this.submiting = true;
      this.showAlert('Gerando acesso ao WPPConnect...');
      await WppConnect.generateToken(this.sessionName);

      this.showAlert('Criando caixa de entrada...');
      const webhook_url = WppConnect.getWebhookUrl();
      this.channel = await this.$store.dispatch('inboxes/createChannel', {
        name: this.inboxName,
        channel: {
          type: 'api',
          webhook_url,
          additional_attributes: {
            provider: 'wppconnect',
            token: WppConnect.getToken(),
            session: WppConnect.getSession(),
            phoneNumber: this.phoneNumber,
            passwordLink: this.passwordLink,
          },
        },
      });

      this.showAlert('Iniciando sessão...');
      await WppConnect.startSession({
        webhook: null,
        waitQrCode: true,
        chatWoot: {
          enable: true,
          baseURL: window.origin,
          token: this.currentUser.access_token,
          inbox_id: this.channel.id,
          account_id: this.currentUser.account_id,
        },
      });

      this.showAlert('Obtendo QR Code...');
      this.qrcode = WppConnect.getQRCode();
      this.submiting = false;
      this.sessionInterval = setInterval(
        () => this.handleWPPConnectStatus(),
        5000
      );
    },
    async handleWPPConnectStatus() {
      const { qrcode, status } = await WppConnect.statusSession();
      this.qrcode = qrcode;
      this.status = status;
    },
    addAgents() {
      router.replace({
        name: 'settings_inboxes_add_agents',
        params: {
          page: 'new',
          inbox_id: this.channel.id,
        },
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.sessionInterval);
  },
};
</script>
<style lang="scss" scoped>
.qrcode {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 25px 0;
  .qrcode_desc {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    .qrcode_desc_guia {
      margin-right: 15px;
      > h1 {
        font-size: 2rem;
        margin-bottom: 30px;
        margin-top: 30px;
      }
      > ul {
        list-style: none;
        font-size: 1.2rem;
        padding: 0;
        margin: 0;
        li {
          margin-bottom: 5px;
        }
      }
    }
    > img {
      max-width: 250px;
    }
    .qrcode_conected {
      width: 150px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      color: #39ae53;
      font-size: 2rem;
      font-weight: 600;
      .qrcode_conected_icon {
        height: 100px;
        background: #e5e7eb;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 10px;
        > svg {
          zoom: 3;
        }
      }
    }
  }
}
</style>