<template>
  <div className="column content-box no-padding">
    <div className="row">
      <div className="small-12">
        <iframe
          title="sleekplan"
          src="https://converxapp.sleekplan.app/"
          className="full-height full-width"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'iFrameFeedback',
};
</script>

<style scoped>
.no-padding {
  padding: 0 !important;
}

.full-height {
  height: calc(100vh - 56px);
}

.full-width {
  width: 100%;
}
</style>
