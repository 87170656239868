<template>
  <div class="right-aligned-wrap">
    <div class="search-wrap">
      <fluent-icon icon="search" class="search-icon" />

      <input
        type="text"
        :placeholder="$t('CADENCES.SEARCH.INPUT_PLACEHOLDER')"
        class="content-search"
        :value="searchQuery"
        @keyup.enter="$emit('on-search-submit')"
        @input="onInputSearch"
      />

      <woot-button
        :is-loading="false"
        class="clear"
        :class-names="searchButtonClass"
        @click="$emit('on-search-submit')"
      >
        {{ $t('CADENCES.SEARCH.BUTTON') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
    onInputSearch: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrap {
  max-width: 400px;
  min-width: 150px;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: var(--space-small);
  margin-left: var(--space-small);
  margin-bottom: var(--space-large);
  margin-top: var(--space-medium);

  .search-icon {
    position: absolute;
    top: 1px;
    left: var(--space-one);
    height: 3.8rem;
    line-height: 3.6rem;
    font-size: var(--font-size-medium);
    color: var(--b-700);
  }
  .content-search {
    margin: 0;
    height: 3.8rem;
    width: 100%;
    padding-left: var(--space-large);
    padding-right: 6rem;
    border-color: var(--s-100);
  }

  .button {
    margin-left: var(--space-small);
    height: 3.2rem;
    right: var(--space-smaller);
    position: absolute;
    padding: 0 var(--space-small);
    transition: transform 100ms linear;
    opacity: 0;
    transform: translateX(-1px);
    visibility: hidden;
  }

  .button.show {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
</style>