import Cookies from 'js-cookie';
import types from '../mutation-types';
import { setCookieWithDomain } from '../../../sdk/cookieHelpers';

const TOKEN = 'cx_observer_mode';

export const state = {
  status: Cookies.getJSON(TOKEN)
}

export const getters = {
  isObserverMode($state) {
    return $state.status;
  },
};

export const actions = {
  setMode: ({ commit }, value) => {
    commit(types.SET_OBSERVER_MODE, value);
    setCookieWithDomain(TOKEN, value);
  }
};

export const mutations = {
  [types.SET_OBSERVER_MODE]($state, data) {
    $state.status = data;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
