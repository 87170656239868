<template>
  <div v-if="template">
    <div class="content-template" v-if="getTemplateHeader">
      <strong>{{ $t('WHATSAPP_TEMPLATES.PARSER.HEADER') }}</strong>

      <p v-if="getTemplateHeader.text" class="label-body">
        {{ getTemplateHeader.text }}
      </p>

      <div v-else>
        <fluent-icon :icon="getIconTemplateHeader" size="20" />
      </div>
    </div>

    <div class="content-template">
      <strong>{{ $t('WHATSAPP_TEMPLATES.PARSER.BODY') }}</strong>

      <p class="label-body">{{ getTemplateBody }}</p>
    </div>

    <div v-if="getTemplateFooter">
      <strong>{{ $t('WHATSAPP_TEMPLATES.PARSER.FOOTER') }}</strong>

      <p class="label-body">{{ getTemplateFooter }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    template: {
      type: Object,
      default: {},
    },
  },
  computed: {
    getIconTemplateHeader() {
      const { format } = this.getTemplateHeader;

      switch (format) {
        case 'IMAGE':
          return 'image';
        case 'DOCUMENT':
          return 'document';
        case 'VIDEO':
          return 'video-add';
      }
    },
    getTemplateHeader() {
      return this.template.components.find(({ type }) => type === 'HEADER');
    },
    getTemplateBody() {
      return this.template.components.find(({ type }) => type === 'BODY').text;
    },
    getTemplateFooter() {
      return this.template.components.find(({ type }) => type === 'FOOTER')
        ?.text;
    },
  },
};
</script>

<style scoped lang="scss">
.content-template {
  margin-bottom: var(--space-one);
}

.label-body {
  font-family: monospace;
}
</style>