<template>
  <div class="settings--content">
    <form class="row" @submit.prevent="submit">
      <div class="medium-12 columns">
        <label :class="{ error: $v.status.$error }">
          {{ $t('CLOSING_REASONS.ADD.FORM.STATUS.LABEL') }}

          <select v-model="status" style="width: 25%">
            <option
              v-for="item in getStatus"
              :key="item.label"
              :value="item.key"
            >
              {{ item.label }}
            </option>
          </select>

          <span v-if="$v.status.$error" class="message">
            {{ $t('CLOSING_REASONS.ADD.FORM.STATUS.ERROR') }}
          </span>
        </label>

        <p>{{ $t('CLOSING_REASONS.ADD.FORM.DESCRIPTION_1') }}</p>

        <p>{{ $t('CLOSING_REASONS.ADD.FORM.DESCRIPTION_2') }}</p>

        <label
          class="multiselect-wrap--small"
          :class="{ error: $v.selectedReasons.$error }"
        >
          {{ $t('CLOSING_REASONS.ADD.FORM.REASONS.LABEL') }}
          <multiselect
            v-model="selectedReasons"
            :options="getClosingReasons"
            track-by="id"
            label="name"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CLOSING_REASONS.ADD.FORM.REASONS.PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @blur="$v.selectedReasons.$touch"
            @select="$v.selectedReasons.$touch"
            style="width: 50%"
          />
          <span v-if="$v.selectedReasons.$error" class="message">
            {{ $t('CLOSING_REASONS.ADD.FORM.REASONS.ERROR') }}
          </span>
        </label>

        <woot-button :is-loading="uiFlags.isFetching">
          {{ $t('CLOSING_REASONS.ADD.FORM.CREATE_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { required, requiredIf } from 'vuelidate/lib/validators';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {},
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      status: 0,
      selectedReasons: [],
    };
  },
  validations() {
    return {
      status: { required },
      selectedReasons: {
        required: requiredIf(function () {
          return this.status;
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      getConfigInbox: 'inboxes/getInbox',
      getClosingReasons: 'closingReasons/getClosingReasons',
      getInboxOfClosingReasons: 'inboxClosingReasons/getInboxOfClosingReasons',
      uiFlags: 'inboxClosingReasons/getUIFlags',
    }),
    getStatus() {
      return [
        { key: 0, label: this.$t('CLOSING_REASONS.ADD.FORM.STATUS.DISABLED') },
        { key: 1, label: this.$t('CLOSING_REASONS.ADD.FORM.STATUS.ENABLED') },
      ];
    },
  },
  methods: {
    ...mapActions('closingReasons', ['get']),
    ...mapActions('inboxClosingReasons', ['fetch', 'update']),
    ...mapActions('inboxes', ['updateInbox']),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      try {
        const payload = {
          id: this.inbox.id,
          enable_closing_reasons: this.status,
        };

        const data = {
          closingReasons: this.selectedReasons.map(({ id }) => id),
          inboxId: this.inbox.id,
        };

        await this.updateInbox(payload);
        await this.update(data);

        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(error.message);
      }
    },
    async getData() {
      try {
        const { id: inboxId } = this.inbox;

        await this.get({ page: 1, limit: 100 });
        await this.fetch(inboxId);

        if (this.getInboxOfClosingReasons.length) {
          this.selectedReasons = this.getInboxOfClosingReasons;
        }

        const inbox = this.getConfigInbox(inboxId);
        this.status = Number(inbox.enable_closing_reasons);
      } catch (error) {
        this.showAlert(error.message);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.table-actions-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message {
  margin-top: var(--space-micro);

  &.custom-error {
    position: absolute;
    margin-top: var(--space-jumbo);
    left: var(--space-large);
    width: 50%;
  }
}
</style>