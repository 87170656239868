import SettingsContent from '../Wrapper';
import ClosingReasonsHome from './Index';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/closing-reasons'),
      component: SettingsContent,
      props: {
        headerTitle: 'CLOSING_REASONS.HEADER',
        icon: 'chat-off',
        showNewButton: true,
      },
      children: [
        {
          path: '',
          name: 'closingreasons_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'closingreasons_list',
          roles: ['administrator'],
          component: ClosingReasonsHome,
        },
      ],
    },
  ],
};
