<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
    />
    <div class="medium-8 columns">
      <label>
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
        <select v-model="provider">
          <option value="360dialog">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG') }}
          </option>
          <option value="360_cloud">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG_CLOUD') }}
          </option>
          <option value="whatsapp_web">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_WEB') }}
          </option>
          <option value="infobip">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.INFOBIP') }}
          </option>
        </select>
      </label>
    </div>

    <wpp-connect v-if="provider === 'wppconnect'" />
    <whatsapp-web v-else-if="provider === 'whatsapp_web'" />
    <infobip-whatsapp v-else-if="provider === 'infobip'" />
    <three-sixty-dialog-cloud-whatsapp v-else-if="provider === '360_cloud'" />
    <three-sixty-dialog-whatsapp v-else />
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import Twilio from './Twilio';
import ThreeSixtyDialogWhatsapp from './360DialogWhatsapp';
import ThreeSixtyDialogCloudWhatsapp from './360DialogCloudWhatsapp';
import CloudWhatsapp from './CloudWhatsapp';
import WppConnect from './WppConnect';
import WhatsappWeb from './WhatsappWeb';
import InfobipWhatsapp from './InfobipWhatsapp';

export default {
  components: {
    PageHeader,
    Twilio,
    ThreeSixtyDialogWhatsapp,
    ThreeSixtyDialogCloudWhatsapp,
    CloudWhatsapp,
    WppConnect,
    WhatsappWeb,
    InfobipWhatsapp,
  },
  data() {
    return {
      provider: '360_cloud',
    };
  },
  mounted() {
    if (this.has360dialogCloudRedirect()) {
      this.provider = '360_cloud';
    }
  },
  methods: {
    has360dialogCloudRedirect() {
      const params = new URLSearchParams(window.location.search);
      return params.get('client') && params.get('channels');
    },
  },
};
</script>
