<template>
  <div class="contact--profile">
    <div class="contact--info">
      <div class="contact-info--header">
        <div class="contact-info--thumbnail">
          <thumbnail
            v-if="showAvatar"
            :src="contact.thumbnail"
            size="56px"
            :username="contact.name"
            :status="contact.availability_status"
          />
        </div>
        <woot-button
          v-if="showCloseButton"
          :icon="closeIconName"
          class="clear secondary close-button--rtl"
          @click="onPanelToggle"
        />
      </div>

      <div class="contact--details">
        <div v-if="showAvatar" class="contact--name-wrap">
          <h3 class="sub-block-title contact--name">
            {{ contact.name }}
          </h3>
        </div>

        <p v-if="additionalAttributes.description" class="contact--bio">
          {{ additionalAttributes.description }}
        </p>

        <social-icons :social-profiles="socialProfiles" />
      </div>

      <div class="contact-actions">
        <woot-button
          v-tooltip="$t('CONTACT_PANEL.NEW_MESSAGE')"
          title="$t('CONTACT_PANEL.NEW_MESSAGE')"
          class="new-message"
          icon="chat"
          size="small"
          @click="toggleConversationModal"
        />
        <woot-button
          v-tooltip="$t('EDIT_CONTACT.BUTTON_LABEL')"
          title="$t('EDIT_CONTACT.BUTTON_LABEL')"
          class="edit-contact"
          icon="edit"
          variant="smooth"
          size="small"
          @click="toggleEditModal"
        />
        <woot-button
          v-tooltip="$t('CONTACT_PANEL.MERGE_CONTACT')"
          title="$t('CONTACT_PANEL.MERGE_CONTACT')"
          class="merge-contact"
          icon="merge"
          variant="smooth"
          size="small"
          :disabled="uiFlags.isMerging"
          @click="openMergeModal"
        />
        <a
          :href="contactProfileLink"
          class="fs-default"
          target="_blank"
          rel="noopener nofollow noreferrer"
          v-tooltip="$t('CONTACTS_PAGE.LIST.VIEW_DETAILS')"
        >
          <woot-button
            class="edit-contact"
            icon="open"
            variant="smooth"
            size="small"
          />
        </a>
        <woot-button
          v-if="isAdmin"
          v-tooltip="$t('DELETE_CONTACT.BUTTON_LABEL')"
          title="$t('DELETE_CONTACT.BUTTON_LABEL')"
          class="delete-contact"
          icon="delete"
          variant="smooth"
          size="small"
          :disabled="uiFlags.isDeleting"
          @click="toggleDeleteModal"
        />
      </div>

      <div class="contact--metadata">
        <contact-info-row
          :href="contact.email ? `mailto:${contact.email}` : ''"
          :value="contact.email"
          :title="$t('CONTACT_PANEL.EMAIL_ADDRESS')"
        />
        <contact-info-row
          :href="
            contact.phone_number
              ? `tel:${contact.phone_number}`
              : parseNumber(contact.phone_number)
          "
          :value="parseNumber(contact.phone_number)"
          :title="$t('CONTACT_PANEL.PHONE_NUMBER')"
        />
        <contact-info-row
          v-if="contact.identifier"
          :value="contact.identifier"
          :title="$t('CONTACT_PANEL.IDENTIFIER')"
        />
        <contact-info-row
          v-if="additionalAttributes.company_name"
          :value="additionalAttributes.company_name"
          :title="$t('CONTACT_PANEL.COMPANY')"
        />
        <contact-info-row
          v-if="location || additionalAttributes.location"
          :value="location || additionalAttributes.location"
          :title="$t('CONTACT_PANEL.LOCATION')"
        />

        <p class="created-at">
          {{ $t('CONTACT_PANEL.CREATED_AT_LABEL') }}
          {{ dynamicTime(contact.created_at) }}
        </p>
      </div>

      <edit-contact
        v-if="showEditModal"
        :show="showEditModal"
        :contact="contact"
        @cancel="toggleEditModal"
      />

      <new-conversation
        v-if="contact.id"
        :show="showConversationModal"
        :contact="contact"
        @cancel="toggleConversationModal"
      />

      <contact-merge-modal
        v-if="showMergeModal"
        :primary-contact="contact"
        :show="showMergeModal"
        @close="toggleMergeModal"
      />
    </div>

    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="confirmDeleteMessage"
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import timeMixin from 'dashboard/mixins/time';
import ContactInfoRow from './ContactInfoRow';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import SocialIcons from './SocialIcons';

import EditContact from './EditContact';
import NewConversation from './NewConversation';
import ContactMergeModal from 'dashboard/modules/contact/ContactMergeModal';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import agentMixin from 'dashboard/mixins/agentMixin';
import { mapGetters } from 'vuex';
import { getCountryFlag } from 'dashboard/helper/flag';

export default {
  components: {
    ContactInfoRow,
    EditContact,
    Thumbnail,
    SocialIcons,
    NewConversation,
    ContactMergeModal,
  },
  mixins: [agentMixin, alertMixin, adminMixin, clickaway, timeMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    channelType: {
      type: String,
      default: '',
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    closeIconName: {
      type: String,
      default: 'dismiss',
    },
  },
  data() {
    return {
      showEditModal: false,
      showConversationModal: false,
      showMergeModal: false,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'contacts/getUIFlags' }),
    contactProfileLink() {
      return `/app/accounts/${this.$route.params.accountId}/contacts/${this.contact.id}`;
    },
    additionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    location() {
      const {
        country = '',
        city = '',
        country_code: countryCode,
      } = this.additionalAttributes;
      const cityAndCountry = [city, country].filter(item => !!item).join(', ');

      if (!cityAndCountry) {
        return '';
      }
      return this.findCountryFlag(countryCode, cityAndCountry);
    },
    socialProfiles() {
      const {
        social_profiles: socialProfiles,
        screen_name: twitterScreenName,
      } = this.additionalAttributes;

      return { twitter: twitterScreenName, ...(socialProfiles || {}) };
    },
    // Delete Modal
    confirmDeleteMessage() {
      return ` ${this.contact.name}?`;
    },
  },
  methods: {
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    onPanelToggle() {
      this.$emit('toggle-panel');
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    confirmDeletion() {
      this.deleteContact(this.contact);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
      this.showConversationModal = false;
      this.showEditModal = false;
    },
    findCountryFlag(countryCode, cityAndCountry) {
      try {
        const countryFlag = countryCode ? getCountryFlag(countryCode) : '🌎';
        return `${cityAndCountry} ${countryFlag}`;
      } catch (error) {
        return '';
      }
    },
    async deleteContact({ id }) {
      try {
        await this.$store.dispatch('contacts/delete', id);
        this.$emit('panel-close');
        this.showAlert(this.$t('DELETE_CONTACT.API.SUCCESS_MESSAGE'));
        if (this.$route.name !== 'contacts_dashboard') {
          this.$router.push({ name: 'contacts_dashboard' });
        }
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_CONTACT.API.ERROR_MESSAGE')
        );
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
    parseNumber(number) {
      if (
        number &&
        !number.includes('tel') &&
        this.agentRules?.mask_number === false
      ) {
        const sub = number.substring(5, number.length - 4);
        return number.replace(sub, '*'.repeat(sub.length)) || '';
      }

      return number || '';
    },
  },
  mounted() {
    if (!this.agentList.length) {
      this.$store.dispatch('agents/get');
    }
  },
};
</script>

<style scoped lang="scss">
.contact--profile {
  position: relative;
  align-items: flex-start;
}

.contact--details {
  margin-top: var(--space-medium);
  width: 100%;
  padding: var(--space-normal);
}

.contact--info {
  text-align: left;
}

.contact-info--header {
  background-color: #053ca8;
  height: var(--space-larger);

  .contact-info--thumbnail {
    justify-content: center;
    display: flex;

    .user-thumbnail-box {
      margin-top: var(--space-two);
    }
  }

  .close-button--rtl {
    top: var(--space-micro);
    right: var(--space-micro);
    position: absolute;
    color: var(--white);

    &:hover {
      background-color: transparent;
      color: var(--white-transparent);
    }
  }
}

.contact--name-wrap {
  text-align: center;
  margin-bottom: var(--space-small);
}

.contact--name {
  text-transform: capitalize;
  white-space: normal;
  margin: 0 var(--space-smaller) 0 var(--space-smaller);

  a {
    color: var(--color-body);
  }
}

.contact--metadata {
  text-align: center;
  margin-top: var(--space-two);

  .created-at {
    color: var(--b-500);
    margin-top: var(--space-small);
    font-size: var(--font-size-micro);
    font-weight: var(--font-weight-normal);
  }
}

.contact-actions {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;

  .new-message,
  .edit-contact,
  .merge-contact,
  .delete-contact {
    margin-right: var(--space-small);
  }
}
.merge-summary--card {
  padding: var(--space-normal);
}

.contact--bio {
  word-wrap: break-word;
}

.button--contact-menu {
  position: absolute;
  right: var(--space-normal);
  top: 0;
}
</style>
