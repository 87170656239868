<template>
  <div class="custom-attribute-agent">
    <h1 class="title">{{ agent.name }} | Atributos personalizados</h1>
    <div class="body">
      <div class="list-attributes">
        <div
          class="item-attribute"
          v-for="(attribute, index) in attributes"
          :key="attribute.id"
        >
          <div>
            <woot-input
              v-model.trim="attribute.name"
              :class="{ error: attribute.error.name }"
              class="medium-12 columns"
              :error="attribute.error.name"
              :label="$t('AGENT_MGMT.ATTRIBUTE.FORM.NAME.LABEL')"
              :placeholder="$t('AGENT_MGMT.ATTRIBUTE.FORM.NAME.PLACEHOLDER')"
            />
          </div>
          <div>
            <woot-input
              v-model.trim="attribute.value"
              :class="{ error: attribute.error.value }"
              class="medium-12 columns"
              :error="attribute.error.value"
              :label="$t('AGENT_MGMT.ATTRIBUTE.FORM.VALUE.LABEL')"
              :placeholder="$t('AGENT_MGMT.ATTRIBUTE.FORM.VALUE.PLACEHOLDER')"
            />
          </div>
          <div>
            <woot-button
              v-tooltip.top="$t('AGENT_MGMT.ATTRIBUTE.FORM.BUTTON_DELETE_TEXT')"
              variant="smooth"
              color-scheme="alert"
              size="tiny"
              icon="dismiss-circle"
              class-names="grey-btn"
              @click="openDeletePopup(attribute, index)"
            />
          </div>
        </div>
      </div>
      <div>
        <woot-button
          type="button"
          icon="add"
          variant="clear"
          @click="addAttribute"
        >
          {{ $t('AGENT_MGMT.ATTRIBUTE.FORM.BUTTON_ADD_TEXT') }}
        </woot-button>
      </div>
    </div>
    <div class="footer">
      <woot-button @click="editAgent" :disabled="!isValid">
        {{ $t('AGENT_MGMT.ATTRIBUTE.FORM.SUBMIT') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    agent: Object,
    onClose: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      attributes: [],
    };
  },
  mounted() {
    this.loadAttributes();
    if (!this.isEmptyAttributes) {
      this.addAttribute();
    }
  },
  computed: {
    totalAttributes() {
      return this.attributes.length;
    },
    isEmptyAttributes() {
      return !!this.totalAttributes;
    },
    customAttributes() {
      return this.attributes.map(attr => ({
        id: attr.id,
        name: attr.name,
        value: attr.value,
      }));
    },
    isValid() {
      const filtered = this.customAttributes.filter(
        attr => attr.name && attr.value
      );
      return filtered.length == this.totalAttributes;
    },
  },
  methods: {
    loadAttributes() {
      const attributes = this.agent?.custom_attributes || [];
      this.attributes = attributes.map(attr => ({
        ...attr,
        error: {
          name: null,
          value: null,
        },
      }));
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    addAttribute() {
      this.attributes.push({
        id: new Date().getTime(),
        name: null,
        value: null,
        error: {
          name: null,
          value: null,
        },
      });
    },
    removeAttribute(id) {
      const idx = this.attributes.findIndex(attr => attr.id == id);
      if (idx == -1) return false;

      return !!this.attributes.splice(idx, 1);
    },
    openDeletePopup(attribute, index) {
      this.removeAttribute(attribute.id);
    },
    async editAgent() {
      try {
        const bodyAttributes = this.customAttributes.filter(
          attr => attr.name && attr.value
        );
        await this.$store.dispatch('agents/update', {
          id: this.agent.id,
          custom_attributes: bodyAttributes,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-attribute-agent {
  .title {
    font-size: 2rem;
    margin: 35px 15px 15px;
  }
  .body {
    position: absolute;
    bottom: 70px;
    top: 75px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 15px 15px;
    .list-attributes {
      .item-attribute {
        display: flex;
        justify-content: space-between;
        align-content: baseline;
        div {
          margin-left: 8px;
          &:first {
            margin-left: 0;
          }
          &:last-child {
            display: flex;
            flex-flow: column;
            justify-content: center;
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
</style>