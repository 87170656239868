<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.VOLL360_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.VOLL360_CHANNEL.DESC')"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.VOLL360_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.VOLL360_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.VOLL360_CHANNEL.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.domain.$error }">
          {{ $t('INBOX_MGMT.ADD.VOLL360_CHANNEL.DOMAIN.LABEL') }}
          <input
            v-model.trim="domain"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.VOLL360_CHANNEL.DOMAIN.PLACEHOLDER')
            "
            @blur="$v.domain.$touch"
          />
          <span v-if="$v.domain.$error" class="message">{{
            $t('INBOX_MGMT.ADD.VOLL360_CHANNEL.DOMAIN.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.apiKey.$error }">
          {{ $t('INBOX_MGMT.ADD.VOLL360_CHANNEL.API_KEY.LABEL') }}
          <input
            v-model.trim="apiKey"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.VOLL360_CHANNEL.API_KEY.PLACEHOLDER')
            "
            @blur="$v.apiKey.$touch"
          />
        </label>
        <p class="help-text">
          {{ $t('INBOX_MGMT.ADD.VOLL360_CHANNEL.API_KEY.SUBTITLE') }}
        </p>
      </div>

      <div class="columns medium-12">
        <button class="header-section back-button" @click.prevent="showCustomFilters = !showCustomFilters">
          Adicionar filtros
          <div class="chevron-icon__wrap">
            <fluent-icon v-if="showCustomFilters" icon="chevron-up" />
            <fluent-icon v-else icon="chevron-down" />
          </div>
        </button>
      </div>

      <div v-if="showCustomFilters" class="columns medium-12">
        <div class="medium-8 columns">
          <label :class="{ error: $v.number.$error }">
            Número
            <input
              v-model.trim="number"
              placeholder="+55DDD900000000"
              type="text"
              @blur="$v.number.$touch"
            />
            <span v-if="$v.number.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
            </span>
          </label>
          <p class="help-text">
            Armazenar apenas conversas desse numero.
          </p>
        </div>

        <div class="medium-8 columns">
          <label>
            Agentes (id, login ou nome)
            <multiselect
              ref="multiselect_incoming"
              v-model="agents"
              selectedLabel="Selecionado"
              tag-placeholder="Adicionar esse agente"
              placeholder="Adicionar agentes"
              label="name"
              track-by="id"
              deselect-label=""
              :max-height="160"
              :allow-empty="true"
              :option-height="104"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              :options="options"
              tag-position="bottom"
            >
              <span slot="noOptions">Lista vazia</span>
            </multiselect>
          </label>
          <p class="help-text">
            Considerar armazenar apenas conversas desses agentes. Deixe vazio caso queira armazenar todos.
          </p>
        </div>

        <div class="medium-8 columns">
          <label :class="{ error: $v.minMessages.$error }">
            Mínimo de Mensagens
            <input
              v-model.trim="minMessages"
              type="text"
              @blur="$v.minMessages.$touch"
            />
            <span v-if="$v.minMessages.$error" class="message">
              Informe uma quantidade valida
            </span>
          </label>
          <p class="help-text">
            Deve ter essa quantidade mínima de mensagens para poder armazenar as conversas.
          </p>
        </div>
      </div>

      <div class="divider">&nbsp;</div>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.VOLL360_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required, url, numeric } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import getUuid from 'widget/helpers/uuid';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';
import { watch } from 'vue';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      domain: '',
      apiKey: '',
      number: null,
      agents: [],
      options: [],
      minMessages: 10,
      showCustomFilters: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  watch: {
    showCustomFilters() {
      if (!this.showCustomFilters) this.cleanFilters();
    }
  },
  validations: {
    channelName: { required },
    domain: { required, url },
    apiKey: { required },
    number: { isPhoneE164OrEmpty },
    minMessages: { numeric },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const vollChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.channelName,
            channel: {
              type: 'voll',
              identifier: getUuid(),
              domain: this.domain,
              api_key: this.apiKey,
              custom_config: {
                number: this.number,
                agents: this.agents,
                min_messages: parseInt(this.minMessages),
              }
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: vollChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.VOLL360_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.agents.push(tag)
    },
    cleanFilters() {
      this.number = '';
      this.agents = [];
      this.options = [];
      this.minMessages = 10;
    }
  },
};
</script>

<style lang="scss" scoped>
.wizard-body {
  .divider {
    background: #b8b9bb;
    height: 1px;
    margin: 1.5rem 0;
    flex: 0 0 100%;
  }
}
</style>