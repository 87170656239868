import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import botsAPI from '../../api/bots';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isDeleting: false,
    isForbidden: false
  },
};

export const getters = {
  getBots($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_BOT_UI_FLAG, { isForbidden: false });
    commit(types.SET_BOT_UI_FLAG, { isFetching: true });
    try {
      const response = await botsAPI.get();
      commit(types.SET_BOTS, response.data.rows);
    } catch (error) {
      if (error.message.includes("401")) {
        commit(types.SET_BOT_UI_FLAG, { isForbidden: true });
      }
    } finally {
      commit(types.SET_BOT_UI_FLAG, { isFetching: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_BOT_UI_FLAG, { isDeleting: true });
    try {
      await botsAPI.delete(id);
      commit(types.DELETE_BOT, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_BOT_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_BOT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_BOTS]: MutationHelpers.set,
  [types.DELETE_BOT]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
