import Index from './Index';
import AgentReports from './AgentReports';
import LabelReports from './LabelReports';
import InboxReports from './InboxReports';
import TeamReports from './TeamReports';
import CsatResponses from './CsatResponses';
import LiveReports from './LiveReports';
import DashboardApps from '../../dashboard-apps/Index';
import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';
import Attendance from './superset/Attendance';
import AttendanceDetail from './superset/AttendanceDetail';
import AttendanceAgent from './superset/AttendanceAgent';
import Insights from './superset/Insights';
import Campaigns from './superset/Campaigns';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'OVERVIEW_REPORTS.HEADER',
        icon: 'arrow-trending-lines',
        keepAlive: false,
      },
      children: [
        {
          path: '',
          redirect: 'overview',
        },
        {
          path: 'overview',
          name: 'account_overview_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: LiveReports,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'REPORT.HEADER',
        icon: 'chat',
        keepAlive: false,
      },
      children: [
        {
          path: 'conversation',
          name: 'conversation_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'CSAT_REPORTS.HEADER',
        icon: 'emoji',
        keepAlive: false,
      },
      children: [
        {
          path: 'csat',
          name: 'csat_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: CsatResponses,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'AGENT_REPORTS.HEADER',
        icon: 'people',
        keepAlive: false,
      },
      children: [
        {
          path: 'agent',
          name: 'agent_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: AgentReports,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'LABEL_REPORTS.HEADER',
        icon: 'tag',
        keepAlive: false,
      },
      children: [
        {
          path: 'label',
          name: 'label_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: LabelReports,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'INBOX_REPORTS.HEADER',
        icon: 'mail-inbox-all',
        keepAlive: false,
      },
      children: [
        {
          path: 'inboxes',
          name: 'inbox_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: InboxReports,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'TEAM_REPORTS.HEADER',
        icon: 'people-team',
      },
      children: [
        {
          path: 'teams',
          name: 'team_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: TeamReports,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'Atendimentos',
        icon: 'chat',
      },
      children: [
        {
          path: 'attendances',
          name: 'attendance_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: Attendance,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'Atendimentos detalhado',
        icon: 'mail-inbox-all',
      },
      children: [
        {
          path: 'attendance-details',
          name: 'attendance_detail_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: AttendanceDetail,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'Atendimento por agente',
        icon: 'people',
      },
      children: [
        {
          path: 'attendance-agents',
          name: 'attendance_agent_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: AttendanceAgent,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'Insights',
        icon: 'tag',
      },
      children: [
        {
          path: 'insights',
          name: 'insights_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: Insights,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.HEADER',
        icon: 'megaphone',
        keepAlive: false,
      },
      children: [
        {
          path: 'campaigns',
          name: 'campaigns_reports',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: Campaigns,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'DASHBOARD_APPS.HEADER',
        icon: 'automation',
      },
      children: [
        {
          path: 'dashboard-apps',
          name: 'reports_dashboard_apps',
          roles: ['administrator', 'supervisor', 'analyzer', 'assistant'],
          component: DashboardApps,
        },
      ],
    }
  ],
};
