<template>
  <div>
    <div class="row profile--settings--access-timecontrol">
      <div class="columns">
        <label class="margin-top-2">
          <strong>{{
            $t(
              'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DAYS_WEEK.SECTION_TITLE'
            )
          }}</strong>
        </label>

        <fieldset
          class="margin-left-1 margin-top-1"
          v-for="(setup, index) in settings.week_days_hours_allow"
          :key="index"
        >
          <template v-if="settings.week_days_hours_allow && lang">
            <legend>
              {{
                $t(
                  'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DAYS_WEEK.PERIOD_NUMBER_TEXT'
                )
              }}
              {{ index + 1 }}
            </legend>
            <div class="row">
              <div class="columns small-10">
                <p>
                  <span
                    class="button tiny margin-right-2"
                    v-for="(name, weekDay) in lang.weekdays"
                    :key="weekDay"
                    :class="{
                      primary: setup.week_day.includes(weekDay + 1),
                      hollow: !setup.week_day.includes(weekDay + 1),
                    }"
                    @click="toggleWeekDay(index, weekDay + 1)"
                    v-tooltip.top="{
                      content: name,
                    }"
                  >
                    {{ name.substring(0, 1) }}
                  </span>
                </p>
                <p>
                  {{
                    $t(
                      'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DAYS_WEEK.PERIOD_FROM_TEXT'
                    )
                  }}
                  <date-picker
                    v-model="setup.start_hour"
                    :minute-step="5"
                    format="HH:mm"
                    value-type="format"
                    type="time"
                    placeholder="HH:mm"
                    class="display-inline-block margin-right-1"
                    @close="checkSelectHour(index, 'start_hour')"
                  ></date-picker>

                  {{
                    $t(
                      'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DAYS_WEEK.PERIOD_TO_TEXT'
                    )
                  }}
                  <date-picker
                    v-model="setup.end_hour"
                    :minute-step="5"
                    format="HH:mm"
                    value-type="format"
                    type="time"
                    placeholder="HH:mm"
                    class="display-inline-block margin-left-1"
                    @close="checkSelectHour(index, 'end_hour')"
                  ></date-picker>
                </p>
              </div>
              <div class="columns align-self-middle small-2">
                <woot-button
                  type="button"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  @click="removeWeekDays(index)"
                >
                  {{
                    $t(
                      'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DELETE_BUTTON_TEXT'
                    )
                  }}
                </woot-button>
              </div>
            </div>
          </template>
        </fieldset>
        <woot-button
          type="button"
          color-scheme="primary"
          size="small"
          icon="add-circle"
          class-names="margin-left-1 margin-top-1 float-right"
          @click="addWeekDays()"
        >
          {{
            settings.week_days_hours_allow &&
            settings.week_days_hours_allow.length > 0
              ? $t(
                  'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DAYS_WEEK.PERIOD_ADD_BUTTON_TEXT'
                )
              : $t(
                  'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DAYS_WEEK.PERIOD_INIT_CONFIG_BUTTON_TEXT'
                )
          }}
        </woot-button>
      </div>
    </div>
    <div class="row profile--settings--access-calendar">
      <div class="columns small-12 margin-top-3">
        <label class="margin-top-2"
          ><strong>{{
            $t(
              'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DAYS_DENNY.SECTION_TITLE'
            )
          }}</strong></label
        >
        <div class="margin-left-1">
          <div class="row">
            <div class="columns column-datepicker">
              <date-picker
                v-if="lang"
                type="date"
                :inline="true"
                @change="addDenyDay"
                :lang="lang"
              ></date-picker>
            </div>
            <div class="columns">
              <label>
                {{
                  $t(
                    'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DAYS_DENNY.DAYS_SELECT_SECTION'
                  )
                }}
              </label>
              <template
                v-if="settings.days_deny && settings.days_deny.length > 0"
              >
                <span
                  v-for="(date, index) in settings.days_deny"
                  :key="index"
                  class="margin-right-2 margin-bottom-2 display-inline-block"
                >
                  {{ formatDate(date) }}
                  <woot-button
                    type="button"
                    v-tooltip.top="
                      $t(
                        'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DELETE_BUTTON_TEXT'
                      )
                    "
                    variant="smooth"
                    color-scheme="alert"
                    size="tiny"
                    icon="dismiss-circle"
                    class-names="grey-btn"
                    @click="removeDenyDay(index)"
                  />
                </span>
              </template>
              <p v-else>
                {{
                  $t(
                    'SECURITY_POLICIES.FORM.ACCESS_TIME_POLICY.DAYS_DENNY.DAYS_HELP_UNSELECTED'
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import DatePicker from 'vue2-datepicker';
import langEn from 'date-format-parse/lib/locale/en';
import langPtBR from 'date-format-parse/lib/locale/pt-br';
import langEs from 'date-format-parse/lib/locale/es';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';

export default {
  components: { DatePicker },
  props: {
    settings: {
      type: Object,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lang: langEn,
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
    }),
    settingsStr() {
      return JSON.stringify(this.settings);
    },
  },
  watch: {
    settingsStr() {
      const settings = JSON.parse(JSON.stringify(this.settings));
      settings.week_days_hours_allow = settings.week_days_hours_allow.filter(
        item => {
          return (
            item.start_hour != null &&
            item.start_hour.trim() != '' &&
            item.end_hour != null &&
            item.end_hour.trim() != '' &&
            item.week_day.length > 0
          );
        }
      );

      delete settings.enable;
      this.$emit('updateSettings', settings);
    },
    locale() {
      this.lang = null;
      this.lang = this.getLang();
    },
  },
  created() {
    this.lang = this.getLang();
  },
  methods: {
    getLang() {
      const languages = {
        pt_BR: langPtBR,
        en: langEn,
        es: langEs,
      };
      return languages[this.locale];
    },
    addWeekDays() {
      this.settings.week_days_hours_allow.push({
        week_day: [],
        start_hour: '',
        end_hour: '',
      });
    },

    removeWeekDays(index) {
      this.settings.week_days_hours_allow.splice(index, 1);
    },

    checkSelectHour(index, attribute) {
      const dateStart = new Date();
      const dateEnd = new Date(dateStart.getTime());
      if (this.settings.week_days_hours_allow[index].start_hour != '') {
        dateStart.setHours(
          Number(
            this.settings.week_days_hours_allow[index].start_hour.split(':')[0]
          )
        );
        dateStart.setMinutes(
          Number(
            this.settings.week_days_hours_allow[index].start_hour.split(':')[1]
          )
        );
      }
      if (this.settings.week_days_hours_allow[index].end_hour != '') {
        dateEnd.setHours(
          Number(
            this.settings.week_days_hours_allow[index].end_hour.split(':')[0]
          )
        );
        dateEnd.setMinutes(
          Number(
            this.settings.week_days_hours_allow[index].end_hour.split(':')[1]
          )
        );
      }
      if (dateStart >= dateEnd) {
        this.settings.week_days_hours_allow[index][
          attribute == 'start_hour' ? 'end_hour' : 'start_hour'
        ] = '';
      }
    },

    toggleWeekDay(index, weekDayNumber) {
      const indexWeekDay =
        this.settings.week_days_hours_allow[index].week_day.indexOf(
          weekDayNumber
        );
      if (indexWeekDay < 0) {
        this.settings.week_days_hours_allow[index].week_day.push(weekDayNumber);
      } else {
        this.settings.week_days_hours_allow[index].week_day.splice(
          indexWeekDay,
          1
        );
      }
    },

    addDenyDay(date) {
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      let month = date.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      const newDate = `${date.getFullYear()}-${month}-${day}`;
      if (this.settings.days_deny.includes(newDate)) {
        return;
      }
      this.settings.days_deny.push(newDate);
    },

    removeDenyDay(index) {
      this.settings.days_deny.splice(index, 1);
    },

    formatDate(strDate) {
      const date_format = {
        pt_BR: 'MM/dd/yyyy',
        en: 'yyyy-MM-dd',
        es: 'MM/dd/yyyy',
      };
      return format(new Date(strDate), date_format[this.locale]);
    },
    weekDayName(weekDayNumber) {
      return this.lang.weekdays[weekDayNumber];
    },
  },
};
</script>
  
<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings--access-timecontrol {
  fieldset {
    border: 1px solid $color-border;
    border-radius: 3px;
    padding: 5px 15px 0px 15px;
  }

  .mx-datepicker {
    width: 120px;
  }
}

.profile--settings--access-calendar {
  .column-datepicker {
    min-width: 260px;
    max-width: 260px;
  }
}
</style>
  