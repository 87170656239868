<template>
  <main id="pht-content-login">
    <div class="row">
      <div class="medium-6">
        <div class="box-login text-center">
          <img :src="globalConfig.logo" :alt="globalConfig.installationName" />

          <h4 class="primary-text">Conversar, Conectar & Converter</h4>

          <div v-if="!email">
            <div class="align-self-top">
              <GoogleOAuthButton
                v-if="showGoogleOAuth()"
                button-size="large"
                class="oauth-reverse"
              />

              <form class="medium-9" @submit.prevent="login()">
                <label
                  class="text-left"
                  :class="{ error: $v.credentials.email.$error }"
                >
                  {{ $t('LOGIN.EMAIL.LABEL') }}

                  <input
                    v-model.trim="credentials.email"
                    type="text"
                    data-testid="email_input"
                    @input="$v.credentials.email.$touch"
                  />
                </label>

                <label
                  class="text-left"
                  :class="{ error: $v.credentials.password.$error }"
                >
                  Senha

                  <input
                    v-model.trim="credentials.password"
                    type="password"
                    data-testid="password_input"
                    @input="$v.credentials.password.$touch"
                  />
                </label>

                <p
                  class="text-left"
                  v-if="!globalConfig.disableUserProfileUpdate"
                >
                  <router-link to="auth/reset/password" class="primary-text">
                    Esqueceu sua senha?
                  </router-link>
                </p>

                <woot-submit-button
                  :disabled="
                    $v.credentials.email.$invalid ||
                    $v.credentials.password.$invalid ||
                    loginApi.showLoading
                  "
                  :button-text="$t('LOGIN.SUBMIT')"
                  :loading="loginApi.showLoading"
                  button-class="bg-primary"
                />
              </form>
            </div>

            <div class="text-center column sigin__footer">
              <p v-if="showSignupLink()">
                <router-link to="auth/signup">
                  {{ $t('LOGIN.CREATE_NEW_ACCOUNT') }}
                </router-link>
              </p>
            </div>
          </div>

          <woot-spinner v-else size="" />

          <h6 class="developed-by primary-text">By Intelia</h6>
        </div>
      </div>

      <div class="medium-6">
        <div class="image-background"></div>
      </div>
    </div>
  </main>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import WootSubmitButton from 'components/buttons/FormSubmitButton';
import { mapGetters } from 'vuex';
import { parseBoolean } from '@chatwoot/utils';
import GoogleOAuthButton from '../../components/ui/Auth/GoogleOAuthButton.vue';

const ERROR_MESSAGES = {
  'no-account-found': 'LOGIN.OAUTH.NO_ACCOUNT_FOUND',
  'business-account-only': 'LOGIN.OAUTH.BUSINESS_ACCOUNTS_ONLY',
};

export default {
  components: {
    WootSubmitButton,
    GoogleOAuthButton,
  },
  mixins: [globalConfigMixin],
  props: {
    ssoAuthToken: { type: String, default: '' },
    ssoAccountId: { type: String, default: '' },
    ssoConversationId: { type: String, default: '' },
    embedding: { type: String, default: '' },
    config: { type: String, default: '' },
    email: { type: String, default: '' },
    authError: { type: String, default: '' },
  },
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        email: '',
        password: '',
      },
      loginApi: {
        message: '',
        showLoading: false,
      },
      error: '',
    };
  },
  validations: {
    credentials: {
      password: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  created() {
    if (this.ssoAuthToken) {
      this.login();
    }
    if (this.authError) {
      const message = ERROR_MESSAGES[this.authError] ?? 'LOGIN.API.UNAUTH';
      this.showAlert(this.$t(message));
      // wait for idle state
      window.requestIdleCallback(() => {
        // Remove the error query param from the url
        const { query } = this.$route;
        this.$router.replace({ query: { ...query, error: undefined } });
      });
    }
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.loginApi.showLoading = false;
      this.loginApi.message = message;
      bus.$emit('newToastMessage', this.loginApi.message);
    },
    showSignupLink() {
      return parseBoolean(window.chatwootConfig.signupEnabled);
    },
    showGoogleOAuth() {
      return Boolean(window.chatwootConfig.googleOAuthClientId);
    },
    login() {
      this.loginApi.showLoading = true;
      const credentials = {
        email: this.email
          ? decodeURIComponent(this.email)
          : this.credentials.email,
        password: this.credentials.password,
        sso_auth_token: this.ssoAuthToken,
        ssoAccountId: this.ssoAccountId,
        ssoConversationId: this.ssoConversationId,
        embedding: this.embedding,
      };
      this.$store
        .dispatch('login', credentials)
        .then(() => {
          this.showAlert(this.$t('LOGIN.API.SUCCESS_MESSAGE'));
        })
        .catch(response => {
          // Reset URL Params if the authentication is invalid
          if (this.email) {
            window.location = '/app/login';
          }

          if (response && response.status === 401) {
            const { errors } = response.data;
            const hasAuthErrorMsg =
              errors &&
              errors.length &&
              errors[0] &&
              typeof errors[0] === 'string';
            if (hasAuthErrorMsg) {
              this.showAlert(errors[0]);
            } else {
              this.showAlert(this.$t('LOGIN.API.UNAUTH'));
            }
            return;
          }
          this.showAlert(this.$t('LOGIN.API.ERROR_MESSAGE'));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.oauth-reverse {
  display: flex;
  flex-direction: column-reverse;
}
</style>
