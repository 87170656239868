<template>
  <div>
    <div v-for="(history, index) of getHistory" :key="index">
      <div
        class="content-body"
        :style="index === 0 ? { marginTop: '2rem' } : ''"
      >
        <p>
          <strong>{{ formatDate(history.created_at) }}</strong>
        </p>

        <p>
          <strong>{{ $t('CLOSING_REASONS.HISTORY.REASONS.LABEL') }}</strong>
          {{ history.closing_reason.name }}
        </p>

        <p>
          <strong>{{ $t('CLOSING_REASONS.HISTORY.RESOLVED_BY.LABEL') }}</strong>
          {{ history.resolved_by.name }}
        </p>

        <p>
          <strong>{{ $t('CLOSING_REASONS.HISTORY.COMMENTS.LABEL') }}</strong>
          {{ history.comments || '-' }}
        </p>
      </div>

      <hr v-if="getHistory.length > 1 && index + 1 < getHistory.length" />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'historyClosingReasons/getUIFlags',
      getHistory: 'historyClosingReasons/getHistoryClosingReasons',
    }),
  },
  methods: {
    ...mapActions('historyClosingReasons', ['get']),
    getAllHistory() {
      try {
        this.get({ conversation_id: this.conversationId });
      } catch (error) {
        console.log(error.message);
      }
    },
    formatDate(dateString) {
      return format(new Date(dateString), 'dd/MM/yyyy - HH:mm');
    },
  },
  mounted() {
    this.getAllHistory();
  },
};
</script>

<style lang="scss" scoped>
.content-body {
  padding: 0 var(--space-normal);
}
</style>